.wrapper{width:calc( 100% - 110px );padding-left:110px;height:auto;overflow:hidden;background:#fff;min-height:100vh;}

.loginWrap{width:862px;height:auto;overflow:hidden;margin:130px auto 0 auto;}
.loginWrap.type01{width:500px;}
.loginWrap .slogo{width:100%;height:auto;overflow:hidden;text-align:center;}
.loginWrap .slogo video{max-width:200px;}
.loginWrap .stit{width:100%;height:auto;overflow:hidden;font-size:31px;color:#141B2D;font-weight:700;text-align:center;}

.loginForm{margin:48px auto 0 auto;max-width:454px;width:calc( 100% - 40px );height:auto;overflow:hidden;}
.loginForm .formItem{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.loginForm .formItem + .formItem{margin-top:20px;}
.loginForm .formItem .inp{width:100%;flex:1;height:40px;border-radius:9px;padding:0 10px;font-size:13px;color:#000;border:1px solid #838383;}
.loginForm .findLink{margin-top:10px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-end;align-items:center;}
.loginForm .findLink a{font-size:13px;color:#333;background:url(../../assets/image/ico/ico-link-arr.svg) no-repeat right center;padding-right:10px;}

.loginForm button{margin-top:40px;width:100%;height:45px;border-radius:7px;border:0;overflow:hidden;display:flex;justify-content:center;align-items:center;padding:0;background: #141B2D;box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.04);font-size:19px;color:#fff;font-weight:400;}
.loginForm .snsList{margin-top:30px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:center;align-items:center;}
.loginForm .snsList a{display:flex;margin:0 10px;}
.loginForm .snsList a img{width:46px;height:46px;}


@media screen and (max-width: 1500px) {
}

@media screen and (max-width: 1024px) {
    .wrapper{width:calc( 100% - 0px );padding-left:0px;}
    .loginWrap{width:calc( 100% - 0px );}
    .loginWrap .stit{font-size:21px;}

    .loginForm button{font-size:13.7px;}
    .loginWrap .slogo video{max-width:151px;}
}