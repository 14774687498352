.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@font-face {
  font-family: 'Pretendard';
  font-weight: 900;
  font-display: swap;
  src: local('Pretendard Black'), url('./assets/fonts/Pretendard-Black.woff2') format('woff2'), url('./assets/fonts/Pretendard-Black.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 800;
  font-display: swap;
  src: local('Pretendard ExtraBold'), url('./assets/fonts/Pretendard-ExtraBold.woff2') format('woff2'), url('./assets/fonts/Pretendard-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-display: swap;
  src: local('Pretendard Bold'), url('./assets/fonts/Pretendard-Bold.woff2') format('woff2'), url('./assets/fonts/Pretendard-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  font-display: swap;
  src: local('Pretendard SemiBold'), url('./assets/fonts/Pretendard-SemiBold.woff2') format('woff2'), url('./assets/fonts/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-display: swap;
  src: local('Pretendard Medium'), url('./assets/fonts/Pretendard-Medium.woff2') format('woff2'), url('./assets/fonts/Pretendard-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-display: swap;
  src: local('Pretendard Regular'), url('./assets/fonts/Pretendard-Regular.woff2') format('woff2'), url('./assets/fonts/Pretendard-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 300;
  font-display: swap;
  src: local('Pretendard Light'), url('./assets/fonts/Pretendard-Light.woff2') format('woff2'), url('./assets/fonts/Pretendard-Light.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 200;
  font-display: swap;
  src: local('Pretendard ExtraLight'), url('./assets/fonts/Pretendard-ExtraLight.woff2') format('woff2'), url('./assets/fonts/Pretendard-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 100;
  font-display: swap;
  src: local('Pretendard Thin'), url('./assets/fonts/Pretendard-Thin.woff2') format('woff2'), url('./assets/fonts/Pretendard-Thin.woff') format('woff');
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable) {
	min-height: 400px;
	margin-bottom: 20px;
}

*{ font-family: 'Pretendard';font-weight:400;font-size:14px;-webkit-overflow-scrolling: touch;letter-spacing:-.5px;}
/*#not_all * { color:initial; }*/

html,body,p, input, select, form, label, mark, ul, ul li, ol, ol li, dl, dl dt, dl dd, img, a, table, h1, h2, h3, h4, h5{margin:0;padding:0;}
img{ border:0; }
ul, ol{ list-style:none; }
a{color:#fff;text-decoration:none;}
a:hover, a:active, a:visited{text-decoration:none !important; }
/*table{border:0;border-collapse:collapse;table-layout:fixed;}*/
option{color:#666}

.hidden{position:absolute;left:-20000px;}
.w100{width:100px !important;}
.w150{width:150px !important;}

.ml5{margin-left:5px !important}
.ml20{margin-left:20px !important}
.ml30{margin-left:30px !important}

.mt0{margin-top:0px !important}
.mt5{margin-top:5px !important}
.mt10{margin-top:10px !important}
.mt20{margin-top:20px !important}
.mt30{margin-top:30px !important}
.mt35{margin-top:35px !important}
.mt40{margin-top:40px !important}
.mt50{margin-top:50px !important}

.mb5{margin-bottom:5px !important}
.mb10{margin-bottom:10px;}
.mb20{margin-bottom:20px;}
.mb30{margin-bottom:30px;}

::selection {background:#99CC00;color: #FFF;text-shadow: none;}
::-moz-selection {background:#99CC00;color: #FFF;text-shadow: none;}
::-webkit-selection {background:#99CC00;color: #FFF;text-shadow: none;}

a{opacity:1;text-decoration:none !important;}

a.cubic{-webkit-transition:.3s;outline:0;}
a.cubic:active{outline:0;}
a.cubic:hover{opacity:.8;-webkit-transform: scale(0.95);-webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.47, 2.02);}

.hidden{position:absolute;left:-20000px;}
/**/
select {cursor:pointer;outline:none;background:url(./assets/image/bg/bg-select01.svg) no-repeat right center;background-size:24px 12px;appearance: none;-moz-appearance:none;-webkit-appearance: none;}
select::-ms-expand {display:none}

input[type=checkbox]{outline:none;float:left;background:url(./assets/image/bg/bg-chk-off.svg) no-repeat;background-size:14px 14px;border:0;width:14px;height:14px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;}
input[type=checkbox]:checked{width:14px;height:14px;background:url(./assets/image/bg/bg-chk-on.svg) no-repeat;background-size:14px 14px;}
input[type=checkbox]::-ms-check{border:0;outline:none;appearance: none;-moz-appearance: none;-webkit-appearance: none;width:14px;height:14px;content:"";background:url(./assets/image/bg/bg-chk-off.svg) no-repeat;background-size:14px 14px;}
input[type=checkbox]:checked::-ms-check{color:transparent;background:url(./assets/image/bg/bg-chk-on.svg) no-repeat;background-size:14px 14px;}



/**/
input[type=radio]{outline:none;float:left;background:url(./assets/image/bg/bg-rd-off.svg) no-repeat;border:0;width:25px;height:25px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;border-radius:50%;}
input[type=radio]:checked{background:#40EFC1 url(./assets/image/bg/bg-rd-on.svg) no-repeat center center;}

input{outline:none;}
button{cursor:pointer;outline:none;}
input::-webkit-input-placeholder {color: #777;}
textarea::-webkit-input-placeholder {color: #777;}

.container{padding-top: 72px;}

@media screen and (max-width: 1024px) {

  .container{padding-top:40px;padding-bottom:90px;position:absolute;left:0;top:0;height:calc( 100% - 130px );overflow-y:auto;}
}
body{background:#141b2d !important;}


::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #2f3542;
  background-clip: padding-box;
  border: 2px solid transparent;
}
::-webkit-scrollbar-track {
  background-color: rgba(255,255,255,.5);
  box-shadow: inset 0px 0px 5px white;
}

a{cursor:pointer;}

.ck .ck-editor__main a{
  font-family:inherit;
  font-weight:inherit;
  font-size:inherit;
  color:blue;
  letter-spacing:inherit;
  cursor:pointer;
  text-decoration: underline !important;
}

.ck .ck-editor__main i{
  font-family:inherit;
  font-weight:inherit;
  font-size:inherit;
  color:inherit;
  letter-spacing:inherit;
}

.ck .ck-editor__main p{
  font-weight:initial;
  font-size:1.1em;
  color:initial;
  letter-spacing:initial;
}
.ck .ck-editor__main p strong{
  font-weight:bold;
  font-size:1.1em;
  color:initial;
  letter-spacing:initial;
}
.ck .ck-editor__main h2{
  font-weight:initial;
  font-size:1.6em;;
  color:initial;
  letter-spacing:initial;
}
.ck .ck-editor__main h2 strong{
  font-weight:bold;
  font-size:1.1em;
  color:initial;
  letter-spacing:initial;
}
.ck .ck-editor__main h3{
  font-weight:initial;
  font-size:1.35em;;
  color:initial;
  letter-spacing:initial;
}
.ck .ck-editor__main h3 strong{
  font-weight:bold;
  font-size:1.1em;
  color:initial;
  letter-spacing:initial;
}
.ck .ck-editor__main h4{
  font-weight:initial;
  font-size:1.25em;;
  color:initial;
  letter-spacing:initial;
}
.ck .ck-editor__main h4 strong{
  font-weight:bold;
  font-size:1.1em;
  color:initial;
  letter-spacing:initial;
}
.ck .ck-editor__main ol{
  margin: initial;
  margin-left: 2.666em;
  padding: initial;
}
.ck .ck-editor__main ol li{
  margin: initial;
  padding: initial;
}
.ck .ck-editor__main ol li span{
  font-weight:initial;
  font-size:1.1em;
  color:initial;
  letter-spacing:initial;
  margin: initial;
  padding: initial;
}
.ck .ck-editor__main ul{
  margin: initial;
  margin-left: 2.666em;
  padding: initial;
}
.ck .ck-editor__main ul li{
  margin: initial;
  padding: initial;
}
.ck .ck-editor__main ul li span{
  font-weight:initial;
  font-size:1.1em;
  color:initial;
  letter-spacing:initial;
  margin: initial;
  padding: initial;
}
.ck-content{
  background-color: #fff;
  padding: 0.7em;
}
.ck-content a{
  font-weight:inherit;
  font-size:inherit;
  color:blue;
  letter-spacing:inherit;
  cursor:pointer;
  text-decoration: underline !important;
}
.ck-content i{
  font-weight:inherit;
  font-size:inherit;
  color:inherit;
  letter-spacing:inherit;
}
.ck-content p{
  font-weight:initial;
  font-size:1.1em;
  color:initial;
  letter-spacing:initial;
}
.ck-content p strong{
  font-weight:bold;
  font-size:1.1em;
  color:initial;
  letter-spacing:initial;
}
.ck-content h2{
  font-weight:initial;
  font-size:1.6em;;
  color:initial;
  letter-spacing:initial;
}
.ck-content h2 strong{
  font-weight:bold;
  font-size:1.1em;
  color:initial;
  letter-spacing:initial;
}
.ck-content h3{
  font-weight:initial;
  font-size:1.35em;;
  color:initial;
  letter-spacing:initial;
}
.ck-content h3 strong{
  font-weight:bold;
  font-size:1.1em;
  color:initial;
  letter-spacing:initial;
}
.ck-content h4{
  font-weight:initial;
  font-size:1.25em;;
  color:initial;
  letter-spacing:initial;
}
.ck-content h4 strong{
  font-weight:bold;
  font-size:1.1em;
  color:initial;
  letter-spacing:initial;
}
.ck-content ol{
  margin: initial;
  margin-left: 2.666em;
  padding: initial;
  list-style-type: decimal;
}
.ck-content ol li{
  font-weight:initial;
  font-size:1.1em;
  color:initial;
  letter-spacing:initial;
  margin: initial;
  padding: initial;
}
.ck-content ul{
  margin: initial;
  margin-left: 2.666em;
  padding: initial;
  list-style: initial;
}
.ck-content ul li{
  font-weight:initial;
  font-size:1.1em;
  color:initial;
  letter-spacing:initial;
  margin: initial;
  padding: initial;
}

.ck-content blockquote {
  border-left: 5px solid #ccc;
  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

figure > img { width:100%; height:auto; }