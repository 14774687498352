.wrapper{width:calc( 100% - 110px );padding-left:110px;height:auto;overflow:hidden;}

.wrapper .titlebar{max-width:1300px;width:100%;height:auto;display:flex;justify-content:flex-start;align-items:center;margin:130px auto 0 auto;}
.wrapper .titlebar strong{font-size:60px;color:#40EFC1;font-weight:800;display:flex;}
.wrapper .titlebar span{font-size:24px;color:#40EFC1;font-weight:700;display:flex;margin-left:8px;margin-top:10px;}

.wrapper .descbar{max-width:1300px;margin:0 auto;width:100%;height:auto;display:flex;justify-content:flex-start;align-items:center;font-size:34px;color:#fff;font-weight:800;}

.calendarRela{margin:50px auto 0 auto;width:1300px;height:auto;padding-bottom:100px;position:relative;overflow:hidden;display:flex;justify-content:flex-start;align-items:flex-start;}
.calendarRela .side{width:230px;height:100%;overflow:hidden;background:#1F2A40;position:absolute;left:0;top:0;}
.calendarRela .side ul{width:calc( 100% - 20px );height:auto;overflow:hidden;margin:20px auto 0 auto;}
.calendarRela .side ul li{position:relative;border-bottom:1px solid #fff;width:calc( 100% - 40px );padding:20px;height:auto;overflow:hidden;}
.calendarRela .side ul li .mdesc{width:calc( 100% - 40px );height:auto;overflow:hidden;font-size:17px;color:#fff;font-weight:600;line-height:1.3em;}
.calendarRela .side ul li .mdate{position:relative;width:calc( 100% - 20px );padding-left:20px;height:auto;overflow:hidden;margin-top:20px;font-size:17px;color:#fff;font-weight:600;}
.calendarRela .side ul li .mdate:before{content:"";display:block;width:9px;height:9px;overflow:hidden;border-radius:50%;position:absolute;left:0;top:50%;margin-top:-4px;}
.calendarRela .side ul li.status01 .mdate:before{background:#FFA9A9;}
.calendarRela .side ul li.status02 .mdate:before{background:#FFFA98;}
.calendarRela .btnAdd{width:calc( 100% - 40px );margin:40px auto 0 auto;height:32px;border-radius:4px;border:0;background:#40EFC1;padding:0;display:flex;justify-content:center;align-items:center;}
.calendarRela .btnAdd span{display:flex;font-size:16px;color:#000;font-weight:600;}
.calendarRela .btnAdd img{display:flex;margin-left:5px;}
.calendarRela .btnRemove{width:20px;height:20px;border:0;background:transparent;padding:0;display:flex;justify-content:center;align-items:center;position:absolute;right:0;top:40px;}

.calendarRela .acticle{width:100%;flex:1;height:auto;overflow:hidden;margin-left:280px;}
.fc-button{display:none !important;}

.popup{width:calc( 100% - 40px - 50px );max-width:687px;height:auto;padding:48px 25px;overflow:hidden;background:#ededed;position:fixed;left:50%;top:100px;transform: translateX(-50%);z-index:99999;display:none;}
.popup.on{display:block;}
.popup .ptit{width:100%;height:auto;overflow:hidden;text-align:center;font-size:22px;color:#141B2D;font-weight:600;}
.popup .pdesc{width:100%;height:auto;overflow:hidden;text-align:center;font-size:18px;color:#141B2D;font-weight:300;margin-top:10px;}
.popup .formItem{margin-top:20px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.popup .formItem .inp{border:1px solid #1F2A40;height:45px;width:100%;flex:1;background:#fff;padding:0 10px;font-size:18px;color:#141B2D;}
.popup .btnGroup{margin-top:20px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-end;align-items:center;}
.popup .btnGroup .btnConfirm{display:flex;justify-content:center;align-items:center;width:113px;height:40px;border-radius:3px;background:#3C7EFE;border:0;font-size:18px;color:#fff;font-weight:600;padding:0;}
.popup .btnGroup .btnCancel{margin-left:20px;display:flex;justify-content:center;align-items:center;width:113px;height:40px;border-radius:3px;background:#DEDEDE;border:0;font-size:18px;color:#141B2D;font-weight:600;padding:0;}
.popup .btnGroup .btnSave{display:flex;justify-content:center;align-items:center;width:113px;height:40px;border-radius:3px;background:#40EFC1;border:0;font-size:18px;color:#141B2D;font-weight:600;padding:0;}

.popup .formItem01{margin-top:0px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.popup .formItem01 .inp{font-weight:700;border:0;border-bottom:1px solid #141B2D;height:45px;width:100%;flex:1;background:transparent;padding:0;font-size:18px;color:#141B2D;}
.popup .formItem01 .inp::placeholder{color:#141B2D;}
.popup .optWrap{margin-top:10px;margin-bottom:10px;width:100%;height:auto;/*overflow:Hidden;*/display:flex;justify-content:flex-start;align-items:center;}
.popup .optWrap .dateWrap{display:flex;justify-content:flex-start;align-items:center;}
.popup .optWrap .e01{display:flex;justify-content:center;align-items:center;width:40px;font-size:10px;color:#141B2D;font-weight:600;}
.popup .optWrap .select{height:20px;border:1px solid #141B2D;display:flex;width:70px;font-size:10px;color:#141B2D;padding-left:10px;}
.popup .optWrap .select + .select{margin-left:10px;}
.popup .textareaWrap{margin-top:20px;margin-bottom:20px;width:100%;height:auto;overflow:hidden;}
.popup .textareaWrap .cnt{width:100%;height:30px;overflow:hidden;display:flex;justify-content:flex-end;align-items:center;font-size:12px;color:#141B2D;}
.popup .textareaWrap .textarea{width:calc( 100% - 40px );padding:20px;overflow:hidden;resize:none;border:0;height:154px;font-size:18px;color:#141B2D;background:#DBDBDB;}
.popup .formItem01 .select{height:36px;border:1px solid #141B2D;display:flex;width:115px;font-size:12px;color:#141B2D;padding-left:10px;}

.popup .formItem01 label{display:flex;justify-content:flex-start;align-items:center;}
.popup .formItem01 label input{display:flex;}
.popup .formItem01 label span{display:flex;margin-left:9px;font-size:10px;color:#141B2D;}
.popup input[type=checkbox]{outline:none;float:none;margin:0 auto;background:url(../../assets/image/bg/bg-chk-off03.svg) no-repeat;background-size:24px 24px;border:0;width:24px;height:24px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;}
.popup input[type=checkbox]:checked{width:24px;height:24px;background:url(../../assets/image/bg/bg-chk-on03.svg) no-repeat;background-size:24px 24px;}
.popup input[type=checkbox]::-ms-check{border:0;outline:none;appearance: none;-moz-appearance: none;-webkit-appearance: none;width:24px;height:24px;content:"";background:url(../../assets/image/bg/bg-chk-off.svg) no-repeat;background-size:24px 24px;}
.popup input[type=checkbox]:checked::-ms-check{color:transparent;background:url(../../assets/image/bg/bg-chk-on03.svg) no-repeat;background-size:24px 24px;}


.dialog{position:fixed;left:0;top:100%;width:100%;height:100%;overflow:Hidden;z-index:99;background:rgba(0,0,0,.5);display:flex;justify-content:center;align-items:center;}
.dialog.on{top:0}
.dialog .popCont{transition:all .5s;transition-delay:.5s;transform:translateY(500px);opacity:0;position:relative;width:930px;height:auto;overflow:hidden;padding:40px 40px;border-radius:20px;background:#fff;}
.dialog.on .popCont{transform:translateY(0px);opacity:1;}
.dialog .popCont .popClose{position:absolute;right:30px;top:30px;border:0;padding:0;background:transparent;}
.dialog .popCont .ptit{width:100%;height:auto;overflow:hidden;display:flex;justify-content:center;align-items:center;}



.dialog .popCont .ptit img{display:flex;}
.dialog .popCont .ptit strong{display:flex;margin-left:10px;font-size:28px;color:#141B2D;font-weight:700;}
.dialog .popCont .optWrap{margin-top:40px;width:100%;height:auto;overflow:Hidden;display:flex;justify-content:flex-start;align-items:center;}
.dialog .popCont .optWrap .dateWrap{display:flex;justify-content:flex-start;align-items:center;}
.dialog .popCont .optWrap .e01{display:flex;justify-content:center;align-items:center;width:40px;font-size:10px;color:#141B2D;font-weight:600;}
.dialog .popCont .optWrap .select{height:39px;border:1px solid #141B2D;display:flex;width:100px;font-size:18px;color:#141B2D;padding-left:20px;}
.dialog .popCont .optWrap .select + .select{margin-left:10px;}
.dialog .popCont .textareaWrap{width:100%;height:auto;overflow:hidden;}
.dialog .popCont .textareaWrap .cnt{width:100%;height:30px;overflow:hidden;display:flex;justify-content:flex-end;align-items:center;font-size:12px;color:#141B2D;}
.dialog .popCont .textareaWrap .textarea{width:calc( 100% - 40px );padding:20px;overflow:hidden;resize:none;border:0;height:154px;font-size:18px;color:#141B2D;background:#DBDBDB;}
.dialog .popCont .btnGroup{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-end;align-items:center;padding-top:24px;}
.dialog .popCont .btnGroup button{width:220px;height:60px;border:0;padding:0;background:#40EFC1;display:flex;justify-content:center;align-items:center;border-radius: 10px;font-size:21px;color:#141B2D;font-weight:700;}


@media screen and (max-width: 1500px) {

    .wrapper .titlebar{width:calc( 100% - 100px );margin:130px auto 0 auto;}
    .wrapper .descbar{width:calc( 100% - 100px );margin:0 auto;}
    .calendarRela{width:calc( 100% - 100px );margin:50px auto 0 auto;}
}

@media screen and (max-width: 1024px) {
    .calendarRela .btnRemove{right:20px;}
    .wrapper{width:calc( 100% - 0px );padding-left:0px;}
    .wrapper .titlebar{width:calc( 100% - 40px );margin:130px auto 0 auto;}
    .wrapper .descbar{width:calc( 100% - 40px );margin:0 auto;}
    .calendarRela{display:block;width:calc( 100% - 40px );margin:20px auto 0 auto;}
    .calendarRela .side{position:static;width:calc( 100% - 0px );margin:0px auto 0 auto;}
    .calendarRela .side ul{width:100%;margin-top:0;}
    .calendarRela .side ul li .mdate{margin-top:10px;}
    .calendarRela .acticle{margin:0;}

    .wrapper .titlebar strong{font-size:30px;}
    .wrapper .titlebar span{font-size:18px;}
    .wrapper .descbar{font-size:18px;}

    .popup .btnGroup{justify-content:center;}
}
