.wrapper{width:calc( 100% - 110px );padding-left:110px;padding-bottom:100px;height:auto;overflow:hidden;}

.wrapper .formWrap{width:880px;height:auto;overflow:hidden;margin:128px auto 0 auto;}
.wrapper .formWrap .spic{width:100%;height:450px;border-radius:20px;background:#fff;background-repeat:no-repeat !important;background-position:center center !important;display:flex;justify-content:center;align-items:center;}
.wrapper .formWrap .formRow{width:calc( 100% - 100px );margin:60px auto 0 auto;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.wrapper .formWrap .formRow.t01{margin-top:10px;}
.wrapper .formWrap .formRow .formItem{flex:1;width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.wrapper .formWrap .formRow .formItem + .formItem{margin-left:60px;}
.wrapper .formWrap .formRow .formItem .label{width:130px;height:48px;font-size:20px;color:#fff;font-weight:600;display:flex;justify-content:flex-start;align-items:center;}
.wrapper .formWrap .formRow .formItem .inp{width:100%;height:46px;border-radius:9px;border:1px solid #838383;flex:1;padding:0 10px;font-size:20px;color:#333;}
.wrapper .formWrap .formRow .formItem .inp + .inp{margin-left:10px;}
.wrapper .formWrap .formRow .formItem .inp.s01{}
.wrapper .formWrap .btnGroup{width:calc( 100% - 100px );margin:60px auto 0 auto;height:auto;overflow:hidden;display:flex;justify-content:flex-end;align-items:center;}
.wrapper .formWrap .btnGroup .btnCancel{display:flex;justify-content:center;align-items:center;width:158px;height:65px;border-radius:10px;border:0;background:#fff;padding:0;font-size:27px;color:#141B2D;font-weight:600;}
.wrapper .formWrap .btnGroup .btnEdit{display:flex;justify-content:center;align-items:center;width:158px;height:65px;border-radius:10px;border:0;background:#40EFC1;padding:0;font-size:27px;color:#141B2D;font-weight:600;margin-left:30px;}

@media screen and (max-width: 1500px) {

    .wrapper .formWrap .formRow{width:100%;margin-top:20px;}
}

@media screen and (max-width: 1024px) {
    .wrapper{width:calc( 100% - 0px );padding-left:0px;}
    .wrapper .formWrap{width:calc( 100% - 40px );}
    .wrapper .formWrap .spic{height:60vw;}
    .wrapper .formWrap .formRow{display:block;}
    .wrapper .formWrap .formRow .formItem + .formItem{margin-left:0;margin-top:20px;}
    .wrapper .formWrap .formRow .formItem .label{width:90px;font-size:15px;}
    .wrapper .formWrap .btnGroup .btnCancel{width:158px;height:45px;font-size:18px;}
    .wrapper .formWrap .btnGroup .btnEdit{width:158px;height:45px;background:#40EFC1;padding:0;font-size:18px;margin-left:10px;}
}
