.wrapper{width:calc( 100% - 110px );padding-left:110px;padding-bottom:100px;height:auto;overflow:hidden;}
.wrapper .subTitle{width:1300px;height:auto;overflow:Hidden;margin:130px auto 0 auto;border-bottom:2px solid #40EFC1;padding-bottom:40px;display:flex;justify-content:center;align-items:center;}
.wrapper .subTitle img{display:flex;}
.wrapper .subTitle span{display:flex;margin-left:16px;font-size:28px;color:#40EFC1;font-weight:700;}

.wrapper .scrolls{width:1300px;height:auto;overflow:Hidden;margin:60px auto 0 auto;}
.wrapper .scrolls table{width:100%;table-layout:fixed;border:0;border-collapse:collapse;}
.wrapper .scrolls table th{text-align:center;font-size:14px;color:#fff;font-weight:500;height:37px;border-left:1px solid #fff;border-top:1px solid #fff;border-bottom:1px solid #fff;}
.wrapper .scrolls table td{text-align:center;font-size:18px;color:#fff;font-weight:500;height:60px;border-left:1px solid #fff;border-bottom:1px solid #fff;}
.wrapper .scrolls table td a{font-size:18px;color:rgba(65, 78, 111, 0.66);}
.wrapper .scrolls table tr>th:first-child{border-left:0;}
.wrapper .scrolls table tr>td:first-child{border-left:0;}
.wrapper .scrolls table .al{text-align:left;}


@media screen and (max-width: 1500px) {

    .wrapper .subTitle{width:calc( 100% - 100px );margin:130px auto 0 auto;}
    .wrapper .scrolls{width:calc( 100% - 100px );margin:20px auto 0 auto;overflow-x:auto;}
    .wrapper .scrolls table{min-width:1200px;}
}

@media screen and (max-width: 1024px) {
    .wrapper{width:calc( 100% - 0px );padding-left:0px;}
    .wrapper .subTitle{width:calc( 100% - 40px );}
    .wrapper .subTitle span{font-size:20px;}
    .wrapper .scrolls{width:calc( 100% - 40px );overflow-x:auto !important;}
}


.mobList{display:none;width:calc( 100% - 0px );margin:20px auto 0 auto;height:auto;overflow:hidden;}
.mobList.type01{width:100%;}
.mobList li{width:100%;height:auto;overflow:hidden;}
.mobList li .chk{width:100%;height:auto;overflow:hidden;padding:0 0 10px 0;}
.mobList li + li{margin-top:20px;}

.mobList li .chk input[type=checkbox]{outline:none;float:none;margin:0 auto;background:url(../../assets/image/bg/bg-chk-off01.svg) no-repeat;background-size:24px 24px;border:0;width:24px;height:24px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;}
.mobList li .chk input[type=checkbox]:checked{width:24px;height:24px;background:url(../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}
.mobList li .chk input[type=checkbox]::-ms-check{border:0;outline:none;appearance: none;-moz-appearance: none;-webkit-appearance: none;width:24px;height:24px;content:"";background:url(../../assets/image/bg/bg-chk-off.svg) no-repeat;background-size:24px 24px;}
.mobList li .chk input[type=checkbox]:checked::-ms-check{color:transparent;background:url(../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}

@media screen and (max-width: 1024px) {
    .mobList{display:block;}
    .wrapper .scrolls .mobList li table{min-width:0;}
    .wrapper .scrolls .mobList li table th{font-size:14px;line-height:1.3em;padding:10px;height:30px;}
    .wrapper .scrolls .mobList li table td{border-top:1px solid #fff;font-size:14px;line-height:1.3em;padding:10px;height:30px;}
    .webList{display:none;}
    .wrapper .scrolls table td a{font-size:14px;margin:0;width:auto;justify-content:flex-start;}
}