.wrapper{width:calc( 100% - 110px );padding-left:110px;height:auto;overflow:hidden;}

.wrapper .titlebar{max-width:1462px;width:100%;height:auto;display:flex;justify-content:flex-start;align-items:center;margin:130px auto 0 auto;}
.wrapper .titlebar strong{font-size:60px;color:#40EFC1;font-weight:800;display:flex;}
.wrapper .titlebar span{font-size:24px;color:#40EFC1;font-weight:700;display:flex;margin-left:8px;margin-top:10px;}

.wrapper .housingList{margin:40px auto 0 auto;width:1462px;height:auto;overflow:Hidden;}
.wrapper .housingList>li{width:467px;height:600px;overflow:Hidden;margin:0 20px 75px 0;float:left;background:#1F2A40;}
.wrapper .housingList li a{width:calc( 100% - 60px );padding:0 30px;height:600px;overflow:Hidden;display:block;}
.wrapper .housingList li a .stit{margin-top:20px;width:100%;height:auto;overflow:Hidden;font-size:24px;color:#40EFC1;font-weight:700;}
.wrapper .housingList li a .snum{width:100%;height:auto;overflow:Hidden;font-size:12px;color:#868686;font-weight:700;line-height:25px;}
.wrapper .housingList li a .spic{width:160px;height:160px;overflow:Hidden;margin:0 auto;background-size:cover !important;margin-top:20px;}
.wrapper .housingList li a .addr{margin:28px auto 0 auto;width:178px;height:42px;line-height:42px;border:1px solid #40EFC1;border-radius:22px;padding:0 20px;overflow:Hidden;text-align:center;font-size:14px;color:#40EFC1;font-weight:700;text-overflow:ellipsis; white-space:nowrap;word-wrap:normal;}
.wrapper .housingList li a ul{margin-top:50px;border-top:1px solid rgba(255,255,255,.2);width:100%;height:auto;overflow:Hidden;display:flex;justify-content:center;align-items:center;}
.wrapper .housingList li a ul + ul{margin-top:0;}
.wrapper .housingList li a ul li{display:flex;justify-content:center;align-items:center;flex:1;width:100%;height:95px;overflow:hidden;flex-direction: column;}
.wrapper .housingList li a ul li .key{display:flex;font-size:16px;color:#fff;font-weight:700;}
.wrapper .housingList li a ul li .value{margin-top:5px;display:flex;font-size:20px;color:#40EFC1;font-weight:700;}


@media screen and (max-width: 1500px) {

    .wrapper .titlebar{width:calc( 100% - 100px );margin:130px auto 0 auto;}
    .wrapper .descbar{width:calc( 100% - 100px );margin:0 auto;}
    .wrapper .housingList{width:calc( 100% - 100px );margin:40px auto 0 auto;}
    .wrapper .housingList>li{width:calc( 50% - 20px );margin:0 0 40px 0;}
    .wrapper .housingList>li:nth-child(even){float:right;}
}

@media screen and (max-width: 1024px) {
    .wrapper{width:calc( 100% - 0px );padding-left:0px;}
    .wrapper .titlebar{width:calc( 100% - 40px );}
    .wrapper .titlebar strong{font-size:30px;}
    .wrapper .titlebar span{font-size:18px;}
    .wrapper .descbar{width:calc( 100% - 40px )}
    .wrapper .housingList{margin-top:20px;width:calc( 100% - 40px )}
    .wrapper .housingList>li{width:100%;margin:0 0 20px 0;height:500px;}
    .wrapper .housingList li a{width:calc( 100% - 20px );padding:0 10px;height:500px;}
    .wrapper .housingList li a ul{margin-top:20px;}
    .wrapper .housingList li a ul li{height:70px;}
    .wrapper .housingList li a ul li .key{font-size:14px;}
    .wrapper .housingList li a ul li .value{font-size:16px;}

}

.pageTitle{width:1300px;height:auto;overflow:Hidden;margin:122px auto 0 auto;}
.pageTitle .mtit{width:100%;height:auto;overflow:Hidden;font-size:60px;color:#40EFC1;font-weight:800;line-height:67px;}
.pageTitle .mdesc{width:100%;height:auto;overflow:Hidden;font-size:24px;color:#40EFC1;font-weight:700;line-height:35px;margin-top:20px;}

.wrapper .scrolls{width:1300px;height:auto;overflow:Hidden;margin:80px auto 0 auto;}
.wrapper .scrolls table{width:100%;table-layout:fixed;border:0;border-collapse:collapse;}
.wrapper .scrolls table th{text-align:left;padding-left:20px;font-size:14px;color:#fff;font-weight:500;height:37px;border-left:1px solid #2D459D;border-top:1px solid #2D459D;border-bottom:1px solid #2D459D;background:#2D459D}
.wrapper .scrolls table td{text-align:left;padding-left:20px;font-size:18px;color:#fff;font-weight:500;height:60px;border-left:1px solid #1F2A40;border-bottom:1px solid #1F2A40;background:#1F2A40;}


.wrapper .scrolls table input[type=checkbox]{outline:none;float:left;background:url(../../assets/image/bg/bg-chk-off01.svg) no-repeat;background-size:24px 24px;border:0;width:24px;height:24px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;}
.wrapper .scrolls table input[type=checkbox]:checked{width:24px;height:24px;background:url(../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}
.wrapper .scrolls table input[type=checkbox]::-ms-check{border:0;outline:none;appearance: none;-moz-appearance: none;-webkit-appearance: none;width:24px;height:24px;content:"";background:url(../../assets/image/bg/bg-chk-off.svg) no-repeat;background-size:24px 24px;}
.wrapper .scrolls table input[type=checkbox]:checked::-ms-check{color:transparent;background:url(../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}

.wrapper .scrolls table td a{display:flex;justify-content:flex-start;align-items:center;}
.wrapper .scrolls table td a img{display:flex;}
.wrapper .scrolls table td a span{display:flex;margin-left:10px;font-size:14px;color:#40EFC1;font-weight:700;}
.wrapper .scrolls table tr>th:first-child{border-left:0;}
.wrapper .scrolls table tr>td:first-child{border-left:0;}

.paging{max-width:1300px;width:100%;height:auto;overflow:Hidden;display:flex;justify-content:center;align-items:center;margin:50px auto 50px auto;}
.paging button{border:0;background:transparent;width:24px;height:24px;overflow:Hidden;padding:0;display:flex;justify-content:center;align-items:center;}
.paging ul{display:flex;justify-content:center;align-items:center;margin:0 20px;}
.paging ul li{display:flex;justify-content:center;align-items:center;}
.paging ul li a{font-size:15px;color:rgba(255,255,255,.7);display:flex;justify-content:center;align-items:center;width:24px;height:24px;}
.paging ul li a.on{color:rgba(255,255,255,1);font-weight:500;}

@media screen and (max-width: 1500px) {
    .pageTitle{width:calc( 100% - 100px );}
    .wrapper .scrolls{width:calc( 100% - 100px );margin:20px auto 0 auto;overflow-x:auto;}
    .wrapper .scrolls table{min-width:1200px;}
}

@media screen and (max-width: 1024px) {
    .pageTitle .mtit{font-size:40px;line-height:40px;}
    .pageTitle .mdesc{font-size:18px;}
    .wrapper .subTitle span{font-size:20px;}
    .pageTitle{width:calc( 100% - 40px );}
    .wrapper .scrolls{width:calc( 100% - 40px );overflow-x:auto !important;}
}


.mobList{display:none;width:100%;height:auto;overflow:hidden;}
.mobList li{width:100%;height:auto;overflow:hidden;}
.mobList li .chk{width:100%;height:auto;overflow:hidden;padding:10px 0;}
.mobList li + li{margin-top:10px;}

.mobList li .chk input[type=checkbox]{outline:none;float:none;margin:0 auto;background:url(../../assets/image/bg/bg-chk-off01.svg) no-repeat;background-size:24px 24px;border:0;width:24px;height:24px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;}
.mobList li .chk input[type=checkbox]:checked{width:24px;height:24px;background:url(../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}
.mobList li .chk input[type=checkbox]::-ms-check{border:0;outline:none;appearance: none;-moz-appearance: none;-webkit-appearance: none;width:24px;height:24px;content:"";background:url(../../assets/image/bg/bg-chk-off.svg) no-repeat;background-size:24px 24px;}
.mobList li .chk input[type=checkbox]:checked::-ms-check{color:transparent;background:url(../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}

@media screen and (max-width: 1024px) {
    .mobList{display:block;}
    .wrapper .scrolls .mobList li table{min-width:0;}
    .wrapper .scrolls .mobList li table th{font-size:14px;line-height:1.3em;padding:10px;height:30px;}
    .wrapper .scrolls .mobList li table td{font-size:14px;line-height:1.3em;padding:10px;height:30px;}
    .webList{display:none;}
}