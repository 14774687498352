.wrapper{width:calc( 100% - 110px );padding-left:110px;padding-bottom:100px;height:auto;overflow:hidden;}
.wrapper .subTitle{width:1300px;height:auto;overflow:Hidden;margin:130px auto 0 auto;display:flex;justify-content:center;align-items:center;}
.wrapper .subTitle img{display:flex;}
.wrapper .subTitle span{display:flex;margin-left:16px;font-size:28px;color:#40EFC1;font-weight:700;}

.wrapper .cmmList{width:1300px;height:auto;overflow:Hidden;margin:30px auto 0 auto;}
.wrapper .cmmList ul{width:calc( 100% - 100px );height:auto;overflow:Hidden;padding:30px 50px;border-top:2px solid #40EFC1;}
.wrapper .cmmList ul li{width:100%;height:auto;overflow:Hidden;display:flex;justify-content:flex-start;align-items:center;}
.wrapper .cmmList ul li + li{margin-top:15px;}
.wrapper .cmmList ul li .radio{width:30px;display:flex;justify-content:flex-start;align-items:center;}
.wrapper .cmmList ul li a{width:100%;height:auto;overflow:Hidden;display:flex;justify-content:flex-start;align-items:center;}
.wrapper .cmmList ul li .cate{width:140px;display:flex;justify-content:flex-start;align-items:center;font-size:18px;color:#fff;font-weight:500;}
.wrapper .cmmList ul li .status{width:120px;display:flex;justify-content:flex-start;align-items:center;font-size:18px;color:#fff;font-weight:500;}
.wrapper .cmmList ul li .tit{width:100%;height:auto;overflow:Hidden;flex:1;text-overflow:ellipsis; white-space:nowrap;word-wrap:normal;font-size:18px;color:#fff;font-weight:400;}
.wrapper .cmmList ul li .tit span{background:#fff200;color:#21272a;font-size:18px;}
.wrapper .cmmList ul li .date{width:70px;display:flex;justify-content:flex-end;align-items:center;font-size:13px;color:#fff;font-weight:400;}

.paging{max-width:1300px;width:100%;height:auto;overflow:Hidden;display:flex;justify-content:center;align-items:center;margin:50px auto 50px auto;}
.paging button{border:0;background:transparent;width:24px;height:24px;overflow:Hidden;padding:0;display:flex;justify-content:center;align-items:center;}
.paging ul{display:flex;justify-content:center;align-items:center;margin:0 20px;}
.paging ul li{display:flex;justify-content:center;align-items:center;}
.paging ul li a{font-size:15px;color:rgba(255,255,255,.7);display:flex;justify-content:center;align-items:center;width:24px;height:24px;}
.paging ul li a.on{color:rgba(255,255,255,1);font-weight:500;}

.boardSearch{max-width:1300px;margin:0 auto;width:100%;height:auto;overflow:Hidden;display:flex;justify-content:center;align-items:center;}
.boardSearch .searchForm{width:520px;border:1px solid #141B2D;height:40px;overflow:hidden;background:#fff;margin:0 auto;display:flex;justify-content:flex-start;position:relative;align-items:center;}
.boardSearch .select{width:130px;height:40px;border:0;padding-left:12px;font-size:13px;color:#000;}
.boardSearch .inp{padding-left:12px;width:100%;flex:1;height:40px;border-left:1px solid #141B2D;}
.boardSearch button{position:absolute;border:0;right:0;top:0;width:60px;height:40px;overflow:hidden;padding:0;background:transparent;display:flex;justify-content:center;align-items:center;}

.boardBtn{max-width:1300px;width:100%;margin:0 auto;height:auto;overflow:Hidden;display:flex;justify-content:flex-end;align-items:center;}
.boardBtn button{width:118px;height:40px;border-radius:20px;border:0;background:#40EFC1;padding:0;overflow:Hidden;display:flex;justify-content:center;align-items:center;}
.boardBtn button img{display:flex;}
.boardBtn button span{display:flex;margin-left:8px;font-size:18px;color:#000;font-weight:500;}
.viewHeader{display:flex;justify-content:space-between;align-items:flex-start;border-top:2px solid #40EFC1;margin:30px auto 0 auto;padding:30px 0;max-width:1300px;width:100%;height:auto;overflow:Hidden;}
.viewHeader .lt{display:flex;justify-content:flex-start;align-items:center;}
.viewHeader .lt .select{margin-right:25px;width:151px;display:flex;height:38px;padding-left:12px;border:1px solid #40EFC1;font-size:15px;color:#40EFC1;font-weight:700;background: url(../../assets/image/bg/bg-select01-white.svg) no-repeat right center;background-size: 24px 12px;}
.viewHeader .rt{width:100%;flex:1;height:auto;overflow:hidden;}
.viewHeader .rt .vtit{width:100%;height:auto;overflow:hidden;text-align:right;font-size:24px;color:#40EFC1;font-weight:700;}
.viewHeader .rt .vinfo{margin-top:20px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-end;align-items:center;}
.viewHeader .rt .vinfo span{display:flex;font-size:20px;color:#40EFC1;margin-left:20px;}
.viewCont{max-width:1300px;width:calc( 100% - 40px );padding:20px;height:auto;margin:0 auto;overflow:hidden;min-height:650px;border-radius:10px;background:#D8D8D8;}

.regForm{border-top:2px solid #40EFC1;margin:30px auto 0 auto;padding:0;max-width:1300px;width:100%;height:auto;overflow:Hidden;}
.regForm .formRow{width:100%;height:auto;overflow:hidden;}
.regForm .formRow .formItem{padding:20px 0;border-bottom:1px solid #40EFC1;width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.regForm .formRow .formItem .inp{border:0;padding:0 12px;height:50px;font-size:30px;color:#40EFC1;font-weight:700;background:transparent;width:100%;flex:1;}
.regForm .formRow .formItem .inp::placeholder{color:#40EFC1}
.regForm .formRow .formItem .select{margin-right:25px;width:151px;display:flex;height:38px;padding-left:12px;border:1px solid #40EFC1;font-size:15px;color:#40EFC1;font-weight:700;background: url(../../assets/image/bg/bg-select01-white.svg) no-repeat right center;background-size: 24px 12px;}
.regForm .formRow .editor{width:calc( 100% - 100px );height:650px;overflow:hidden;margin:50px auto;background:#fff;}
.regForm .btnGroup{width:calc( 100% - 100px );margin:0 auto 0 auto;height:auto;overflow:hidden;display:flex;justify-content:flex-end;align-items:center;}
.regForm .btnGroup .btnCancel{display:flex;justify-content:center;align-items:center;width:158px;height:44px;border-radius:10px;border:0;background:#fff;padding:0;font-size:18px;color:#141B2D;font-weight:600;}
.regForm .btnGroup .btnConfirm{display:flex;justify-content:center;align-items:center;width:158px;height:44px;border-radius:10px;border:0;background:#40EFC1;padding:0;font-size:18px;color:#141B2D;font-weight:600;margin-left:30px;}


/* 선택삭제 버튼 추가 2024-01-08 */
.btn{margin-top:20px; width:130px;height:35px;overflow:hidden;border-radius:8px;border:0;background:#40EFC1;padding:0;display:flex;justify-content:center;align-items:center;font-size:14px;color:#141B2D;font-weight:700;}


@media screen and (max-width: 1500px) {

    .wrapper .subTitle{width:calc( 100% - 100px );margin:130px auto 0 auto;}
    .wrapper .cmmList{width:calc( 100% - 100px );margin:30px auto 0 auto;}
    .viewHeader{display:block;width:calc( 100% - 100px );margin:20px auto 0 auto;}
    .viewCont{width:calc( 100% - 340px );margin:0px auto 0 auto;}
    .boardBtn{width:calc( 100% - 100px );margin:0px auto 0 auto;}
    .regForm{width:calc( 100% - 100px );margin:20px auto 0 auto;}
    .viewHeader .rt{margin-top:20px;}
}

@media screen and (max-width: 1024px) {
    .wrapper{width:calc( 100% - 0px );padding-left:0px;}
    .wrapper .subTitle{width:calc( 100% - 40px );margin:130px auto 0 auto;justify-content: flex-start;}
    .wrapper .subTitle img{display:flex;}
    .wrapper .subTitle span{display:flex;margin-left:16px;font-size:18px;color:#40EFC1;font-weight:700;}

    .boardBtn{width:calc( 100% - 40px );margin:20px auto 0 auto;}

    .regForm{width:calc( 100% - 40px );margin:20px auto 0 auto;}

    .viewHeader{width:calc( 100% - 40px );margin:20px auto 0 auto;}
    .viewCont{width:calc( 100% - 80px );margin:0px auto 0 auto;}
    .viewHeader .rt{margin-top:20px;}
    .viewHeader .rt .vtit{text-align:left;font-size:18px;}
    .viewHeader .rt .vinfo span{font-size:15px;}
    .viewHeader .lt .select{margin-right:10px;}

    .wrapper .cmmList{width:calc( 100% - 40px );margin:0px auto 0 auto;}
    .boardSearch{width:300px;margin:0 auto;}
    .boardSearch .select{width:100px;}
    .wrapper .cmmList ul{margin-top:20px;padding:0;width:100%;}
    .wrapper .cmmList ul li{margin:0;padding:15px 0;border-bottom:1px solid #40EFC1;}
    .wrapper .cmmList ul li + li{margin:0;}
    .wrapper .cmmList ul li a{display:block;}
    .wrapper .cmmList ul li a{}
    .wrapper .cmmList ul li .cate{font-size:12px;width:50%;float:left;color:#40EFC1;}
    .wrapper .cmmList ul li .status{font-size:14px;width:50%;float:right;display:flex;justify-content:flex-end;align-items:center;}
    .wrapper .cmmList ul li .tit{float:left;margin-top:10px;width:calc( 100% - 80px );height:auto;overflow:Hidden;flex:1;text-overflow:ellipsis; white-space:nowrap;word-wrap:normal;font-size:11px;color:#fff;font-weight:400;}

    .wrapper .cmmList ul li .date{float:left;width:80px;margin-top:10px;display:flex;justify-content:flex-end;align-items:center;font-size:9px;color:#fff;font-weight:400;}

    .regForm .formRow .formItem{padding:10px 0;}
    .regForm .formRow .formItem .inp{border:0;padding:0 12px;height:40px;font-size:18px;color:#40EFC1;font-weight:700;background:transparent;width:100%;flex:1;}

    .regForm .formRow .editor{width:calc( 100% - 0px );height:400px;margin:20px auto;}
    .regForm .btnGroup .btnCancel{height:45px;font-size:18px;}
    .regForm .btnGroup .btnConfirm{height:45px;font-size:18px;margin-left:10px;}
    .regForm .formRow .formItem .select{margin-right:10px;}

    .boardBtn button{width:80px;height:30px;border-radius:15px;}
    .boardBtn button span{font-size:12px;}
}
