.wrapper{width:calc( 100% - 110px );padding-left:110px;height:auto;overflow:hidden;background:#fff;min-height:100vh;}

.signupWrap{width:862px;height:auto;overflow:hidden;margin:130px auto 0 auto;}
.signupWrap.type01{width:500px;}
.signupWrap .slogo{width:100%;height:auto;overflow:hidden;text-align:center;}
.signupWrap .slogo video{max-width:200px;}
.signupWrap .stit{margin-top:20px;width:100%;height:auto;overflow:hidden;font-size:32px;color:#141B2D;font-weight:700;text-align:center;}
.signupWrap .sdesc{width:100%;height:auto;overflow:hidden;font-size:22px;color:rgba(0, 0, 0, 0.25);;font-weight:700;text-align:center;margin-top:20px;}
.signupWrap ul{margin-top:30px;width:100%;height:auto;overflow:hidden;}
.signupWrap ul li{margin-top:20px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:center;align-items:center;}
.signupWrap ul li a{display:flex;justify-content:center;align-items:center;width:330px;height:42px;border-radius:10px;border:1px solid #000;background:#fff;padding:0;}
.signupWrap ul li a img{display:flex;}
.signupWrap ul li a span{margin-left:10px;display:flex;justify-content:flex-start;align-items:center;width:126px;font-size:13px;color:#000;font-weight:700;}
.signupWrap ul li:nth-child(2) a{background:#FFF200;border-color:#FFF200;}

.signupWrap ul li .formItem{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.signupWrap ul li .formItem .inp{width:100%;flex:1;height:40px;border-radius:9px;padding:0 10px;font-size:13px;color:#000;border:1px solid #838383;}
.signupWrap ul li .formItem button{margin-left:10px;display:flex;justify-content:center;align-items:center;width:140px;height:42px;border:1px solid #838383;border-radius:9px;padding:0;font-size:16px;color:#000;background:#fff;}
.signupWrap ul li .formItem .select{border-radius:3px;background-color:#fff !important;max-width:220px;box-shadow: 0px 3.56119px 2.67089px 1.7806px rgba(0, 0, 0, 0.04);height:36px;width:100%;flex:1;padding:0 10px;border:0;font-size:16px;color:#333;
height: 40px; border-radius: 9px; font-size: 13px; color: #000; border: 1px solid #838383;}
.signupWrap ul li .formItem .select + .select{margin-left:20px;}

.signupWrap .helpmsg{width:100%;height:auto;overflow:hidden;margin-top:20px;font-size:12px;color:#333;}
.signupWrap .btnGroup{margin-top:40px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:center;align-items:center;}
.signupWrap .btnGroup button{width:100%;height:55px;border-radius:7px;border:0;overflow:hidden;display:flex;justify-content:center;align-items:center;padding:0;background: #141B2D;box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.04);font-size:19px;color:#fff;font-weight:400;}
.signupWrap .btnGroup button[disabled]{background:#838383;}

.dialog{position:fixed;left:0;top:100%;width:100%;height:100%;overflow:Hidden;z-index:99;background:rgba(0,0,0,.5);display:flex;justify-content:center;align-items:flex-end;}
.dialog.on{top:0}
.dialog .popCont{transition:all .5s;transition-delay:.5s;transform:translateY(500px);opacity:0;position:relative;width:580px;height:auto;overflow:hidden;padding:40px 100px;border-radius:20px;border-bottom-left-radius:0;border-bottom-right-radius:0;background:#fff;}
.dialog.on .popCont{transform:translateY(0px);opacity:1;}
.dialog .popCont .popClose{position:absolute;right:30px;top:30px;border:0;padding:0;background:transparent;}
.dialog .popCont .ptit{width:100%;height:auto;overflow:hidden;font-size:25px;color:#000;font-weight:700;}
.dialog .popCont .agreeList{margin-top:30px;width:100%;height:auto;overflow:hidden;}
.dialog .popCont .agreeList li{margin-top:8px;width:100%;height:auto;overflow:hidden;}
.dialog .popCont .agreeList li label{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.dialog .popCont .agreeList li label input{display:flex;}

.dialog .popCont .agreeList li label input[type=checkbox]{outline:none;float:left;background:url(../../assets/image/ico/ico-agree-chk.svg) no-repeat;border:0;width:17px;height:14px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;}
.dialog .popCont .agreeList li label input[type=checkbox]:checked{width:17px;height:14px;background:url(../../assets/image/ico/ico-agree-chk-on.svg) no-repeat;}
.dialog .popCont .agreeList li label input[type=checkbox]::-ms-check{border:0;outline:none;appearance: none;-moz-appearance: none;-webkit-appearance: none;width:17px;height:14px;content:"";background:url(../../assets/image/ico/ico-agree-chk.svg) no-repeat;}
.dialog .popCont .agreeList li label input[type=checkbox]:checked::-ms-check{color:transparent;background:url(../../assets/image/ico/ico-agree-chk-on.svg) no-repeat;}


.dialog .popCont .agreeList li label span{display:flex;margin-left:10px;font-size:20px;color:#333;}
.dialog .popCont  .btnGroup{margin-top:48px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:center;align-items:center;}
.dialog .popCont  .btnGroup button{width:100%;height:58px;border-radius:20px;border:0;overflow:hidden;display:flex;justify-content:center;align-items:center;padding:0;background: #141B2D;box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.04);font-size:27px;color:#fff;font-weight:400;}

.popup{width:calc( 100% - 40px - 50px );max-width:687px;height:auto;padding:48px 25px;overflow:hidden;background:#ededed;position:fixed;left:50%;top:100px;transform: translateX(-50%);z-index:99999;display:none;}
.popup.on{display:block;}
.popup .popClose{position:absolute;right:30px;top:20px;border:0;padding:0;background:transparent;}
.popup .searchForm{margin-top:60px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.popup .searchForm .inp{width:100%;height:40px;overflow:hidden;flex:1;padding:0 10px;border-radius:3px;border:1px solid #1F2A40;font-size:13px;color:#141B2D;font-weight:600;background:#fff url(../../assets/image/ico/ico-search01.svg) no-repeat right center;}
.popup .searchForm button{margin-left:10px;width:120px;border-radius:3px;border:0;background:#40EFC1;padding:0;font-size:15px;color:#141B2D;font-weight:600;height:42px;overflow:hidden;display:flex;justify-content:center;align-items:center;}
.popup .tblWrap{margin-top:35px;width:100%;height:auto;overflow:hidden;}
.popup .tblWrap table{width:100%;table-layout:fixed;border:0;border-collapse:collapse;}
.popup .tblWrap table th{height:36px;text-align:center;font-weight:600;font-size:11px;color:#141B2D;}
.popup .tblWrap table td{height:32px;text-align:center;font-size:14px;color:#141B2D;font-weight:600;border-top:1px solid #141B2D;}

@media screen and (max-width: 1500px) {
}

@media screen and (max-width: 1024px) {
    .wrapper{width:calc( 100% - 0px );padding-left:0px;}
    .signupWrap{width:calc( 100% - 40px );padding-bottom:20px;}
    .signupWrap .stit{font-size:20px;}
    .signupWrap .sdesc{font-size:14px;}
    .signupWrap ul{margin-top:20px;}
    .signupWrap ul li{margin-top:21px;}


    .dialog .popCont{width:280px;height:auto;overflow:hidden;padding:20px 20px;}
    .dialog .popCont .popClose{right:20px;top:20px;}
    .dialog .popCont .ptit{font-size:20px;}
    .dialog .popCont .agreeList{margin-top:20px;}


    .dialog .popCont .agreeList li label span{font-size:16px;}
    .dialog .popCont  .btnGroup{margin-top:20px;}
    .dialog .popCont  .btnGroup button{height:45px;font-size:18px;border-radius:10px;}

    .signupWrap.type01{max-width:500px;width:calc( 100% - 40px );}
    .signupWrap ul li .formItem .inp{height:40px;padding:0 10px;font-size:16px;width:calc( 100% - 22px );font-size:9px;}
    .signupWrap ul li .formItem button{margin-left:0px;float:right;border-radius:5px;margin-top:10px;width:100px;height:22px;font-size:8px;border-color:#838383;background:#f2f2f2;}
    .signupWrap .helpmsg{margin-top:20px;font-size:8.66px;color:#333;}
    .signupWrap .btnGroup{margin-top:40px;}
    .signupWrap .btnGroup button{height:45px;border-radius:10px;font-size:13.7px;}

    .signupWrap ul li .formItem{display:block;}
    .signupWrap .slogo video{max-width:151px;}
}