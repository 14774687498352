.dialog{position:fixed;left:0;top:100%;width:100%;height:100%;overflow:Hidden;z-index:99;background:rgba(0,0,0,.5);display:flex;justify-content:center;align-items:flex-end;}
.dialog.on{top:0}
.dialog .popCont{transition:all .5s;transition-delay:.5s;transform:translateY(500px);opacity:0;position:relative;width:580px;height:auto;overflow:hidden;padding:40px 100px;border-radius:20px;border-bottom-left-radius:0;border-bottom-right-radius:0;background:#fff;}
.dialog.on .popCont{transform:translateY(0px);opacity:1;}
.dialog .popCont .popClose{position:absolute;right:30px;top:30px;border:0;padding:0;background:transparent;}
.dialog .popCont .ptit{width:100%;height:auto;overflow:hidden;font-size:25px;color:#000;font-weight:700;}
.dialog .popCont .agreeList{margin-top:30px;width:100%;height:auto;overflow:hidden;}
.dialog .popCont .agreeList li{margin-top:8px;width:100%;height:auto;overflow:hidden;}
.dialog .popCont .agreeList li label{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.dialog .popCont .agreeList li label input{display:flex;}

.dialog .popCont .agreeList li label input[type=checkbox]{outline:none;float:left;background:url(../../assets/image/ico/ico-agree-chk.svg) no-repeat;border:0;width:17px;height:14px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;}
.dialog .popCont .agreeList li label input[type=checkbox]:checked{width:17px;height:14px;background:url(../../assets/image/ico/ico-agree-chk-on.svg) no-repeat;}
.dialog .popCont .agreeList li label input[type=checkbox]::-ms-check{border:0;outline:none;appearance: none;-moz-appearance: none;-webkit-appearance: none;width:17px;height:14px;content:"";background:url(../../assets/image/ico/ico-agree-chk.svg) no-repeat;}
.dialog .popCont .agreeList li label input[type=checkbox]:checked::-ms-check{color:transparent;background:url(../../assets/image/ico/ico-agree-chk-on.svg) no-repeat;}


.dialog .popCont .agreeList li label span{display:flex;margin-left:10px;font-size:20px;color:#333;}
.dialog .popCont  .btnGroup{margin-top:48px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:center;align-items:center;}
.dialog .popCont  .btnGroup button{width:100%;height:58px;border-radius:20px;border:0;overflow:hidden;display:flex;justify-content:center;align-items:center;padding:0;background: #141B2D;box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.04);font-size:27px;color:#fff;font-weight:400;}
