

.overflow-hidden {
   
    width: 90%;
    margin: 0px auto;
}
.bg-transparent {
    background-color: #fff;
}
.border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgb(33 33 33 / var(--tw-border-opacity));
}
.border-b-2 {
    border-bottom-width: 2px;
}
.text-inherit {
    color: inherit;
}

.text-gray-900 {
    height: 38px !important;
    font-weight: 400;
    font-size: 1rem !important;
}

.text-blue-gray-900 {
    height: 38px !important;
    font-weight: 400;
    font-size: 1rem !important;
}

.z-20 {
    z-index: 20;
}
.border-blue-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgb(236 239 241 / var(--tw-border-opacity));
}
.border-b, .border-y {
    border-bottom-width: 1px !important;
}


*,:after,:before {
    
    border: 0 solid #eee
}





.bg-opacity-60 {
    --tw-bg-opacity: 0.6;
    background-color: #fff !important;
}

.bg-opacity-80 {
    --tw-bg-opacity: 0.8
}

.bgcolor {
    background-color: #fff !important;
}


