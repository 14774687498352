.slider {
    margin:0 5px;
    overflow:"hidden";
    padding:0 0;
    max-height: 300px;
    position: relative;
    display: inline-block; 
  }
  
.slider img {
    width: 100%;
    max-height: 300px;
    display: block;
}

.slider button {
    position: absolute;
    top: 0px; /* Adjust top position as needed */
    right: 0px; /* Adjust right position as needed */
    background-color: #3498d000; /* Button background color */
    color: #000; /* Button text color */
    padding: 5px 10px; /* Adjust padding as needed */
    border: none; /* Remove button border */
    cursor: pointer; /* Add pointer cursor for interaction */
    font-size: 1.4em;
}

.react-multi-carousel-list {
    /* margin:20px auto 0 auto; */
    /* max-width:836px; */
    /* padding: 2rem 0 0 0; */
    margin-top: 4rem;
}