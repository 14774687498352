.wrapper{width:calc( 100% );padding-bottom:100px;height:auto;overflow:hidden;}


.spacedHeader{width:1300px;height:auto;margin:80px auto 0 auto;}
.spacedHeader .pageLoacation{width:100%;height:auto;overflow:Hidden;display:flex;justify-content:space-between;align-items:center;}
.spacedHeader .pageLoacation .cate{font-size:26px;color:#fff;font-weight:700;}
.spacedHeader .pageLoacation ul{display:flex;justify-content:flex-start;align-items:center;}
.spacedHeader .pageLoacation ul li{display:flex;position:relative;padding:0 10px;}
.spacedHeader .pageLoacation ul li + li:before{content:"";width:2px;height:20px;overflow:Hidden;display:block;background:#fff;position:absolute;left:0;top:50%;margin-top:-10px;}
.spacedHeader .pageLoacation ul li a{display:flex;font-size:21px;color:#fff;font-weight:700;}
.spacedHeader .pageLoacation ul li a.on{color:#40EFC1;}

.spacedHeader .pageTitle{margin-top:40px;width:100%;height:auto;display:flex;justify-content:center;align-items:center;position:relative;}
.spacedHeader .pageTitle.type02{padding-bottom:36px;border-bottom:1px solid #40EFC1;}
.spacedHeader .pageTitle img{display:flex;}
.spacedHeader .pageTitle span{display:flex;font-size:24px;color:#40EFC1;font-weight:700;border-bottom:2px solid #40EFC1;padding-bottom:20px;display:flex;justify-content:center;width:100%;}

.spacedHeader .pageTitle strong{display:flex;font-size:28px;color:#fff;margin-right:5px;font-weight:700;}
.spacedHeader .pageTitle .select{position:absolute;left:0;top:0;display:flex;
    width: 172px;
    height: 35px;
    border: 1px solid #fff;
    background: rgba(31, 42, 64, 0.00) url(../../../../assets/image/ico/ico-select01-arr.svg) no-repeat right center;
    text-align: center;
    text-indent: -20px;
    font-size: 16px;
    color: #fff;
    font-weight: 600;}
.spacedHeader .pageTitle button{width:130px;height:35px;overflow:hidden;border-radius:8px;border:0;background:#40EFC1;padding:0;display:flex;justify-content:center;align-items:center;font-size:14px;color:#141B2D;font-weight:700;}
.spacedHeader .pageTitle .sideBtn{position:absolute;right:0;top:0;}
.spacedHeader .pageTitle .sideBtn ul{position:absolute;left:0;top:35px;}
.spacedHeader .pageTitle .sideBtn ul li{margin-top:5px;}
.spacedHeader .pageTitle .pageLoacation{width:auto;position:absolute;right:0;top:0;}


.spaceWrap{width:1300px;height:auto;overflow:Hidden;margin:100px auto;display:flex;justify-content:space-between;align-items:flex-start;position:relative;}
.spaceWrap .spaceControl{width:100%;display:none;justify-content:space-between;align-items:center;position:absolute;left:0;top:30px;}
.spaceWrap .spaceControl button{width:31px;height:31px;border:0;background:transparent;padding:0;}
.spaceWrap .spaceControl button.disabled{opacity:.5;}
.spaceWrap .sitem{width:270px;height:auto;overflow:hidden;}
.spaceWrap .sitem .sico{width:100%;height:auto;overflow:hidden;border-bottom:1px solid #fff;background:url(../../../../assets/image/bg/bg-house-top.png) no-repeat center top;padding-top:45px;display:flex;justify-content:center;align-items:center;}
.spaceWrap .sitem .sico img{display:flex;}
.spaceWrap .sitem .sico span{display:flex;justify-content:center;align-items:center;height:80px;margin-left:10px;font-size:20px;color:#fff;font-weight:700;}
.spaceWrap .sitem .sbox{width:100%;height:auto;overflow:hidden;}
.spaceWrap .sitem .sbox dl.type01{width:100%;height:auto;overflow:hidden;}
.spaceWrap .sitem .sbox dl.type01 dt{width:100%;height:auto;overflow:hidden;margin-top:35px;font-size:23px;color:#fff;font-weight:700;text-align:center;}
.spaceWrap .sitem .sbox dl.type01 dt strong{font-size:20px;color:#fff;font-weight:700;}
.spaceWrap .sitem .sbox dl.type01 dd{width:100%;height:auto;overflow:hidden;}
.spaceWrap .sitem .sbox dl.type01 dd ul{width:calc( 100% + 20px );margin-left:-10px;height:auto;overflow:hidden;}
.spaceWrap .sitem .sbox dl.type01 dd ul li{margin-top:20px;margin-left:10px;margin-right:3px;float:left;display:flex;justify-content:center;align-items:center;flex-direction:column;width:83px;height:60px;overflow:hidden;background:url(../../../../assets/image/bg/bg-house01.png) no-repeat center center;}
.spaceWrap .sitem .sbox dl.type01 dd ul li.on{background:url(../../../../assets/image/bg/bg-house01-on.png) no-repeat center center;}
.spaceWrap .sitem .sbox dl.type01 dd ul li .key{display:flex;font-size:16px;color:#141B2D;font-weight:600;}
.spaceWrap .sitem .sbox dl.type01 dd ul li .value{display:flex;font-size:16px;color:#141B2D;font-weight:600;}

.spaceWrap .sitem .sbox dl.type02{width:100%;height:auto;overflow:hidden;}
.spaceWrap .sitem .sbox dl.type02 dt{width:100%;height:auto;overflow:hidden;margin-top:35px;font-size:23px;color:#40EFC1;font-weight:700;text-align:left;}
.spaceWrap .sitem .sbox dl.type02 dt strong{font-size:20px;color:#40EFC1;font-weight:700;}
.spaceWrap .sitem .sbox dl.type02 dd{width:100%;height:auto;overflow:hidden;}
.spaceWrap .sitem .sbox dl.type02 dd + dt{border-top:1px solid rgba(255, 255, 255, 0.50);margin-top:24px;padding-top:24px;}
.spaceWrap .sitem .sbox dl.type02 dd ul{width:100%;height:auto;overflow:hidden;}
.spaceWrap .sitem .sbox dl.type02 dd ul li{margin-top:24px;width:100%;height:auto;overflow:hidden;}
.spaceWrap .sitem .sbox dl.type02 dd ul li .type{width:100%;height:auto;overflow:hidden;text-align:center;font-size:19px;color:#40EFC1;font-weight:700;line-height:22px;}
.spaceWrap .sitem .sbox dl.type02 dd ul li .minfo{width:100%;height:auto;overflow:hidden;text-align:center;font-size:17px;color:#fff;font-weight:600;line-height:42px;}
.spaceWrap .sitem .sbox dl.type02 dd ul li .mdesc{width:100%;height:auto;overflow:hidden;text-align:left;font-size:12px;color:#fff;font-weight:600;line-height:1.5em;}

.spaceWrap.type01{justify-content: center;gap:100px;}

.spaceView{width:1300px;height:auto;overflow:Hidden;margin:45px auto 0 auto;padding-top:45px;border-top:1px solid #40EFC1;}
.spaceView .optList{width:100%;height:auto;display:flex;justify-content:flex-start;align-items:center;}
.spaceView .optList ul{width:100%;height:auto;display:flex;justify-content:space-between;gap:10px;align-items:flex-start;}
.spaceView .optList ul li{display:flex;justify-content:flex-start;align-items:flex-start;flex:1;width:100%;}
.spaceView .optList.type01 ul{justify-content:flex-start;}
.spaceView .optList.type01 ul li{width:auto;flex:initial;display:inline-flex;}
.spaceView .optList.type01 ul li .value{display:inline-flex;height:38px;border: 1px solid #fff;justify-content:center;align-items:center;font-size:16px;color:#141B2D;font-weight:600;background:#fff;padding:0 20px;}

.spaceView .optList ul li .select{display:flex;
    width: 100%;
    height: 48px;
    border: 1px solid #fff;
    background: rgba(31, 42, 64, 0.00) url(../../../../assets/image/ico/ico-select01-arr.svg) no-repeat right center;
    text-align: center;
    text-indent: -20px;
    font-size: 16px;
    color: #fff;
    font-weight: 600;}

.spaceView .optList ul li button{width:100px;height:48px;overflow:hidden;padding:0;border-radius:5px;border:1px solid #fff;background:transparent;display:flex;justify-content:center;align-items:center;font-size:16px;color:#fff;font-weight:600;}
.spaceView .formList{margin-top:90px;width:100%;height:auto;overflow:hidden;}
.spaceView .formList ul{width:100%;display:flex;justify-content:flex-start;align-items:center;}
.spaceView .formList ul li{width:196px;margin-right:30px;}
.spaceView .formList ul li .label{display:flex;font-size:22px;color:#40EFC1;font-weight:600;padding-bottom:15px;}
.spaceView .formList ul li .formItem{width:100%;flex:1;display:flex;justify-content:flex-start;align-items:center;}
.spaceView .formList ul li .formItem .inp{width:100%;flex:1;height:46px;border:0;font-size:16px;color:#141B2D;padding:0 10px;}

.spaceView .optList .inpWrap{width:100%;position:relative;}
.spaceView .optList .inpWrap .inp{width: 180px;
    padding-left: 20px;
    color: #141B2D;
    font-weight: 600;
    font-size: 20px;
    height: 46px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #fff;
    background: #fff;
    position: relative;}
.spaceView .optList .inpWrap .unit{color:rgba(20, 27, 45, 0.62);;font-size:16px;font-weight:600;height:46px;position:absolute;right:10px;top:0;display:flex;justify-content:flex-start;align-items:center;}

.spaceView dl{width:100%;height:auto;overflow:hidden;}
.spaceView dl dt{margin-top:60px;width:100%;height:auto;display:flex;justify-content:flex-start;align-items:flex-start;}
.spaceView dl dt img{display:flex;}
.spaceView dl dt span{display:flex;margin-left:10px;font-size:25px;color:#40EFC1;font-weight:700;}
.spaceView dl dd{width:100%;height:auto;overflow:hidden;}
.spaceView dl dd .formItem{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-end;align-items:flex-start;}
.spaceView dl dd .formItem .textarea{margin-top:20px;width:calc( 100% - 60px );font-size:22px;color:#141B2D;line-height:1.5em;height:130px;padding:30px;border-radius:8px;overflow:hidden;background:#DBDBDB;resize:none;border:0;}
.spaceView dl dd .formItem button{display:flex;justify-content:flex-start;align-items:center;border:0;padding:0;background:transparent;}
.spaceView dl dd .formItem button span{display:flex;font-size:20px;color:#fff;font-weight:600;}
.spaceView dl dd .formItem button img{display:flex;margin-left:10px;}
.spaceView dl dd ul{margin-top:20px;width:100%;height:auto;overflow:hidden;}
.spaceView dl dd ul li{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.spaceView dl dd ul li + li{margin-top:40px;}
.spaceView dl dd ul li .sdate{display:flex;justify-content:flex-start;align-items:center;width:152px;font-size:22px;color:#fff;font-weight:600;}
.spaceView dl dd ul li .stit{display:flex;justify-content:flex-start;align-items:center;width:100%;padding:0 24px;flex:1;height:70px;border-radius:8px;font-size:22px;color:#141B2D;font-weight:600;background:#fff;}
.spaceView dl dd .photoList01{max-width:100%;width:100%;height:auto;overflow:hidden;}
.spaceView dl dd .photoList01 ul{width:calc( 100% + 20px );height:auto;overflow:hidden;margin-left:-10px;}
.spaceView dl dd .photoList01 ul li{width:calc( 100% / 4 - 20px );height:auto;overflow:hidden;margin:20px 10px 0 10px;float:left;}
.spaceView dl dd .photoList01 ul li .mpic{border-radius:8px;width:100%;height:283px;overflow:hidden;background-position:center center !important;background-size:cover !important;}


.dialog{position:fixed;left:0;top:100%;width:100%;height:100%;overflow:Hidden;z-index:99;background:rgba(0,0,0,.5);display:flex;justify-content:center;align-items:center;}
.dialog.on{top:0}
.dialog .popCont{transition:all .5s;transition-delay:.5s;transform:translateY(500px);opacity:0;position:relative;width:930px;height:auto;overflow:hidden;padding:40px 40px;border-radius:20px;background:#fff;}
.dialog.on .popCont{transform:translateY(0px);opacity:1;}
.dialog .popCont .popClose{position:absolute;right:30px;top:30px;border:0;padding:0;background:transparent;}
.dialog .popCont .ptit{width:100%;height:auto;overflow:hidden;display:flex;justify-content:center;align-items:center;}

.dialog .popCont .ptit img{display:flex;}
.dialog .popCont .ptit strong{display:flex;margin-left:10px;font-size:28px;color:#141B2D;font-weight:700;}
.dialog .popCont .optWrap{margin-top:40px;width:100%;height:auto;overflow:Hidden;display:flex;justify-content:flex-start;align-items:center;}
.dialog .popCont .optWrap .dateWrap{display:flex;justify-content:flex-start;align-items:center;}
.dialog .popCont .optWrap .e01{display:flex;justify-content:center;align-items:center;width:40px;font-size:10px;color:#141B2D;font-weight:600;}
.dialog .popCont .optWrap .select{height:39px;border:1px solid #141B2D;display:flex;width:100px;font-size:18px;color:#141B2D;padding-left:20px;}
.dialog .popCont .optWrap .select + .select{margin-left:10px;}
.dialog .popCont .textareaWrap{width:100%;height:auto;overflow:hidden;}
.dialog .popCont .textareaWrap .cnt{width:100%;height:30px;overflow:hidden;display:flex;justify-content:flex-end;align-items:center;font-size:12px;color:#141B2D;}
.dialog .popCont .textareaWrap .textarea{width:calc( 100% - 40px );padding:20px;overflow:hidden;resize:none;border:0;height:154px;font-size:18px;color:#141B2D;background:#DBDBDB;}
.dialog .popCont .btnGroup{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-end;align-items:center;padding-top:24px;}
.dialog .popCont .btnGroup button{width:220px;height:60px;border:0;padding:0;background:#40EFC1;display:flex;justify-content:center;align-items:center;border-radius: 10px;font-size:21px;color:#141B2D;font-weight:700;}

.selectItemWrap{position:relative;margin-right:54px;}
.selectItemWrap>a{text-decoration:none !important;width:180px;padding-left:20px;color:#141B2D;font-weight:600;font-size:20px;height:46px;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;border:1px solid #fff;background:#fff;position:relative;}
.selectItemWrap>a:before{content:"";width:32px;height:11px;overflow:hidden;display:block;background:url(../../../../assets/image/ico/ico-select-arr-black.svg) no-repeat right center;position:absolute;right:10px;top:50%;margin-top:-6px;transform:rotate(180deg)}
.selectItemWrap .selectResultWrap{width:calc( 100% - 2px );height:auto;overflow:Hidden;border:1px solid #fff;display:none;
}
.selectItemWrap>a.on:before{transform:rotate(0deg)}
.selectItemWrap>a.on + .selectResultWrap{display:block;background:#fff;}
.selectItemWrap .selectResultWrap ul{display:block;width:100%;height:auto;overflow:hidden;}
.selectItemWrap .selectResultWrap ul li{width:100%;height:auto;overflow:hidden;border-top:1px solid #141B2D;}
.selectItemWrap .selectResultWrap ul li + li{border-top:1px solid #141B2D;}
.selectItemWrap .selectResultWrap ul li a{width:100%;height:45px;overflow:hidden;font-size:20px;color:#141B2D;display:flex;justify-content:center;margin:0;align-items:center;}
.selectItemWrap .selectResultWrap ul li a:hover{color:#141B2D;background:#40EFC1;}


.paging{max-width:1300px;width:100%;height:auto;overflow:Hidden;display:flex;justify-content:center;align-items:center;margin:50px auto 50px auto;}
.paging button{border:0;background:transparent;width:24px;height:24px;overflow:Hidden;padding:0;display:flex;justify-content:center;align-items:center;}
.paging ul{display:flex;justify-content:center;align-items:center;margin:0 20px;}
.paging ul li{display:flex;justify-content:center;align-items:center;}
.paging ul li a{font-size:15px;color:rgba(255,255,255,.7);display:flex;justify-content:center;align-items:center;width:24px;height:24px;}
.paging ul li a.on{color:rgba(255,255,255,1);font-weight:500;}

.boardSearch{max-width:1300px;margin:0 auto;width:100%;height:auto;overflow:Hidden;display:flex;justify-content:center;align-items:center;}
.boardSearch .searchForm{width:520px;border:1px solid #141B2D;height:40px;overflow:hidden;background:#fff;margin:0;display:flex;justify-content:flex-start;position:relative;align-items:center;}
.boardSearch .select{width:130px;height:40px;border:0;padding-left:12px;font-size:13px;color:#000;}
.boardSearch .inp{padding-left:12px;width:100%;flex:1;height:40px;border-left:1px solid #141B2D;}
.boardSearch .searchForm button{position:absolute;border:0;right:0;top:0;width:60px;height:40px;overflow:hidden;padding:0;background:transparent;display:flex;justify-content:center;align-items:center;}
.boardSearch .btnReq{margin-left:30px;height:40px;width:198px;border-radius:5px;border:0;background:#40EFC1;display:flex;justify-content:center;align-items:center;}
.boardSearch .btnReq img{display:flex;}
.boardSearch .btnReq span{display:flex;margin-left:10px;font-size:15px;color:#141B2D;font-weight:700;}
.boardSearch .btnReq.type01{margin-left:0;margin-top:40px;}
.boardSearch .btnReq.type01 span{margin-left:0;}

.excelDownload{width:calc( 100% - 100px );max-width:1300px;height:auto;overflow:hidden;margin:30px auto 0 auto;display:flex;justify-content:flex-end;align-items:center;}
.excelDownload button{display:flex;justify-content:center;align-items:center;border:0;border-radius:4px;background:#fff;width:197px;height:40px;overflow:hidden;}
.excelDownload button img{display:flex;}
.excelDownload button span{display:flex;font-size:16px;color:#105D31;font-weight:700;}


.reqView{max-width:1300px;margin:20px auto 0 auto;width:100%;height:auto;overflow:Hidden;border-top:1px solid #40EFC1;}
.reqView .reqHeader{width:calc( 100% - 60px );padding:0 30px;height:100px;overflow:Hidden;border-bottom:1px solid rgba(255, 255, 255, 0.30);display:flex;justify-content:space-between;align-items:center;}
.reqView .reqHeader .mtit{width:100%;flex:1;font-size:23px;color:#40EFC1;font-weight:700;}
.reqView .reqHeader .mdate{display:flex;font-size:16px;color:#fff;font-weight:500;}

.reqView .reqHeader .inp{height:40px;width:100%;flex:1;border:0;background:transparent;font-size:26px;color:#40EFC1;font-weight:700;}
.reqView .reqHeader .inp::placeholder{color:#40EFC1;}
.reqView .reqHeader .select{height:39px;border:1px solid #141B2D;display:flex;margin-right:20px;width:150px;font-size:18px;background-color:#fff;color:#141B2D;padding-left:20px;}

.reqView .reqInfo{width:100%;height:72px;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;border-bottom:1px solid rgba(255, 255, 255, 0.30);}
.reqView .reqInfo ul{display:flex;justify-content:flex-start;align-items:center;}
.reqView .reqInfo ul li{display:flex;margin-left:70px;font-size:16px;color:#40EFC1;font-weight:500;}

.reqView .reqCont{width:calc( 100% - 70px );height:auto;overflow:hidden;padding:35px;}
.reqView .reqCont .mcont{background: rgba(78, 84, 102, 0.30);
    box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.04);
    width:calc( 100% - 190px );height:auto;overflow:hidden;border-radius:8px;
    padding:35px 95px 95px 95px;
}
.reqView .reqCont .mcont .mdesc{width:100%;height:auto;overflow:hidden;font-size:19px;color:#fff;font-weight:600;line-height:1.5em;}

.reqView .minfo .mradio{width:100%;height:auto;overflow:hidden;margin-bottom:20px;display:flex;justify-content:flex-start;align-items:center;}
.reqView .minfo .mradio label{display:flex;justify-content:flex-start;align-items:center;margin-right:20px;}
.reqView .minfo .mradio label input{display:flex;}
.reqView .minfo .mradio label span{display:flex;margin-left:10px;font-size:13px;color:#fff;font-weight:800;}

.reqView .reqCont .mcont .mradio{width:100%;height:auto;overflow:hidden;margin-top:30px;display:flex;justify-content:flex-start;align-items:center;}
.reqView .reqCont .mcont .mradio label{display:flex;justify-content:flex-start;align-items:center;margin-right:20px;}
.reqView .reqCont .mcont .mradio label input{display:flex;}
.reqView .reqCont .mcont .mradio label span{display:flex;margin-left:10px;font-size:13px;color:#fff;font-weight:800;}
.reqView .reqCont .mcont .mpic{width:100%;height:auto;overflow:hidden;margin-top:30px;}
.reqView .reqCont .mcont .mpic img{max-width:100%;}
.reqView .reqCont .mcont .mpic ul{width:100%;height:auto;overflow:hidden;}
.reqView .reqCont .mcont .mpic ul li{width:calc( 50% - 10px );float:left;margin-top:10px;height:auto;overflow:hidden;}
.reqView .reqCont .mcont .mpic ul li:nth-child(even){float:right;}
.reqView .reqCont .mcont .mpic ul li .mpic{width:100%;height:300px;overflow:hidden;background-size:cover !important;}

.reqView .reqCont .minfo{width:100%;height:auto;overflow:hidden;margin-top:80px;}
.reqView .reqCont .minfo ul{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.reqView .reqCont .minfo ul li{display:flex;justify-content:flex-start;align-items:center;float:left;}
.reqView .reqCont .minfo ul li + li{margin-left:40px;}
.reqView .reqCont .minfo ul li:nth-child(1){width:300px;}
.reqView .reqCont .minfo ul li:nth-child(2){width:300px;}
.reqView .reqCont .minfo ul li:nth-child(3){width:100%;flex:1;}
.reqView .reqCont .minfo ul li .key{display:flex;font-size:18px;color:#DBDBDB;font-weight:700;}
.reqView .reqCont .minfo ul li .value{width:100%;flex:1;display:flex;margin-left:10px;font-size:23px;color:#40EFC1;font-weight:700;}
.reqView .reqCont .minfo ul li .value .select{box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.04);width:198px;height:40px;background-color:#fff;border:0;padding-left:20px;font-size:16px;color:#141B2D;font-weight:600;border-radius:8px;}
.reqView .reqCont .minfo ul li .value .inp{border-radius:8px;height:40px;border:0;background:#fff;box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.04);padding:0 20px;width:calc( 100% - 40px );font-size:16px;color:#141B2D;font-weight:600;}

.reqView .reqCont .reqBar{width:100%;height:auto;overflow:hidden;margin-top:70px;display:flex;justify-content:space-between;align-items:center;}
.reqView .reqCont .reqBar .rtit{display:flex;justify-content:flex-start;align-items:center;}
.reqView .reqCont .reqBar .rtit img{display:flex;}
.reqView .reqCont .reqBar .rtit span{display:flex;margin-left:10px;font-size:22px;color:#40EFC1;font-weight:700;}
.reqView .reqCont .reqBar button{display:flex;justify-content:center;align-items:center;background: #FFF;
    box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.04);width:170px;height:34px;overflow:hidden;padding:0;font-size:16px;color:#141B2D;font-weight:600;}

.reqView .reqCont .reqBar .select{height:35px;width:130px;border:1px solid #fff;padding-left:20px;font-size:16px;color:#fff;font-weight:600;background:url(../../../../assets/image/bg/bg-select01-white.svg) no-repeat right center;background-size: 24px 12px}

.reqView .reqCont .reqBar .radio{display:flex;justify-content:flex-start;align-items:center;}
.reqView .reqCont .reqBar .radio label{display:flex;justify-content:flex-start;align-items:center;margin-left:20px;}
.reqView .reqCont .reqBar .radio label input{display:flex;}
.reqView .reqCont .reqBar .radio label span{display:flex;margin-left:10px;font-size:15px;color:#fff;font-weight:800;}


.reqView .reqCont .overview{resize:none;width:calc( 100% - 60px );height:auto;min-height:130px;margin-top:20px;overflow:hidden;padding:30px;border-radius:8px;background:#fff;box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.04);font-size:22px;color:#141B2D;font-weight:600;line-height:1.5em;
}
.photoList{width:100%;max-width:836px;height:auto;overflow:hidden;margin:120px auto 0 auto;}
.photoUpload{background: #FFF url(../../../../assets/image/bg/bg-upload.png) no-repeat center center;width:100%;height:395px;overflow:hidden;border-radius:8px;
    box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.04);}
.photoUpload label{display:flex;width:100%;height:395px;}
.photoUpload label input{position:absolute;left:-2000000px;}

.reqView .reqCont .photoHeader{width:100%;height:auto;overflow:hidden;margin-top:50px;display:flex;justify-content:flex-end;align-items:center;}
.reqView .reqCont .photoHeader button{display:flex;justify-content:center;align-items:center;border:1px solid #fff;background:transparent;width:210px;height:38px;}
.reqView .reqCont .photoHeader button span{display:flex;font-size:17px;color:#fff;font-weight:600;}
.reqView .reqCont .photoHeader button img{display:flex;margin-left:10px;width:18px;}
.reqView .reqCont .photoHeader.sw{justify-content:space-between;}
.reqView .reqCont .photoHeader .select{height:39px;border:1px solid #141B2D;display:flex;margin-right:20px;width:150px;font-size:18px;background-color:#fff;color:#141B2D;padding-left:20px;}
.reqView .reqCont .photoHeader .status{display:flex;justify-content:center;align-items:center;background: transparent;border:1px solid #fff;
    box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.04);width:170px;height:34px;overflow:hidden;padding:0;font-size:16px;color:#fff;font-weight:600;}


.reqView .reqCont .photoList{width:100%;height:auto;overflow:hidden;margin-top:20px;}
.reqView .reqCont .photoList.type01{max-width:836px;margin-top:120px; }
.reqView .reqCont .photoList ul{width:calc( 100% + 20px );height:auto;overflow:hidden;margin-left:-10px;}
.reqView .reqCont .photoList ul li{float:left;width:calc( 25% - 20px );margin:10px;height:auto;overflow:hidden;}
.reqView .reqCont .photoList li .mpic{width:100%;height:326px;overflow:hidden;background-size:cover !important;}

.reqView .reqCont .mbtn{width:100%;height:auto;overflow:hidden;margin-top:100px;display:flex;justify-content:center;align-items:center;}
.reqView .reqCont .mbtn button{display:flex;justify-content:center;align-items:center;width:220px;height:44px;overflow:hidden;border-radius:10px;border:0;background:#40EFC1;padding:0;font-size:18px;color:#141B2D;font-weight:700;}

.spaceView .mbtn{width:100%;height:auto;overflow:hidden;margin-top:100px;display:flex;justify-content:center;align-items:center;}
.spaceView .mbtn button{display:flex;justify-content:center;align-items:center;width:196px;height:54px;overflow:hidden;border-radius:5px;border:0;background:#40EFC1;padding:0;font-size:18px;color:#141B2D;font-weight:700;}

.pinTh{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;padding-bottom:18px;padding-top:18px;}
.pinTh.type01{margin-top:120px;}
.pinTh li{width:100px;margin:0 10px;height:32px;display:flex;justify-content:center;align-items:center;font-size:18px;color:#40EFC1;font-weight:600;}
.pinTd{width:100%;height:auto;overflow:hidden;border-top:1px solid rgba(219, 219, 219, .2);}
.pinTd li{width:100%;height:73px;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;border-bottom:1px solid rgba(219, 219, 219, .2);}
.pinTd li .cell01{width:100px;margin:0 10px;height:36px;display:flex;justify-content:center;align-items:center;}
.pinTd li .cell01 button{width:64px;height:30px;border-radius:5px;border:1px solid #fff;background:transparent;padding:0;font-size:13px;color:#fff;font-weight:600;}
.pinTd li .cell02{width:100px;margin:0 10px;height:36px;display:flex;justify-content:center;align-items:center;font-size:18px;color:#fff;font-weight:600;}
.pinTd li .cell03{width:100px;margin:0 10px;height:36px;display:flex;justify-content:center;align-items:center;font-size:18px;color:#fff;font-weight:600;}
.pinTd li .cell04{width:100px;margin:0 10px;display:flex;justify-content:center;align-items:center;}
.pinTd li .cell05{width:100%;flex:1;margin:0 10px;display:flex;justify-content:flex-start;align-items:center;}
.pinTd li .cell05 span {font-size: 16px; color: rgba(20, 27, 45, 0.62); font-weight: 600; height: 35px; position: absolute; right: 8px; top: 0; display: flex; justify-content: flex-start;
    align-items: center;}
.pinTd li .inp{width:100%;flex:1;height:36px;border:0;background:#fff;padding:0 10px;font-size:18px;color:#333;font-weight:600;max-width:480px;}

.listOptForm{width:calc( 100% - 100px );max-width:1300px;height:auto;overflow:hidden;margin:80px auto 0 auto;display:flex;justify-content:flex-start;align-items:center;}
.listOptForm .formItem{display:flex;justify-content:flex-start;align-items:center;}
.listOptForm .formItem .select{display:flex;
    width: 172px;
    height: 35px;
    border: 1px solid #fff;
    background: rgba(31, 42, 64, 0.00) url(../../../../assets/image/ico/ico-select01-arr.svg) no-repeat right center;
    text-align: center;
    text-indent: -20px;
    font-size: 16px;
    color: #fff;
    font-weight: 600;}
.listOptForm .formItem .select + .select{margin-left:20px;}
.listOptForm .formItem .select.w01{width:100px;}
.listOptForm .formItem .e01{display:flex;justify-content:center;align-items:center;width:45px;height:28px;font-size:16px;color:#fff;font-weight:600;}

.chargeForm{width:calc( 100% - 40px );max-width:800px;height:auto;overflow:hidden;margin:20px auto 0 auto;}
.chargeForm .formItem{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.chargeForm .formItem .label{width:120px;font-size:18px;color:#40EFC1;font-weight:600;}
.chargeForm .formItem .inp{margin-right:60px;width:200px;height:34px;border:1px solid #fff;text-align:center;background:transparent;font-size:16px;color:#fff;font-weight:600;}
.chargeForm .formItem .select{display:flex;
    width: 172px;
    height: 35px;
    border: 1px solid #fff;
    background: rgba(31, 42, 64, 0.00) url(../../../../assets/image/ico/ico-select01-arr.svg) no-repeat right center;
    text-align: center;
    text-indent: -20px;
    font-size: 16px;
    color: #fff;
    font-weight: 600;}
.chargeForm ul{margin-top:80px;width:100%;height:auto;overflow:hidden;}
.chargeForm ul li{padding:20px 0;width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.chargeForm ul li .cols{display:flex;justify-content:flex-start;align-items:center;}
.chargeForm ul li:first-child{border-bottom:1px solid rgba(255,255,255,.5);}
.chargeForm ul li:last-child{padding-top:60px;border-top:1px solid rgba(255,255,255,.5);}
.chargeForm ul li .label{width:178px;height:34px;border:1px solid #40EFC1;display:flex;justify-content:center;align-items:center;font-size:16px;color:#40EFC1;font-weight:600;margin-right:20px;}
.chargeForm ul li .inp01{width:300px;height:36px;border:0;background:#fff;text-align:center;font-size:16px;color:rgba(20, 27, 45, 1);}
.chargeForm ul li .inp01.w01{width:120px;}
.chargeForm ul li .inp01.w02{width:170px;margin:0 20px;}
.chargeForm ul li button{border:1px solid #40EFC1;border-radius:5px;width:90px;height:24px;background:transparent;padding:0;display:flex;justify-content:center;align-items:center;font-size:13px;color:#40EFC1;font-weight:600;}
.chargeForm ul li .e01{display:flex;justify-content:center;align-items:center;width:45px;height:28px;font-size:16px;color:#fff;font-weight:600;}
.chargeForm ul li label{display:flex;justify-content:flex-start;align-items:center;}
.chargeForm ul li label input{display:flex;}
.chargeForm ul li label input[type=checkbox]{outline:none;background:url(../../../../assets/image/bg/bg-chk-off01.svg) no-repeat;background-size:24px 24px;border:0;width:24px;height:24px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;}
.chargeForm ul li label input[type=checkbox]:checked{width:24px;height:24px;background:url(../../../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}
.chargeForm ul li label input[type=checkbox]::-ms-check{border:0;outline:none;appearance: none;-moz-appearance: none;-webkit-appearance: none;width:24px;height:24px;content:"";background:url(../../../../assets/image/bg/bg-chk-off.svg) no-repeat;background-size:24px 24px;}
.chargeForm ul li label input[type=checkbox]:checked::-ms-check{color:transparent;background:url(../../../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}

.chargeForm ul li label span{display:flex;margin-left:10px;font-size:16px;color:#fff;font-weight:600;}

/* 2024-01-31 추가된 부분 */
.spaceView .formList ul li .form{width:100%;height:auto;overflow:hidden;margin-top:10px;display:flex;justify-content:flex-start;align-items:center;}

.spaceView .formList ul li .form .select{width:170px;height:36px;border:1px solid #fff;background: rgba(31, 42, 64, 0.00) url(../../../../assets/image/ico/ico-select01-arr.svg) no-repeat right center;font-size:16px;color:#fff;font-weight:600;padding-left:10px;}
.spaceView .formList ul li .form .select + .select{margin-left:10px;}
.spaceView .formList ul li .form .select + .inp{margin-left:10px;}
.spaceView .formList ul li .form .select.type01{background: #fff url(../../../../assets/image/ico/ico-select01-arr-black.svg) no-repeat right center;font-size:16px;color:#141B2D;font-weight:600;}
.spaceView .formList ul li .form .inp{height:34px;width:100%;flex:1;padding-left:10px;border:1px solid #fff;background: #fff;font-size:16px;color:#141B2D;font-weight:600;}

.spaceView .formList ul li .form .inpWrap{width:250px;position:relative;}
.spaceView .formList ul li .form .inpWrap .select{width:100%;background: #fff url(../../../../assets/image/ico/ico-select01-arr-black.svg) no-repeat right center;font-size:16px;color:#141B2D;font-weight:600;}
.spaceView .formList ul li .form .inpWrap.type01{width:100px;}
.spaceView .formList ul li .form .inpWrap + .inpWrap{margin-left:20px;}
.spaceView .formList ul li .form .inpWrap .inp{}
.spaceView .formList ul li .form .inpWrap .unit{font-size:16px;color:rgba(20, 27, 45, 0.62);font-weight:600;height:35px;position:absolute;right:8px;top:0;display:flex;justify-content:flex-start;align-items:center;}

@media screen and (max-width: 1500px) {

    .wrapper .subTitle{width:calc( 100% - 100px );margin:130px auto 0 auto;}
    .wrapper .scrolls{width:calc( 100% - 100px );margin:20px auto 0 auto;overflow-x:auto;}
    .wrapper .scrolls table{min-width:1200px;}

    .reqView{width:calc( 100% - 100px );}
    .reqView .reqInfo ul li{margin-left:30px;}

    .spacedHeader{width:calc( 100% - 100px );}
    .spaceWrap{width:calc( 100% - 100px );}

    .spaceView{width:calc( 100% - 100px );}
    .spaceView .optList{width:100%;height:auto;display:flex;justify-content:flex-start;align-items:center;}

    .spaceView .optList ul{justify-content:space-between;gap:10px;}
    .spaceView .optList ul li{width:100%;flex:1;display:flex;justify-content:flex-start;align-items:flex-start;}


    .selectItemWrap{position:relative;margin-right:0px;width:100%;}
    .selectItemWrap>a{text-decoration:none !important;width:calc( 100% - 22px );padding-left:20px;color:#40EFC1;font-weight:600;font-size:20px;height:46px;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;border:1px solid #40EFC1;position:relative;}
    .selectItemWrap>a:before{content:"";width:32px;height:11px;overflow:hidden;display:block;background:url(../../../../assets/image/ico/ico-select-arr.svg) no-repeat right center;position:absolute;right:10px;top:50%;margin-top:-6px;transform:rotate(180deg)}
    .selectItemWrap .selectResultWrap{position:absolute;left:0;top:46px;background:#141b2d;display:none;
    }
    .selectItemWrap>a.on:before{transform:rotate(0deg)}
    .selectItemWrap>a.on + .selectResultWrap{display:block;}
    .selectItemWrap .selectResultWrap ul{display:block;width:100%;height:auto;overflow:hidden;}
    .selectItemWrap .selectResultWrap ul li{width:100%;height:auto;overflow:hidden;}
    .selectItemWrap .selectResultWrap ul li + li{border-top:1px solid #40EFC1;}
    .selectItemWrap .selectResultWrap ul li a{width:100%;height:45px;overflow:hidden;font-size:20px;color:#40EFC1;display:flex;justify-content:center;margin:0;align-items:center;}
    .selectItemWrap .selectResultWrap ul li a:hover{color:#141B2D;background:#40EFC1;}

    .spaceView .formList{margin-top:40px;}
    .spaceView .formList ul li:nth-child(1){width:100%;flex:1;}
    .spaceView .formList ul li:nth-child(2){width:100%;flex:1;}
    .spaceView .formList ul li:nth-child(3){width:100%;flex:1;}

}

@media screen and (max-width: 1024px) {
    .listOptForm{width:calc( 100% - 40px );display:block;margin-top:20px;}
    .listOptForm .formItem .select{width:100%;flex:1;max-width:120px;font-size:13px;}
    .listOptForm .formItem .select + .select{margin-left:10px;}
    .listOptForm .formItem .select.w01{max-width:90px;}
    .listOptForm .formItem .e01{display:none;width:20px;font-size:13px;}
    .listOptForm .formItem + .formItem{margin-top:10px;}
    .spacedHeader{overflow:visible;}
    .spacedHeader .pageTitle{overflow:visible;}
    .spacedHeader .pageTitle .sideBtn{top:-50px;}
    .pinTh{display:none;}
    .pinTd{margin:40px auto;}
    .pinTd li{display:block;height:auto;padding:15px 0;}
    .pinTd li .cell01{float:left;margin:0;width:80px;}
    .pinTd li .cell02{float:left;margin:0;width:80px;}
    .pinTd li .cell03{float:left;margin:0;width:80px;}
    .pinTd li .cell04{float:right;margin:0;width:80px;}
    .pinTd li .cell05{float:left;width:calc( 100% - 0px );margin-top:10px;}
    .pinTd li .inp{font-size:14px;}
    .photoList{margin-top:40px;}
    .reqView{width:calc( 100% - 40px );}
    .spaceView{width:calc( 100% - 40px );padding-top:10px;margin-top:20px;}
    .wrapper{width:calc( 100% - 0px );padding-left:0px;padding-bottom:0;}
    .wrapper .subTitle{width:calc( 100% - 40px );}
    .wrapper .subTitle span{font-size:20px;}
    .wrapper .scrolls{width:calc( 100% - 40px );overflow-x:auto !important;}
    .spacedHeader{margin-top:100px;width:calc( 100% - 40px );}
    .spaceWrap{width:calc( 100% - 40px );margin-top:0;}

    .spaceWrap .sitem .sbox dl.type01 dd ul{text-align:center;}
    .spaceWrap .sitem .sbox dl.type01 dd ul li{margin:20px 5px 0 5px;float:none;display:inline-flex;justify-content:center;align-items:center;}

    .spaceView .optList>ul{display:block;width:calc( 100% + 20px );}
    .spaceView .optList>ul>li{width:calc( 50% - 20px );margin:10px;float:left;}


    .selectItemWrap{position:relative;margin-right:0px;width:100%;}
    .selectItemWrap>a{text-decoration:none !important;width:calc( 100% - 22px );padding-left:20px;font-weight:600;font-size:15px;height:46px;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;position:relative;color:#141B2D;border-color:#141B2D;}
    .selectItemWrap>a:before{content:"";width:32px;height:11px;overflow:hidden;display:block;background:url(../../../../assets/image/ico/ico-select-arr-black.svg) no-repeat right center;position:absolute;right:10px;top:50%;margin-top:-6px;transform:rotate(180deg)}
    .selectItemWrap .selectResultWrap{position:absolute;left:0;top:46px;background:#141b2d;display:none;z-index:10;
    }

    .spaceView .optList .inpWrap .inp{width:calc( 100% - 22px );padding-left:20px;font-size:15px;height:46px;}
    .spaceView .optList .inpWrap .unit{font-size:15px;}

    .selectItemWrap>a.on:before{transform:rotate(0deg)}
    .selectItemWrap>a.on + .selectResultWrap{display:block;}
    .selectItemWrap .selectResultWrap ul{display:block;width:100%;height:auto;overflow:hidden;}
    .selectItemWrap .selectResultWrap ul li{width:100%;height:auto;overflow:hidden;}
    .selectItemWrap .selectResultWrap ul li + li{border-top:1px solid #141B2D;}
    .selectItemWrap .selectResultWrap ul li a{width:100%;height:45px;overflow:hidden;font-size:15px;color:#141B2D;display:flex;justify-content:center;margin:0;align-items:center;}
    .selectItemWrap .selectResultWrap ul li a:hover{color:#141B2D;background:#40EFC1;}

    .spaceView .formList{margin-top:40px;}
    .spaceView .formList ul{display:block;}
    .spaceView .formList ul li{}
    .spaceView .formList ul li + li{margin-top:20px;}
    .spaceView .formList ul li .label{width:100px;display:flex;font-size:15px;color:#fff;font-weight:600;padding-right:0px;}
    .spaceView .formList ul li + li .label{margin-left:0px;}
    .spaceView .formList ul li .formItem{width:100%;flex:1;display:flex;justify-content:flex-start;align-items:center;}
    .spaceView .formList ul li .formItem .inp{width:100%;flex:1;height:46px;border:0;font-size:15px;color:#141B2D;padding:0 10px;border-radius:8px;}

    /* 2024-01-31 추가된 부분 */
    .spaceView .formList ul li .formItem .inpWrap{width:250px;position:relative;}
    .spaceView .formList ul li .formItem .inpWrap .select{width:100%;background: #fff url(../../../../assets/image/ico/ico-select01-arr-black.svg) no-repeat right center;font-size:16px;color:#141B2D;font-weight:600;}
    .spaceView .formList ul li .formItem .inpWrap.type01{width:100px;}
    .spaceView .formList ul li .formItem .inpWrap + .inpWrap{margin-left:20px;}
    .spaceView .formList ul li .formItem .inpWrap .inp{}
    .spaceView .formList ul li .formItem .inpWrap .unit{font-size:16px;color:rgba(20, 27, 45, 0.62);font-weight:600;height:35px;position:absolute;right:0;top:0;display:flex;justify-content:flex-start;align-items:center;}


    .spaceView dl{width:100%;height:auto;overflow:hidden;padding-bottom:80px;}
    .spaceView dl dt{margin-top:60px;width:100%;height:auto;display:flex;justify-content:flex-start;align-items:center;}
    .spaceView dl dt img{display:flex;width:26px;}
    .spaceView dl dt span{display:flex;margin-left:10px;font-size:20px;color:#40EFC1;font-weight:700;}
    .spaceView dl dd{width:100%;height:auto;overflow:hidden;}
    .spaceView dl dd .formItem{margin-top:20px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-end;align-items:flex-start;}
    .spaceView dl dd .formItem .textarea{margin-top:20px;width:calc( 100% - 60px );font-size:22px;color:#141B2D;line-height:1.5em;height:130px;padding:30px;border-radius:8px;overflow:hidden;background:#DBDBDB;resize:none;border:0;}
    .spaceView dl dd .formItem button{display:flex;justify-content:flex-start;align-items:center;border:0;padding:0;background:transparent;}
    .spaceView dl dd .formItem button span{display:flex;font-size:15px;color:#fff;font-weight:600;}
    .spaceView dl dd .formItem button img{display:flex;margin-left:10px;}
    .spaceView dl dd ul{margin-top:20px;width:100%;height:auto;overflow:hidden;}
    .spaceView dl dd ul li{display:block;}
    .spaceView dl dd ul li + li{margin-top:20px;}
    .spaceView dl dd ul li .sdate{display:flex;justify-content:flex-start;align-items:center;width:152px;font-size:15px;color:#fff;font-weight:600;}
    .spaceView dl dd ul li .stit{margin-top:10px;display:flex;justify-content:flex-start;align-items:center;width:calc( 100% - 40px );padding:0 20px;flex:1;height:50px;border-radius:8px;font-size:15px;color:#141B2D;font-weight:600;background:#fff;}
}


.wrapper .scrolls{width:1300px;height:auto;overflow:Hidden;margin:0px auto 0 auto;}
.wrapper .scrolls table{width:100%;table-layout:fixed;border:0;border-collapse:collapse;}
.wrapper .scrolls table th{text-align:center;font-size:14px;color:#fff;font-weight:500;height:37px;border-left:1px solid #fff;border-top:1px solid #fff;background:#2D459D}
.wrapper .scrolls table thead tr:nth-child(1) th{border-top:0;}
.wrapper .scrolls table td{border-left:1px solid #fff;border-bottom:1px solid #fff;border-top:1px solid #fff;text-align:center;font-size:16px;color:#fff;font-weight:500;height:60px;background:#1F2A40;}
.wrapper .scrolls table .bol{border-left:1px solid #fff !important;}
.wrapper .scrolls table .al{text-align:left;}

.wrapper .scrolls.type01{margin-top:40px;}
.wrapper .scrolls.type01 table th{border-top:0;text-align:center;padding-left:0px;height:53px;}
.wrapper .scrolls.type01 table th input{margin:0 auto;float:none;}
.wrapper .scrolls.type01 table td{text-align:center;padding-left:0px;}
.wrapper .scrolls.type01 table td input{margin:0 auto;float:none;}

.wrapper .scrolls table input[type=checkbox]{outline:none;float:left;background:url(../../../../assets/image/bg/bg-chk-off01.svg) no-repeat;background-size:24px 24px;border:0;width:24px;height:24px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;}
.wrapper .scrolls table input[type=checkbox]:checked{width:24px;height:24px;background:url(../../../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}
.wrapper .scrolls table input[type=checkbox]::-ms-check{border:0;outline:none;appearance: none;-moz-appearance: none;-webkit-appearance: none;width:24px;height:24px;content:"";background:url(../../../../assets/image/bg/bg-chk-off.svg) no-repeat;background-size:24px 24px;}
.wrapper .scrolls table input[type=checkbox]:checked::-ms-check{color:transparent;background:url(../../../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}

.wrapper .scrolls table td a{display:flex;justify-content:flex-start;align-items:center;}
.wrapper .scrolls table td a img{display:flex;}
.wrapper .scrolls table td a span{display:flex;margin-left:10px;font-size:14px;color:#40EFC1;font-weight:700;}
.wrapper .scrolls table tr>th:first-child{border-left:0;}
.wrapper .scrolls table tr:nth-child(1)>th{border-top:0;}
.wrapper .scrolls table tr>td:first-child{border-left:0;}

.paging{max-width:1300px;width:100%;height:auto;overflow:Hidden;display:flex;justify-content:center;align-items:center;margin:50px auto 50px auto;}
.paging button{border:0;background:transparent;width:24px;height:24px;overflow:Hidden;padding:0;display:flex;justify-content:center;align-items:center;}
.paging ul{display:flex;justify-content:center;align-items:center;margin:0 20px;}
.paging ul li{display:flex;justify-content:center;align-items:center;}
.paging ul li a{font-size:15px;color:rgba(255,255,255,.7);display:flex;justify-content:center;align-items:center;width:24px;height:24px;}
.paging ul li a.on{color:rgba(255,255,255,1);font-weight:500;}

.spaceList{}
.spaceList .optWrap{max-width:1300px;width:100%;height:auto;display:flex;justify-content:space-between;align-items:center;margin:24px auto 31px auto;}
.spaceList .optWrap .filter{display:flex;justify-content:flex-start;align-items:center;}
.spaceList .optWrap .select{height:35px;width:130px;border:1px solid #fff;padding-left:20px;font-size:16px;color:#fff;font-weight:600;background:url(../../../../assets/image/bg/bg-select01-white.svg) no-repeat right center;background-size: 24px 12px}
.filterItemmWrap{margin-right:20px;display:flex;justify-content:flex-start;align-items:center;position:relative;}
.filterItemmWrap>a{display:flex;justify-content:flex-start;align-items:center;}
.filterItemmWrap>a img{display:flex;}
.filterItemmWrap>a span{display:flex;margin-left:10px;font-size:16px;color:#DBDBDB;font-weight:500;}


.filterResultWrap{display:none;position:absolute;left:0;top:40px;width:244px;height:auto;overflow:hidden;background:#2F2F2F;}
.filterItemmWrap>a.on + .filterResultWrap{display:block;}
.filterResultWrap .formItem{width:calc( 100% - 34px );height:auto;overflow:hidden;margin:10px auto 0 auto;}
.filterResultWrap .formItem .inp{background:transparent;height:40px;border:0;border-bottom:1px solid #fff;width:100%;font-size:16px;color:#fff;}
.filterResultWrap ul{width:calc( 100% - 34px );height:auto;overflow:hidden;margin:15px auto;}
.filterResultWrap ul li{width:100%;height:auto;}
.filterResultWrap ul li label{height:37px;display:flex;justify-content:flex-start;align-items:center;}
.filterResultWrap ul li label input{position:relative;display:flex;outline:none;float:left;background:#DBDBDB;border:0;width:38px;height:12px;overflow:visible;appearance: none;-moz-appearance: none;-webkit-appearance: none;border-radius:18px;transition:all .5s;}
.filterResultWrap ul li label input:before{content:"";transition:all .5s;width:20px;height:20px;overflow:Hidden;border-radius:50%;display:block;background:#fff;position:absolute;left:0;top:50%;margin-top:-10px;}
.filterResultWrap ul li label input:checked{background:#21252E;width:38px;height:12px;}
.filterResultWrap ul li label input:checked:before{background:#141B2D;left:18px;}
.filterResultWrap ul li label span{display:flex;margin-left:20px;font-size:14px;color:#fff;font-weight:500;}
.filterResultWrap ul li a{height:30px;display:flex;justify-content:flex-start;align-items:center;}
.filterResultWrap ul li a img{display:flex;margin-right:10px;}
.filterResultWrap ul li a span{display:flex;font-size:16px;color:#fff;font-weight:500;}
.filterResultWrap .toggle{width:calc( 100% - 34px );height:auto;overflow:hidden;margin:0 auto 15px auto;display:flex;justify-content:space-between;align-items:center;}
.filterResultWrap .toggle a{display:flex;font-size:10px;color:#6A6A6A;font-weight:500;}
.filterResultWrap .toggle a.on{color:#fff;}

.dsTitleWrap{margin:130px auto 0 auto;width:1300px;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.dsTitleWrap strong{display:flex;font-size:45px;color:#40EFC1;font-weight:800;}
.dsTitleWrap span{display:flex;margin-left:40px;font-size:28px;color:#fff;font-weight:800;}
.cntWrap{margin:40px auto 0 auto;width:1300px;height:auto;overflow:hidden;}
.cntWrap ul{width:100%;height:auto;overflow:hidden;display:flex;justify-content:space-between;align-items:flex-start;gap:16px;}
.cntWrap ul li{width:100%;flex:1;padding:0 30px;height:168px;overflow:Hidden;background:#1F2A40;position:relative;}
.cntWrap ul li .ico{margin-top:20px;width:100%;overflow:hidden;height:50px;display:flex;justify-content:flex-start;align-items:center;}
.cntWrap ul li .ico img{filter: brightness(0) saturate(100%) invert(99%) sepia(51%) saturate(2140%) hue-rotate(83deg) brightness(96%) contrast(95%);}
.cntWrap ul li .key{margin-top:10px;width:100%;height:auto;overflow:hidden;font-size:18px;color:#40EFC1;font-weight:700;}
.cntWrap ul li .value{margin-top:5px;width:100%;height:auto;overflow:hidden;font-size:16px;color:#fff;font-weight:700;}
.cntWrap ul li .chart{width:60px;height:60px;background:#ddd;position:absolute;right:30px;top:30px;}

.cntWrap.type01{margin-top:50px;margin-bottom:100px;}
.cntWrap.type01 ul{display:block;width:calc( 100% + 22px );margin-left:-11px;}
.cntWrap.type01 ul li{width:calc( 100% / 3 - 22px - 60px );height:226px;float:left;margin:52px 11px 0 11px;}
.cntWrap.type01 ul li .key{margin-top:5px;}
.cntWrap.type01 ul li .value{margin-top:20px;}

.staticsWrap{margin:30px auto 0 auto;width:1300px;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.staticsWrap .chartWrap{font-size:18px;color:#fff;font-weight:500;width:100%;flex:1;height:414px;overflow:Hidden;background:#1F2A40;display:flex;justify-content:center;align-items:center;text-align:center;}
.staticsWrap .listWrap{width:313px;height:414px;overflow:hidden;margin-left:16px;background:#1F2A40;}
.staticsWrap .listWrap ul{width:calc( 100% - 60px );height:auto;overflow:Hidden;margin:30px auto 0 auto;}
.staticsWrap .listWrap ul li{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.staticsWrap .listWrap ul li + li{margin-top:8px;}
.staticsWrap .listWrap ul li a{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.staticsWrap .listWrap ul li .cate{width:80px;height:auto;overflow:Hidden;font-size:18px;color:#40EFC1;font-weight:500;}
.staticsWrap .listWrap ul li .name{width:100%;height:auto;overflow:hidden;text-overflow:ellipsis; white-space:nowrap;word-wrap:normal;flex:1;margin-left:16px;font-size:18px;color:#40EFC1;font-weight:500;}
.staticsWrap .listWrap ul li .age{margin-left:16px;font-size:18px;color:#40EFC1;font-weight:500;}

.latestWrap{margin:100px auto 0 auto;width:1300px;height:auto;overflow:hidden;}
.latestWrap .headerWrap{padding-bottom:15px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:space-between;align-items:center;}
.latestWrap .headerWrap .lt{display:flex;justify-content:flex-start;align-items:center;}
.latestWrap .headerWrap .lt img{display:flex;width:30px;}
.latestWrap .headerWrap .lt strong{display:flex;margin-left:8px;font-size:18px;color:#40EFC1;font-weight:700;}
.latestWrap .headerWrap .lt ul{display:flex;justify-content:flex-start;align-items:center;}
.latestWrap .headerWrap .lt ul li{margin-left:20px;display:flex;justify-content:flex-start;align-items:center;color:#fff;font-size:19px;font-weight:500;}
.latestWrap .headerWrap .lt ul li span{display:flex;margin-left:5px;font-size:19px;color:#40EFC1;font-weight:500;}
.latestWrap .headerWrap .rt{display:flex;justify-content:flex-start;align-items:center;}
.latestWrap .headerWrap .rt a{display:flex;justify-content:flex-start;align-items:center;}
.latestWrap .headerWrap .rt a span{display:flex;font-size:9px;color:#fff;font-weight:300;}
.latestWrap .headerWrap .rt a img{display:flex;margin-left:6px;}
.latestWrap .contWrap{width:750px;height:auto;overflow:hidden;margin:20px auto 0 auto;}


.popup{width:calc( 100% - 40px - 50px );max-width:687px;height:auto;padding:48px 25px;overflow:hidden;background:#ededed;position:fixed;left:50%;top:100px;transform: translateX(-50%);z-index:99999;display:none;}
.popup.on{display:block;}
.popup .popClose{position:absolute;right:30px;top:30px;border:0;padding:0;background:transparent;}
.popup .searchForm{margin-top:60px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.popup .searchForm .inp{width:100%;height:40px;overflow:hidden;background:#fff url(../../../../assets/image/ico/ico-search01.svg) no-repeat right center;flex:1;padding:0 10px;border-radius:3px;border:1px solid #1F2A40;font-size:13px;color:#141B2D;font-weight:600;}
.popup .searchForm button{margin-left:10px;width:120px;border-radius:3px;border:0;background:#40EFC1;padding:0;font-size:15px;color:#141B2D;font-weight:600;height:42px;overflow:hidden;display:flex;justify-content:center;align-items:center;}
.popup .tblWrap{margin-top:35px;width:100%;height:auto;overflow:hidden;}
.popup .tblWrap table{width:100%;table-layout:fixed;border:0;border-collapse:collapse;}
.popup .tblWrap table th{height:36px;text-align:center;font-weight:600;font-size:11px;color:#141B2D;}
.popup .tblWrap table td{height:32px;text-align:center;font-size:14px;color:#141B2D;font-weight:600;border-top:1px solid #141B2D;}
.cntWrap ul li .ico img{filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(108deg) brightness(104%) contrast(104%);}
.paymentHistory{width:100%;height:auto;overflow:hidden;}
.paymentHistory .phTit{width:100%;height:auto;overflow:hidden;font-size:14px;color:#141B2D;font-weight:600;line-height:31px;}
.paymentHistory .phScrolls{width:100%;height:auto;max-height:300px;overflow:hidden;overflow-y:auto;border-top:1px solid #141B2D;border-bottom:1px solid #141B2D;}
.paymentHistory table{width:100%;table-layout:fixed;border:0;border-collapse:collapse;margin:-1px 0 0 -1px;}
.paymentHistory table th{padding:5px;text-align:center;font-size:12px;color:#141B2D;font-weight:600;border-top:1px solid #141B2D;border-left:1px solid #141B2D;}
.paymentHistory table td{padding:5px;text-align:center;font-size:12px;color:#141B2D;font-weight:600;border-top:1px solid #141B2D;border-left:1px solid #141B2D;}
@media screen and (max-width: 1500px) {
    .wrapper .scrolls{width:calc( 100% - 100px );margin:20px auto 0 auto;overflow-x:auto;}
    .wrapper .scrolls table{min-width:1200px;}

    .dsTitleWrap{width:calc( 100% - 100px );margin:130px auto 0 auto;}
    .cntWrap{width:calc( 100% - 100px );margin:20px auto 0 auto;}
    .staticsWrap{width:calc( 100% - 100px );margin:20px auto 0 auto;}
    .latestWrap{width:calc( 100% - 100px );margin:20px auto 0 auto;}
    .spaceWrap{display:block;}
    .spaceWrap .sitem{float:left;width:calc( 25% - 20px );margin:0 10px;}
    .spaceWrap.type01 .sitem{width:calc( 100% / 3 - 20px );}
    .spaceList .optWrap{width:calc( 100% - 100px );}
    .staticsWrap .listWrap{width:100%;height:auto;overflow:hidden;padding-bottom:20px;border-top:1px solid rgba(255,255,255,.9);margin-left:0;margin-top:10px;background:transparent;}
}

@media screen and (max-width: 1024px) {
    .popup .searchForm{margin-top:20px;}
    .popup .tblWrap{margin-top:20px;}
    .popup .searchForm button{width:80px;}
    .spaceList .optWrap{width:calc( 100% - 40px );}
    .wrapper .subTitle span{font-size:20px;}
    .spacedHeader .pageTitle{width:calc( 100% - 0px );justify-content: flex-start;}
    .spacedHeader .pageTitle.type01{margin-top:10px;}
    .spacedHeader .pageTitle.type02{margin-top:10px;padding-bottom:20px;}
    .spacedHeader .pageTitle .select{left:auto;right:0;width:130px;}
    .wrapper .scrolls{width:calc( 100% - 0px );margin-bottom:40px;margin-top:0;overflow-x:auto !important;}
    .dsTitleWrap{width:calc( 100% - 40px );margin:130px auto 0 auto;display:block;}
    .dsTitleWrap strong{font-size:26px;}
    .dsTitleWrap span{margin-left:0;font-size:14.3px;margin-top:10px;}
    .cntWrap{width:calc( 100% - 40px );margin:20px auto 0 auto;}
    .cntWrap ul{display:block}
    .cntWrap ul li{float:left;margin-bottom:20px;width:calc( 50% - 10px - 40px  );padding:0 20px;height:106px;}
    .cntWrap ul li:nth-child(even){float:right;}

    .cntWrap.type01{margin-bottom:40px;}
    .cntWrap.type01 ul{width:100%;margin-left:0;}
    .cntWrap.type01 ul li{margin:0;height:auto;float:left;margin-bottom:20px;width:calc( 50% - 10px - 40px  );padding:0 20px 20px 20px;}
    .cntWrap.type01 ul li:nth-child(even){float:right;}
    .filterItemmWrap>a span{display:none}

    .cntWrap ul li .ico{}
    .cntWrap ul li .ico img{width:30px;}
    .cntWrap ul li .key{font-size:9px;}
    .cntWrap ul li .value{font-size:9px;}
    .staticsWrap{display:block;width:calc( 100% - 40px);margin:0px auto 0 auto;}
    .latestWrap{width:calc( 100% - 40px );margin:20px auto 0 auto;}
    .spaceWrap .sitem{float:left;width:calc( 100% - 0px );margin:40px 0 0 0;}
    .spaceWrap.type01 .sitem{width:calc( 100% - 0px )}

    .spacedHeader .pageLoacation .cate{font-size:20px;}
    .spacedHeader .pageLoacation ul li a{font-size:16px;}
    .spacedHeader .pageTitle span{font-size:26px;}
    .spacedHeader .pageTitle strong{font-size:24px;}

    .dialog .popCont{width:calc( 100% - 80px );padding:20px;}
    .dialog .popCont .ptit strong{font-size:20px;}
    .dialog .popCont .optWrap{margin-top:20px;}
    .dialog .popCont .optWrap .select{height:34px;width:120px;font-size:15px;padding-left:10px;}

    .dialog .popCont .optWrap{display:block;}
    .dialog .popCont .optWrap .dateWrap{width:100%;display:flex;justify-content:space-between;align-items:center;gap:10px;}
    .dialog .popCont .optWrap .e01{display:block;width:100%;height:5px;overflow:hidden;}
    .dialog .popCont .optWrap .select{width:100%;flex:1;}
    .dialog .popCont .optWrap .select + .select{margin-left:0px;}


    .dialog .popCont .textareaWrap .cnt{width:100%;height:30px;overflow:hidden;display:flex;justify-content:flex-end;align-items:center;font-size:12px;color:#141B2D;}
    .dialog .popCont .textareaWrap .textarea{width:calc( 100% - 40px );padding:20px;overflow:hidden;resize:none;border:0;height:154px;font-size:15px;color:#141B2D;background:#DBDBDB;}
    .dialog .popCont .btnGroup{padding-top:20px;}
    .dialog .popCont .btnGroup button{width:160px;height:40px;border:0;padding:0;font-size:15px;}
    .boardSearch .searchForm{width:300px;}

    .spaceList .optWrap .filter{position:relative;z-index:30;}
    .filterItemmWrap{position:static;}

    .boardSearch{flex-direction: column}
    .boardSearch .btnReq{margin:20px auto 80px auto;}


    .reqView .reqHeader{width:calc( 100% - 0px );padding:20px 0;height:auto;overflow:Hidden;display:block;}
    .reqView .reqHeader .mtit{font-size:20px;}
    .reqView .reqHeader .mdate{display:flex;font-size:15px;color:#fff;font-weight:500;margin-top:10px;}
    .reqView .reqInfo{width:100%;height:auto;padding:20px 0;overflow:hidden;}
    .reqView .reqInfo ul{display:block;}
    .reqView .reqInfo ul li{display:flex;margin-left:0px;font-size:15px;}
    .reqView .reqInfo ul li + li{margin-top:10px;}

    .reqView .reqCont{width:calc( 100% - 0px );padding:20px 0px;}
    .reqView .reqCont .mcont{width:calc( 100% - 40px );height:auto;overflow:hidden;border-radius:8px;
        padding:20px;
    }
    .reqView .reqCont .mcont .mdesc{font-size:15px;}
    .reqView .reqCont .mcont .mradio{margin-top:20px;}
    .reqView .reqCont .mcont .mpic{width:100%;height:auto;overflow:hidden;margin-top:20px;}
    .reqView .reqCont .mcont .mpic img{max-width:100%;}
    .reqView .reqCont .mcont .mpic ul{width:100%;height:auto;overflow:hidden;}
    .reqView .reqCont .mcont .mpic ul li{width:100%;float:left;margin-top:10px;}
    .reqView .reqCont .mcont .mpic ul li .mpic{height:50vw;}

    .reqView .reqCont .minfo{margin-top:40px;}
    .reqView .reqCont .minfo ul{display:block;}
    .reqView .reqCont .minfo ul li{width:100%;display:flex;justify-content:flex-start;align-items:center;float:left;}
    .reqView .reqCont .minfo ul li + li{margin-left:0px;margin-top:10px;}
    .reqView .reqCont .minfo ul li:nth-child(1){width:100%;}
    .reqView .reqCont .minfo ul li:nth-child(2){width:100%;}
    .reqView .reqCont .minfo ul li:nth-child(3){width:100%;}
    .reqView .reqCont .minfo ul li .key{width:100px;display:flex;font-size:15px;color:#DBDBDB;font-weight:700;}
    .reqView .reqCont .minfo ul li .value{width:100%;flex:1;display:flex;margin-left:10px;font-size:20px;color:#40EFC1;font-weight:700;}
    .reqView .reqCont .minfo ul li .value .select{box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.04);width:100%;flex:1;height:40px;background-color:#fff;border:0;padding-left:20px;font-size:16px;color:#141B2D;font-weight:600;border-radius:8px;}
    .reqView .reqCont .minfo ul li .value .inp{border-radius:8px;height:40px;border:0;background:#fff;box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.04);padding:0 20px;width:calc( 100% - 40px );font-size:16px;color:#141B2D;font-weight:600;}

    .reqView .reqCont .reqBar{width:100%;height:auto;overflow:hidden;margin-top:40px;}
    .reqView .reqCont .reqBar .rtit{display:flex;justify-content:flex-start;align-items:center;}
    .reqView .reqCont .reqBar .rtit img{display:flex;}
    .reqView .reqCont .reqBar .rtit span{display:flex;margin-left:10px;font-size:20px;color:#40EFC1;font-weight:700;}
    .reqView .reqCont .reqBar button{display:flex;justify-content:center;align-items:center;background: #FFF;
        box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.04);width:100px;height:34px;overflow:hidden;padding:0;font-size:16px;color:#141B2D;font-weight:600;}
    .reqView .reqCont .reqBar .radio{display:flex;justify-content:flex-start;align-items:center;}
    .reqView .reqCont .reqBar .radio label{display:flex;justify-content:flex-start;align-items:center;margin-left:10px;}
    .reqView .reqCont .reqBar .radio label input{display:flex;}
    .reqView .reqCont .reqBar .radio label span{display:flex;margin-left:10px;font-size:15px;color:#fff;font-weight:800;}

    .reqView .reqCont .overview{resize:none;width:calc( 100% - 40px );height:auto;min-height:130px;margin-top:20px;overflow:hidden;padding:20px;border-radius:8px;font-size:15px;
    }
    .photoUpload{background: #FFF url(../../../../assets/image/bg/bg-upload.png) no-repeat center center;background-size:30%;width:100%;height:200px;overflow:hidden;border-radius:8px;
        box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.04);}
    .photoUpload label{display:flex;width:100%;height:200px;}
    .photoUpload label input{position:absolute;left:-2000000px;}

    .reqView .reqCont .photoHeader{width:100%;height:auto;overflow:hidden;margin-top:40px;display:flex;justify-content:flex-end;align-items:center;}
    .reqView .reqCont .photoHeader button{display:flex;justify-content:flex-start;align-items:center;}
    .reqView .reqCont .photoHeader button span{display:flex;font-size:15px;color:#fff;font-weight:600;}
    .reqView .reqCont .photoHeader button img{display:flex;margin-left:10px;}
    .reqView .reqCont .photoHeader.sw{justify-content:space-between;}
    .reqView .reqCont .photoHeader .select{height:39px;border:1px solid #141B2D;display:flex;margin-right:20px;width:150px;font-size:18px;background-color:#fff;color:#141B2D;padding-left:20px;}

    .reqView .reqCont .photoList{width:100%;height:auto;overflow:hidden;margin-top:20px;}
    .reqView .reqCont .photoList ul{width:calc( 100% + 20px );height:auto;overflow:hidden;margin-left:-10px;}
    .reqView .reqCont .photoList ul li{float:left;width:calc( 50% - 20px );margin:10px;height:auto;overflow:hidden;}
    .reqView .reqCont .photoList li .mpic{width:100%;height:40vw;overflow:hidden;background-size:cover !important;}

    .reqView .reqCont .mbtn{width:100%;height:auto;overflow:hidden;margin-top:40px;margin-bottom:80px;display:flex;justify-content:center;align-items:center;}
    .reqView .reqCont .mbtn button{display:flex;justify-content:center;align-items:center;width:200px;height:40px;overflow:hidden;border-radius:10px;border:0;background:#40EFC1;padding:0;font-size:15px;color:#141B2D;font-weight:700;}

    .spaceView .mbtn{width:100%;height:auto;overflow:hidden;margin-top:0px;margin-bottom:40px;display:flex;justify-content:center;align-items:center;}
    .spaceView .mbtn button{display:flex;justify-content:center;align-items:center;width:200px;height:40px;overflow:hidden;border-radius:10px;border:0;background:#40EFC1;padding:0;font-size:15px;color:#141B2D;font-weight:700;}

    .cntWrap ul li .chart{width:50px;height:50px;background:#ddd;position:absolute;right:20px;top:20px;}

    .latestWrap .headerWrap .lt img{display:flex;width:30px;}
    .latestWrap .headerWrap .lt strong{display:flex;margin-left:8px;font-size:10px;color:#40EFC1;font-weight:700;}
    .latestWrap .headerWrap{padding-bottom:15px;margin-top:40px;}
}

.wrapper{width:calc( 100% );padding-bottom:00px;height:auto;overflow:hidden;}
.wrapper .subTitle{width:1300px;height:auto;overflow:Hidden;margin:130px auto 0 auto;border-bottom:2px solid #40EFC1;padding-bottom:40px;display:flex;justify-content:center;align-items:center;position:relative;}
.wrapper .subTitle img{display:flex;}
.wrapper .subTitle span{display:flex;margin-left:16px;font-size:28px;color:#40EFC1;font-weight:700;}
.wrapper .subTitle .etc{position:absolute;right:0;bottom:40px;font-size:21px;color:#fff;font-weight:600;}
.wrapper .subTitle button{width:134px;height:36px;overflow:hidden;border-radius:8px;border:0;background:#40EFC1;display:flex;justify-content:center;align-items:center;font-size:16px;color:#141B2D;font-weight:700;position:absolute;right:0;top:0;}

.detailSection{width:800px;height:auto;overflow:hidden;margin:70px auto 100px auto;}
.detailSection dl{width:100%;height:auto;overflow:hidden;}
.detailSection dl + dl{margin-top:80px;}
.detailSection dl dt{width:100%;height:auto;overflow:hidden;display:flex;justify-content:space-between;align-items:center;border-bottom:1px solid rgba(219, 219, 219, .2);padding-bottom:20px;}
.detailSection dl dt.type01{justify-content:flex-start;}
.detailSection dl dt .stit{font-size:22px;color:#40EFC1;font-weight:700;}
.detailSection dl dt label{margin-left:20px;display:flex;justify-content:flex-start;align-items:center;}
.detailSection dl dt label input{display:flex;}
.detailSection dl dt label span{display:flex;margin-left:8px;font-size:14px;color:#fff;font-weight:800;}
.detailSection dl dt label input[type=checkbox]{outline:none;float:left;background:url(../../../../assets/image/bg/bg-chk-off02.svg) no-repeat;border:0;width:19px;height:19px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;background-size:19px 19px;}
.detailSection dl dt label input[type=checkbox]:checked{width:19px;height:19px;background:url(../../../../assets/image/bg/bg-chk-on02.svg) no-repeat;background-size:19px 19px;}
.detailSection dl dt label input[type=checkbox]::-ms-check{border:0;outline:none;appearance: none;-moz-appearance: none;-webkit-appearance: none;width:19px;height:19px;content:"";background:url(../../../../assets/image/bg/bg-chk-off02.svg) no-repeat;background-size:19px 19px;}
.detailSection dl dt label input[type=checkbox]:checked::-ms-check{color:transparent;background:url(../../../../assets/image/bg/bg-chk-on02.svg) no-repeat;background-size:19px 19px;}

.detailSection dl dt button{width:134px;height:36px;overflow:hidden;border-radius:8px;border:0;background:#40EFC1;display:flex;justify-content:center;align-items:center;font-size:16px;color:#141B2D;font-weight:700;}
.detailSection dl dd{margin-top:30px;width:100%;height:auto;overflow:hidden;}
.detailSection dl dd ul{width:calc( 100% - 60px );padding-left:60px;height:auto;overflow:hidden;}
.detailSection dl dd ul li{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:flex-start;}
.detailSection dl dd ul li + li{margin-top:20px;}
.detailSection dl dd ul li .key{display:flex;width:120px;font-size:18px;color:#40EFC1;font-weight:600;}
.detailSection dl dd ul li .value{display:flex;font-size:15px;color:#fff;font-weight:600;justify-content:flex-start;align-items:center;}
.detailSection dl dd ul li .value span{font-size:15px;color:#fff;font-weight:600;width:100%;flex:1;}
.detailSection dl dd ul li .value button{font-size:13px;color:#fff;font-weight:600;margin-left:40px;display:flex;justify-content:center;align-items:center;width:80px;height:30px;border:1px solid #fff;background:rgba(204, 211, 228, 0.00);border-radius:5px;box-shadow:0px 3.42932px 2.57199px 1.71466px rgba(0, 0, 0, 0.04);}

.detailSection dl dd ul li .value.full{width:100%;flex:1;}
.detailSection dl dd ul li .value .formItem{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.detailSection dl dd ul li .value .formItem .inp{border: 1.286px solid #FFF;font-size:15px;color:#141B2D;font-weight:600;
    background: #FFF;width:135px;height:32px;overflow:hidden;text-align:center;
    box-shadow: 0px 3.42932px 2.57199px 1.71466px rgba(0, 0, 0, 0.04);}
.detailSection dl dd ul li .value .formItem .inp + .inp{margin-left:10px;}
.detailSection dl dd ul li .value .formItem .inp.tel{width:100px;}
.detailSection dl dd ul li .value .formItem .inp.email{width:186px;}
.detailSection dl dd ul li .value .formItem .inp.addr{width:100%;padding:0 10px;text-align:left;flex:1;}
.detailSection dl dd ul li .value .formItem .e01{display:flex;justify-content:center;align-items:center;width:24px;height:32px;font-size:15px;color:#fff;font-weight:600;}

.detailSection .sitem{margin-top:20px;margin-bottom:20px;width:calc( 100% - 60px );padding-left:60px;height:auto;overflow:hidden;}
.detailSection .sitem span{border: 1.286px solid #fff;
    background: transparent;
    height:28px;padding:0 15px;display:inline-flex;justify-content:center;align-items:center;float:left;margin-right:8px;font-size:13px;color:#fff;font-weight:600;}
.detailSection .btnGroup{width:100%;height:auto;overflow:hidden;display:flex;justify-content:center;align-items:center;margin-top:100px;}
.detailSection .btnGroup button{width:198px;height:54px;border-radius:5px;border:0;padding:0;background:#40EFC1;overflow:hidden;display:flex;justify-content:center;align-items:center;font-size:18px;color:#141B2D;font-weight:700;}

.contractSection{width:calc( 100% - 100px );max-width:1192px;height:auto;overflow:hidden;margin:70px auto 100px auto;}
.contractSection .formRow{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:flex-start;}
.contractSection .formRow + .formRow{margin-top:40px;}
.contractSection .formRow .formCols{width:100%;height:auto;overflow:hidden;flex:1;}
.contractSection .formRow .formCols .label{width:100%;height:auto;overflow:hidden;font-size:18px;color:#40EFC1;font-weight:600;}
.contractSection .formRow .formCols .form{width:100%;height:auto;overflow:hidden;margin-top:10px;display:flex;justify-content:flex-start;align-items:center;}
.contractSection .formRow .formCols .form .btnSearch{width:198px;height:34px;border:1px solid #fff;background: rgba(31, 42, 64, 0.00);overflow:hidden;padding:0;position:relative;display:flex;justify-content:center;align-items:center;}
.contractSection .formRow .formCols .form .btnSearch span{font-size:16px;color:#fff;font-weight:600;display:flex;justify-content:center;align-items:center;width:140px;}
.contractSection .formRow .formCols .form .btnSearch img{}

.contractSection .formRow .formCols .form .select{width:170px;height:36px;border:1px solid #fff;background: rgba(31, 42, 64, 0.00) url(../../../../assets/image/ico/ico-select01-arr.svg) no-repeat right center;font-size:16px;color:#fff;font-weight:600;padding-left:10px;}
.contractSection .formRow .formCols .form .select + .select{margin-left:10px;}
.contractSection .formRow .formCols .form .select + .inp{margin-left:10px;}
.contractSection .formRow .formCols .form .select.type01{background: #fff url(../../../../assets/image/ico/ico-select01-arr-black.svg) no-repeat right center;font-size:16px;color:#141B2D;font-weight:600;}
.contractSection .formRow .formCols .form .inp{height:34px;width:100%;flex:1;padding-left:10px;border:1px solid #fff;background: #fff;font-size:16px;color:#141B2D;font-weight:600;}

.contractSection .formRow .formCols .form .inpWrap{width:250px;position:relative;}
.contractSection .formRow .formCols .form .inpWrap .select{width:100%;background: #fff url(../../../../assets/image/ico/ico-select01-arr-black.svg) no-repeat right center;font-size:16px;color:#141B2D;font-weight:600;}
.contractSection .formRow .formCols .form .inpWrap.type01{width:100px;}
.contractSection .formRow .formCols .form .inpWrap + .inpWrap{margin-left:20px;}
.contractSection .formRow .formCols .form .inpWrap .inp{}
.contractSection .formRow .formCols .form .inpWrap .unit{font-size:16px;color:rgba(20, 27, 45, 0.62);font-weight:600;height:35px;position:absolute;right:0;top:0;display:flex;justify-content:flex-start;align-items:center;}

.contractSection .formRow .formCols .form .sitem{width:100%;flex:1;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.contractSection .formRow .formCols .form .sitem + .sitem{margin-left:10px;}
.contractSection .formRow .formCols .form .sitem .e02{white-space:nowrap;font-size:16px;color:#fff;font-weight:600;padding:0 20px;}
.contractSection .formRow .formCols .form .sitem{}

.contractSection .formRow .formCols .form .func{margin-left:10px;display:flex;justify-content:flex-start;align-items:center;}
.contractSection .formRow .formCols .form .func button{width:96px;height:36px;overflow:hidden;border-radius:5px;border:0;background:#40EFC1;padding:0;display:flex;justify-content:center;align-items:center;font-size:16px;color:#141B2D;font-weight:600;}
.contractSection .formRow .formCols .form .func button + button{margin-left:10px;background:#fff;}
.contractSection .btnGroup{width:100%;height:auto;overflow:hidden;display:flex;justify-content:center;align-items:center;margin-top:100px;}
.contractSection .btnGroup button{width:200px;height:54px;border-radius:5px;border:0;padding:0;background:#40EFC1;overflow:hidden;display:flex;justify-content:center;align-items:center;font-size:18px;color:#141B2D;font-weight:700;}

.contractSection .formRow .formCols .form .filearea{width:100%;height:auto;overflow:hidden;}
.contractSection .formRow .formCols .form .filearea label{cursor:pointer;width:100%;height:272px;overflow:hidden;background:#fff;display:flex;justify-content:center;align-items:center;}
.contractSection .formRow .formCols .form .filearea label img{max-width:145px;}
.contractSection .formRow .formCols .form .filearea label input{position:absolute;left:-20000px;}
.contractSection .formRow .formCols .form .textarea{width:calc( 100% - 20px );border:0;background:#fff;height:200px;padding:10px;line-height:1.5em;font-size:16px;color:#141B2D;font-weight:600;}

@media screen and (max-width: 1500px) {
    .wrapper .subTitle{width:calc( 100% - 100px );margin:130px auto 0 auto;}
    .contractSection .formRow .formCols .form .inpWrap{width:200px;}
}

@media screen and (max-width: 1024px) {
    .detailSection dl dd ul{width:calc( 100% - 0px );padding-left:0px;}
    .detailSection .sitem{width:calc( 100% - 0px );padding-left:0px;}
    .wrapper{width:calc( 100% - 0px );padding-left:0px;}
    .wrapper .subTitle{width:calc( 100% - 40px );justify-content: flex-start;padding-bottom:20px;}
    .wrapper .subTitle span{font-size:20px;}
    .wrapper .subTitle .etc{font-size:16px;bottom:20px;}

}
@media screen and (max-width: 1024px) {
    .detailSection{width:calc( 100% - 40px );margin-top:20px;margin-bottom:60px;}
    .detailSection dl dt .stit{font-size:20px;}
    .detailSection dl dt button{margin-left:20px;width:100px;}
    .detailSection dl dd ul li .key{font-size:15px;}
    .detailSection dl dd ul li .value{font-size:14px;}
    .detailSection dl dd ul li .span{font-size:14px;}
    .detailSection .btnGroup{margin-top:40px}
    .detailSection dl dd ul li{display:block;}
    .detailSection dl dd ul li .value{margin-top:10px;}

    .contractSection{width:calc( 100% - 40px );margin-top:20px;margin-bottom:60px;}
    .contractSection .formRow{display:block;}
    .contractSection .formRow .formCols +  .formCols{margin-top:40px;}
    .contractSection .formRow .formCols .form.type01{display:block;}
    .contractSection .formRow .formCols .form .sitem{}
    .contractSection .formRow .formCols .form .sitem + .sitem{margin-left:0;margin-top:10px;}
    .contractSection .formRow .formCols .form .sitem .e02{width:70px;padding:0;}
    .contractSection .formRow .formCols .form .sitem .select{flex:1;}
    .contractSection .formRow .formCols .form.type02{padding-bottom:50px;position:relative;}
    .contractSection .formRow .formCols .form.type02 .select{width:100px;}
    .contractSection .formRow .formCols .form.type02 .func{position:absolute;right:0;bottom:0;}
    .contractSection .btnGroup{margin-top:40px}
    .contractSection .formRow .formCols .form.type03{display:block;margin-top:0;}
    .contractSection .formRow .formCols .form.type03 .select{float:left;width:calc( 50% - 20px );margin-right:10px;margin-top:10px;}
    .contractSection .formRow .formCols .form.type03 .select + .select{margin-left:0;}
    .contractSection .formRow .formCols .form .inpWrap{width:45%;}

    .spacedHeader .pageTitle img{}
}


.mobList{display:none;width:calc( 100% - 40px );margin:0 auto;height:auto;overflow:hidden;}
.mobList.type01{width:100%;}
.mobList li{width:100%;height:auto;overflow:hidden;}
.mobList li .chk{width:100%;height:auto;overflow:hidden;padding:0 0 10px 0;}
.mobList li + li{margin-top:20px;}

.mobList li .chk input[type=checkbox]{outline:none;float:none;margin:0 auto;background:url(../../../../assets/image/bg/bg-chk-off01.svg) no-repeat;background-size:24px 24px;border:0;width:24px;height:24px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;}
.mobList li .chk input[type=checkbox]:checked{width:24px;height:24px;background:url(../../../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}
.mobList li .chk input[type=checkbox]::-ms-check{border:0;outline:none;appearance: none;-moz-appearance: none;-webkit-appearance: none;width:24px;height:24px;content:"";background:url(../../../../assets/image/bg/bg-chk-off.svg) no-repeat;background-size:24px 24px;}
.mobList li .chk input[type=checkbox]:checked::-ms-check{color:transparent;background:url(../../../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}

.addLabel{width:100%;height:auto;overflow:hidden;line-height:30px;font-size:14px;color:#141B2D;font-weight:600;}
.popup .addBtn{margin-top:40px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-end;align-items:center;}
.popup .addBtn button{width:120px;border-radius:3px;border:0;background:#40EFC1;padding:0;font-size:15px;color:#141B2D;font-weight:600;height:42px;overflow:hidden;display:flex;justify-content:center;align-items:center;}
.popup .tblWrap01{margin-top:10px;margin-bottom:25px;border-top:1px solid #141B2D;border-bottom:1px solid #141B2D;width:100%;height:auto;overflow:hidden;}
.popup .tblWrap01 table{width:100%;table-layout:fixed;border:0;border-collapse:collapse;}
.popup .tblWrap01 table th{width:200px;height:32px;text-align:center;font-weight:600;font-size:12px;color:#141B2D;border-top:1px solid #141B2D;border-right:1px solid #141B2D;}

.popup .tblWrap01 table td{border-top:1px solid #141B2D;height:32px;text-align:center;font-size:14px;color:#141B2D;font-weight:600;}
.popup .tblWrap01 table tr:first-child th{border-top:0;}
.popup .tblWrap01 table tr:first-child td{border-top:0;}
.popup .tblWrap01 table td .addBox{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.popup .tblWrap01 table td .addBox .addNum{display:flex;font-size:12px;color:#141B2D;font-weight:600;padding:0 20px;}
.popup .tblWrap01 table td .addBox .addName{display:flex;font-size:12px;color:#141B2D;font-weight:600;width:100%;flex:1;}
.popup .tblWrap01 table td .addBox button{margin-right:20px;width:48px;height:24px;border-radius:3px;border:1px solid #000;background:rgba(217, 217, 217, 0.00);overflow:hidden;padding:0;display:flex;justify-content:center;align-items:center;font-size:12px;color:#141B2D;font-weight:600;}
.popup .addForm{width:100%;height:auto;overflow:hidden;margin-top:10px;display:flex;justify-content:flex-start;align-items:center;}
.popup .addForm .select{display:flex;width:160px;height:36px;border:1px solid #141B2D;font-size:12px;color:#141B2D;font-weight:600;background-color:#fff;padding-left:10px;}
.popup .addForm .inp{display:flex;text-align:center;width:100%;flex:1;height:34px;border:1px solid #141B2D;border-left:0;font-size:12px;color:#141B2D;font-weight:600;background-color:#fff;}
.popup .addForm .inp.w01{flex:initial;width:80px;}

.slideWrap{width:100%;height:auto;overflow:hidden;}
.slideWrap .bigimg{width:100%;height:400px;margin:40px auto 30px auto;background-size:cover !important;}
.slideWrap .spic{cursor:pointer;width:100%;height:200px;background-size:cover !important;overflow:hidden;}

@media screen and (max-width: 1024px) {
    .mobList{display:block;}
    .wrapper .scrolls .mobList li table{min-width:0;}
    .wrapper .scrolls .mobList li table th{border-top:1px solid #fff;border-left:0 !important;border-right:0;border-bottom:0;font-size:9px;line-height:1.3em;padding:10px;height:23px;}
    .wrapper .scrolls .mobList li table td{border-top:1px solid #fff;border-left:0 !important;border-right:0;border-bottom:0;font-size:9px;line-height:1.3em;padding:10px;height:23px;}
    .webList{display:none;}
    .excelDownload{width:calc( 100% - 40px );}
    .chargeForm .formItem{display:block;}
    .chargeForm .formItem .label{width:80px;font-size:14px;height:36px;display:flex;justify-content:flex-start;align-items:center;}
    .chargeForm .formItem .inp{margin-right:10px;width:198px;font-size:14px;}
    .chargeForm .formItem .select{width:200px;font-size:14px;}
    .chargeForm ul{margin-top:20px;}
    .chargeForm ul li .label{margin-right:10px;font-size:14px;width:70px;}
    .chargeForm ul li .inp01{width:100px;font-size:14px;}
    .chargeForm ul li .inp01.w01{width:80px;}
    .chargeForm ul li .inp01.w02{width:220px;margin:0 10px;}
    .chargeForm ul li .e01{width:30px;font-size:14px;}
    .chargeForm ul li label{margin-left:10px;}
    .chargeForm ul li label input{}
    .chargeForm ul li label span{margin-left:5px;font-size:14px;}
    .chargeForm ul li:last-child{display:block;padding-top:40px;}
    .chargeForm ul li .cols{width:100%;}
    .chargeForm ul li .cols + .cols{margin-top:10px;}

    .popup .tblWrap01{margin-top:10px;margin-bottom:25px;border-top:1px solid #141B2D;border-bottom:1px solid #141B2D;width:100%;height:auto;overflow:hidden;}
    .popup .tblWrap01 table{width:100%;table-layout:fixed;border:0;border-collapse:collapse;}
    .popup .tblWrap01 table th{width:80px;}
    .popup .tblWrap01 table td .addBox{display:block;padding:10px 0;}
    .popup .tblWrap01 table td .addBox .addNum{display:flex;font-size:12px;color:#141B2D;font-weight:600;padding:0 20px;}
    .popup .tblWrap01 table td .addBox .addName{display:flex;font-size:12px;color:#141B2D;font-weight:600;width:auto;padding:5px 20px;}
    .popup .tblWrap01 table td .addBox button{margin-left:20px;}
    .popup .addForm .select{display:flex;width:80px;}
    .popup .addForm .inp.w01{flex:initial;width:50px;}
    .staticsWrap .chartWrap{height:132px;}

    .staticsWrap .listWrap ul li .cate{font-size:12.6px;}
    .staticsWrap .listWrap ul li .name{font-size:12.6px;}
    .staticsWrap .listWrap ul li .age{font-size:12.6px;}
    .staticsWrap .listWrap ul{margin-top:10px;}
    .spaceWrap .sitem .sico span{font-size:21px;}
    .spaceWrap .sitem .sbox dl.type01 dt{font-size:21px;}
    .spaceWrap .sitem .sbox dl.type01 dt strong{font-size:21px;}
    .spaceWrap .sitem .sbox dl.type01 dd ul li .key{font-size:15px;}
    .spaceWrap .sitem .sbox dl.type01 dd ul li .value{font-size:15px;}
    .spaceWrap .spaceControl{display:flex;}
    .spaceWrap .sitem{display:none;}
    .spaceWrap .sitem.active{display:block;}
    .spacedHeader .pageTitle.type01{padding-bottom:30px;}
    .spacedHeader .pageTitle .pageLoacation{top:50px;}
    .spaceView dl dd .photoList01 ul li{width:calc( 50% - 20px );}
    .spaceView dl dd .photoList01 ul li .mpic{height:34vw;}
    .cntWrap ul li .ico{margin-top:10px;}
    .cntWrap ul li .key{margin-top:0px;}

    .contractSection .formRow.floatType{margin-top:0px;margin-left:-10px;width:calc( 100% + 10px );}
    .contractSection .formRow.floatType .formCols{float:left;width:calc( 50% - 10px );margin-left:10px;margin-top:40px;}
    .contractSection .formRow.floatType .formCols + .formCols{margin-top:40px;}
    .contractSection .formRow.floatType .formCols .inpWrap{width:100%;}
    .contractSection .formRow.floatType .formCols .inpWrap .unit{right:5px;}
}