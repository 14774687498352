/*상단영역*/
header{z-index:50;width:110px;height:100%;position:fixed;left:0;top:0;background:#1F2A40;border-right:1px solid #fff}

header .toggle{width:40px;height:40px;margin:30px 0 20px 30px;display: none;}
header .toggle button{border:0;border-radius:6px;display:flex;justify-content:center;align-items:center;width:45px;height:45px;padding:0;background:transparent;}
header .toggle button:hover{filter: brightness(0) saturate(100%) invert(84%) sepia(11%) saturate(2205%) hue-rotate(106deg) brightness(93%) contrast(103%);}
header .toggle button img{width:40px;height:40px;}
header .rela{width:100%;height:auto;overflow:Hidden;margin-top:30px;}
header .rela .ltItem{padding-left:12px;width:calc( 100% - 30px );height:auto;overflow:Hidden;display:flex;justify-content:flex-start;align-items:center;}
header .rela .ltItem ul{width:100%;height:auto;overflow:Hidden;}
header .rela .ltItem ul li{margin-top:4px;width:100%;height:auto;overflow:Hidden;display:flex;justify-content:flex-start;align-items:center;}
header .rela .ltItem ul li button{display:flex;justify-content:flex-start;align-items:center;width:40px;height:45px;padding:0;background:transparent;border:0;border-radius:6px;}
header .rela .ltItem ul li button:hover{filter: brightness(0) saturate(100%) invert(84%) sepia(11%) saturate(2205%) hue-rotate(106deg) brightness(93%) contrast(103%);}
header .rela .ltItem ul li button img{width:40px;height:40px;margin-right:2px;}
header .rela .ltItem ul li button span{font-size:12px;}
header .rela .rtItem{margin-top:20px;padding-left:16px;width:calc( 100% - 35px );height:auto;overflow:Hidden;}
header .rela .rtItem ul{width:100%;height:auto;overflow:Hidden;}
header .rela .rtItem ul li{margin-top:5px;width:100%;height:auto;overflow:Hidden;display:flex;justify-content:flex-start;align-items:center;}
header .rela .rtItem ul li button{display:flex;justify-content:flex-start;align-items:center;width:74px;height:45px;padding:0;background:transparent;border:0;border-radius:6px;}
header .rela .rtItem ul li button:hover{filter: brightness(0) saturate(100%) invert(84%) sepia(11%) saturate(2205%) hue-rotate(106deg) brightness(93%) contrast(103%);}
header .rela .rtItem ul li button img{width:35px;height:35px;margin-right:4px;}
header .rela .ltItem ul li button span{font-size:12px;}

header .rela .etcLogo{margin-top:36px;margin-bottom:36px;width:100%;height:auto;overflow:Hidden;text-align:center;}
header .rela .etcLogo .SysLogo01{display:none;}
header .rela .etcLogo .SysLogo02{}

header.active{width:240px;}
header.active .rela .ltItem button span{white-space:nowrap;display:flex;margin-left:30px;font-size:15px;color:#fff;font-weight:700;}

header.active .rela .rtItem button span{white-space:nowrap;display:flex;margin-left:30px;font-size:15px;color:#fff;font-weight:700;}
header.active .rela .rtItem ul li button{margin:0;}
header.active .rela .etcLogo .SysLogo01{display:inline-block;}
header.active .rela .etcLogo .SysLogo02{display:none;}

@media screen and (max-width: 1500px) {


}

@media screen and (max-width: 1024px) {
    header .toggle{display: initial;}
    header{width:100%;height:80px;border-top:0;border-bottom:1px solid #fff;display:flex;justify-content:flex-start;align-items:center;}

    header .rela .ltItem button span{margin-left:20px !important;}
    header .toggle{margin-left:10px;margin-top:20px;}
    /*
    header .rela{flex:1;display:none;justify-content:space-between;align-items:center;}
    header .rela .ltItem{padding-left:10px;width:60px;}
    header .rela .rtItem{margin-top:0;padding-left:0px;width:auto;display:flex;justify-content:flex-start;align-items:center;}
    header .rela .rtItem ul{display:flex;justify-content:flex-start;align-items:center;}
    header .rela .rtItem ul li{margin-top:0;margin-right:10px;}
    */
    header .rela{display:none;}
    header.active .rela .ltItem{padding-left:19px;margin-top:10px;}
    header.active .rela .rtItem{margin-top:0;padding-left:22px;}
    header.active{width:100%;}
    header.active .rela{position:fixed;left:0;top:51px;background:#1F2A40;width:240px;height:100%;overflow:hidden;display:block;}
    header .rela .etcLogo{text-align:left;padding-left:20px;}
    header .rela .etcLogo img{max-width:100px;}
}
