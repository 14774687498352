.wrapper{width:calc( 100% - 110px );padding-left:110px;padding-bottom:100px;height:auto;overflow:hidden;}
.wrapper.whiteType{background:#fff;}
.cntWrap{margin:80px auto 0 auto;width:1300px;height:auto;overflow:hidden;}
.cntWrap ul{width:100%;height:auto;overflow:hidden;display:flex;justify-content:space-between;align-items:flex-start;gap:16px;}
.cntWrap ul li{position:relative;width:100%;flex:1;padding:0 30px;height:168px;overflow:Hidden;background:#1F2A40;}
.cntWrap ul li .ico{margin-top:15px;width:100%;overflow:hidden;height:50px;display:flex;justify-content:flex-start;align-items:center;}
.cntWrap ul li .key{margin-top:20px;width:100%;height:auto;overflow:hidden;font-size:17px;color:#fff;font-weight:700;cursor: pointer;}
.cntWrap ul li .value{margin-top:5px;margin-bottom:10px;width:100%;height:auto;overflow:hidden;font-size:19px;color:#40EFC1;font-weight:700;}
.cntWrap ul li .chart{width:60px;height:60px;overflow:hidden;background:#ddd;position:absolute;right:20px;top:20px;}

.startItem{width:100%;height:auto;overflow:hidden;margin-bottom:40px;display:flex;justify-content:flex-start;align-items:flex-start;}
.startItem .slogo{width:200px;height:auto;overflow:hidden;text-align:center;}
.startItem .slogo video{max-width:100%;}
.startItem .scont{margin-left:60px;margin-top:20px;width:100%;flex:1;height:auto;overflow:hidden;}
.startItem .scont .stit{width:100%;height:auto;overflow:hidden;font-size:32px;color:#141B2D;font-weight:700;}
.startItem .scont .sdesc{margin-top:5px;width:100%;height:auto;overflow:hidden;font-size:22px;color:rgba(0, 0, 0, 0.25);font-weight:700;}

.cntWrap.type01{margin-top:0px;margin-bottom:100px;}
.cntWrap.type01 ul{display:block;width:calc( 100% + 22px );margin-left:-11px;}
.cntWrap.type01 ul li{width:calc( 100% / 3 - 22px - 60px );height:203px;float:left;margin:45px 11px 0 11px;}
.cntWrap.type01 ul li .key{margin-top:5px;}
.cntWrap.type01 ul li .value{margin-top:20px;}

.staticsWrap{margin:30px auto 0 auto;width:1300px;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.staticsWrap .chartWrap{font-size:18px;color:#fff;font-weight:500;width:100%;flex:1;height:414px;overflow:Hidden;background:#1F2A40;display:flex;justify-content:center;align-items:center;text-align:center;}
.staticsWrap .listWrap{width:313px;height:414px;overflow:hidden;margin-left:16px;background:#1F2A40;}
.staticsWrap .listWrap .stit{width:calc( 100% - 60px );height:auto;overflow:Hidden;margin:30px auto 0 auto;display:flex;justify-content:flex-start;align-items:center;}
.staticsWrap .listWrap .stit span{display:flex;font-size:16px;color:#40EFC1;font-weight:700;}
.staticsWrap .listWrap .stit img{display:flex;margin-left:5px;}
.staticsWrap .listWrap ul{width:calc( 100% - 60px );height:auto;overflow:Hidden;margin:25px auto 0 auto;}
.staticsWrap .listWrap ul li{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.staticsWrap .listWrap ul li + li{margin-top:8px;}
.staticsWrap .listWrap ul li a{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.staticsWrap .listWrap ul li .cate{width:80px;height:auto;overflow:Hidden;font-size:16px;color:#fff;font-weight:500;}
.staticsWrap .listWrap ul li .name{width:100%;height:auto;overflow:hidden;text-overflow:ellipsis; white-space:nowrap;word-wrap:normal;flex:1;margin-left:16px;font-size:16px;color:#fff;font-weight:500;}
.staticsWrap .listWrap ul li .age{margin-left:16px;font-size:16px;color:#fff;font-weight:500;}

.latestWrap{margin:100px auto 0 auto;width:1300px;height:auto;overflow:hidden;}
.latestWrap .headerWrap{border-bottom:1px solid #40EFC1;padding-bottom:15px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:space-between;align-items:center;}
.latestWrap .headerWrap .lt{display:flex;justify-content:flex-start;align-items:center;}
.latestWrap .headerWrap .lt img{display:flex;}
.latestWrap .headerWrap .lt strong{display:flex;margin-left:8px;font-size:21px;color:#40EFC1;font-weight:700;}
.latestWrap .headerWrap .lt ul{display:flex;justify-content:flex-start;align-items:center;}
.latestWrap .headerWrap .lt ul li{margin-left:20px;display:flex;justify-content:flex-start;align-items:center;color:#fff;font-size:19px;font-weight:500;}
.latestWrap .headerWrap .lt ul li span{display:flex;margin-left:5px;font-size:19px;color:#40EFC1;font-weight:500;}
.latestWrap .headerWrap .rt{display:flex;justify-content:flex-start;align-items:center;}
.latestWrap .headerWrap .rt a{display:flex;justify-content:flex-start;align-items:center;}
.latestWrap .headerWrap .rt a span{display:flex;font-size:13px;color:#fff;font-weight:300;}
.latestWrap .headerWrap .rt a img{display:flex;margin-left:6px;}
.latestWrap .contWrap{height:auto;overflow:hidden;margin:20px auto 0 auto;}
.latestWrap .contWrap.type01{width:100%;}
.latestWrap .contWrap ul{width:100%;height:auto;overflow:Hidden;}
.latestWrap .contWrap ul li{width:100%;height:auto;overflow:Hidden;display:flex;justify-content:flex-start;align-items:center;}
.latestWrap .contWrap ul li + li{margin-top:8px;}
.latestWrap .contWrap ul li .cate{width:166px;display:flex;justify-content:flex-start;align-items:center;font-size:19px;color:#fff;font-weight:500;}
.latestWrap .contWrap ul li .fcate{width:190px;display:flex;justify-content:flex-start;align-items:center;font-size:18px;color:#fff;font-weight:500;}
.latestWrap .contWrap ul li .tit{width:100%;height:auto;overflow:Hidden;flex:1;font-size:19px;color:#fff;font-weight:500;text-overflow:ellipsis; white-space:nowrap;word-wrap:normal;}
.latestWrap .contWrap ul li .date{display:flex;justify-content:flex-end;align-items:center;font-size:14px;color:#fff;}
.latestWrap .contWrap ul li a{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}

.latestWrap .contWrap ul li .comm01{width:120px;display:flex;justify-content:flex-start;align-items:center;font-size:17px;color:#fff;font-weight:500;}
.latestWrap .contWrap ul li .comm02{width:150px;display:flex;justify-content:flex-start;align-items:center;font-size:17px;color:#fff;font-weight:500;}
.latestWrap .contWrap ul li .comm03{width:100px;display:flex;justify-content:flex-start;align-items:center;font-size:17px;color:#fff;font-weight:500;}

.mainList{width:1300px;height:auto;overflow:Hidden;margin:130px auto 0 auto;}
.mainList .mainHeader{width:100%;height:auto;overflow:Hidden;display:flex;justify-content:space-between;align-items:center;}
.mainList .mainHeader .userInfo{display:flex;justify-content:flex-start;align-items:center;}
.mainList .mainHeader .userInfo .profile{display:flex;justify-content:center;align-items:center;flex-direction: column}
.mainList .mainHeader .userInfo .profile .upic{width:66px;height:66px;overflow:Hidden;border-radius:50%;background-size:cover !important;background-position:center center !important;}
.mainList .mainHeader .userInfo .profile .uname{height:35px;font-size:14px;color:#40EFC1;font-weight:700;display:flex;justify-content:center;align-items:center;}
.mainList .mainHeader .userInfo .umsg{margin-left:40px;font-size:40px;color:#40EFC1;font-weight:700;}

.mainList .mainHeader ul{display:flex;justify-content:flex-start;align-items:center;}
.mainList .mainHeader ul li{padding:0 20px;display:flex;position:relative;}
.mainList .mainHeader ul li:before{content:"";width:1px;height:12px;overflow:hidden;display:block;background:#40EFC1;position:absolute;left:0;top:50%;margin-top:-6px;}
.mainList .mainHeader ul li:first-child{padding-left:0;}
.mainList .mainHeader ul li:first-child:before{display:none;}
.mainList .mainHeader ul li a{font-size:22px;color:#40EFC1;font-weight:600;}

.whiteType .mainList .mainHeader .userInfo .umsg{color:#141B2D;}
.whiteType .mainList .mainHeader ul li:before{background:#141B2D;}
.whiteType .mainList .mainHeader ul li a{color:#141B2D;}


.mlist{width:100%;height:auto;overflow:Hidden;}
.mlist ul{width:100%;height:auto;overflow:Hidden;}
.mlist ul li{width:100%;height:auto;overflow:Hidden;margin-top:35px;}
.mlist ul li a{width:100%;height:590px;display:block;position:relative;overflow:Hidden;background-size:cover !important;border-radius:20px;}
.mlist ul li .mpic{width:100%;height:590px;display:block;position:relative;overflow:Hidden;background-size:cover !important;border-radius:20px;}
.mlist ul li a .scont{position:absolute;right:40px;bottom:40px;text-align:right;}
.mlist ul li a .scont .stit{font-size:40px;color:#fff;font-weight:800;text-shadow: 1px 1px 1px rgba(0,0,0,.2)}
.mlist ul li a .scont .sdesc{font-size:40px;color:#fff;font-weight:800;text-shadow: 1px 1px 1px rgba(0,0,0,.2)}

.residenceWrap{width:1340px;height:auto;overflow:hidden;margin:130px auto 0 auto;}
.residenceWrap .residenceHeader{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:flex-end;}
.residenceWrap .residenceHeader .profile{width:66px;height:auto;overflow:hidden;display:flex;justify-content:center;align-items:center;flex-direction: column;}
.residenceWrap .residenceHeader .profile .upic{width:66px;height:66px;overflow:hidden;border-radius:50%;background-size:cover !important;}
.residenceWrap .residenceHeader .profile .uname{font-size:14px;color:#fff;margin-top:5px;font-weight:700;width:100%;height:auto;overflow:hidden;display:flex;justify-content:center;align-items:center;}
.residenceWrap .residenceHeader .rmsg{margin-left:40px;font-size:24px;color:#40EFC1;}
.residenceWrap .residenceHeader .rmsg strong{font-size:35px;color:#40EFC1;font-weight:800;}

.residenceWrap .residenceCont{width:100%;height:589px;overflow:hidden;border-radius:20px;background-size:cover !important;background-position:center center !important;margin-top:20px;position:relative;}
.residenceWrap .residenceCont .scont{position:absolute;right:40px;bottom:40px;}
.residenceWrap .residenceCont .scont .stit{font-size:30px;color:#fff;font-weight:800;text-align:right;}
.residenceWrap .residenceCont .scont .sdesc{font-size:30px;color:#fff;font-weight:800;text-align:right;}

.contactWrap{display:flex;justify-content:center;align-items:center;width:1340px;height:366px;overflow:hidden;margin:12px auto 0 auto;background-size:cover !important;background-position:center center !important;}
.contactWrap .scont{display:flex;justify-content:center;align-items:center;width:100%;}
.contactWrap .scont dl{width:475px;height:auto;overflow:hidden;}
.contactWrap .scont dl dt{width:100%;height:auto;overflow:hidden;font-size:17px;color:#fff;}
.contactWrap .scont dl dt strong{font-size:17px;color:#fff;font-weight:700;}
.contactWrap .scont dl dd{margin-top:30px;width:100%;height:auto;overflow:hidden;}
.contactWrap .scont dl dd ul{width:100%;height:auto;overflow:hidden;}
.contactWrap .scont dl dd ul li{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.contactWrap .scont dl dd ul li + li{margin-top:14px;}
.contactWrap .scont dl dd ul li .key{display:flex;font-size:15px;color:#fff;font-weight:600;}
.contactWrap .scont dl dd ul li .value{display:flex;margin-left:20px;font-size:15px;color:#fff;}
.contactWrap .scont button{width:184px;height:106px;overflow:hidden;border-radius:10px;border:0;background:#40EFC1;padding:0;display:flex;justify-content:center;align-items:center;font-size:22px;color:#1F2A40;font-weight:700;}
.chatBtn{position:fixed;right:40px;bottom:40px;z-index:80;}
.chatBtn button{width:122px;height:122px;padding:0;border:0;background:url(../../../assets/image/ico/ico-chat.png) no-repeat center center;font-size:0;text-indent:-2000px;}

.managerInfo{margin:40px auto 0 auto;width:calc( 100% - 100px );max-width:1300px;height:auto;overflow:hidden;}
.managerInfo .managerRela{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:flex-start;}
.managerInfo .uinfo{display:flex;justify-content:center;align-items:center;width:66px;flex-direction: column}
.managerInfo .upic{width:66px;height:66px;overflow:hidden;border-radius:50%;background-size:cover !important;}
.managerInfo .uname{height:35px;font-size:14px;color:#fff;font-weight:700;display:flex;justify-content:center;align-items:center;}
.managerInfo .ustatus{width:100%;height:auto;overflow:hidden;flex:1;font-size:30px;color:#40EFC1;font-weight:500;margin-top:10px;}
.managerInfo .ustatus strong{padding-right:10px;font-size:30px;color:#40EFC1;font-weight:800;}

.managerInfo .managerRela + .managerRela{margin-top:30px;width:calc( 100% - 104px );}

.spacedHeader{}
.spacedHeader .pageTitle{margin-top:110px;width:calc( 100% - 40px );max-width:1300px;height:auto;overflow:Hidden;border-bottom:2px solid #40EFC1;padding-bottom:40px;display:flex;justify-content:center;align-items:center;position:relative;}
.spacedHeader .pageTitle img{display:flex;}
.spacedHeader .pageTitle span{display:flex;margin-left:10px;font-size:28px;color:#40EFC1;font-weight:700;}
.spacedHeader .pageTitle strong{display:flex;font-size:28px;color:#fff;margin-right:5px;font-weight:700;}
.spacedHeader .pageTitle .pageName{position:absolute;left:0;bottom:40px;font-size:22px;color:#40EFC1;font-weight:700;}

.whiteType .residenceWrap .residenceHeader{justify-content:flex-end;}
.whiteType .residenceWrap .residenceHeader .profile{width:auto;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;flex-direction: row;}
.whiteType .residenceWrap .residenceHeader .profile .uname{font-size:23px;color:#141B2D;margin-top:0;width:auto;margin-left:10px;}


.whiteType .contactWrap{margin-top:138px;background:rgba(31,42,64,.05);}
.whiteType .contactWrap .scont dl{width:920px;}
.whiteType .contactWrap .scont dl dt{display:flex;justify-content:flex-start;align-items:center;}
.whiteType .contactWrap .scont dl dt .upic{width:56px;height:56px;overflow:hidden;display:flex;border-radius:50%;background-size:cover !important;}
.whiteType .contactWrap .scont dl dt .ucont{font-size:24px;color:#141B2D;width:100%;flex:1;margin-left:12px;}
.whiteType .contactWrap .scont dl dt strong{color:#141B2D;font-size:24px;}
.whiteType .contactWrap .scont dl dd ul li .key{font-size:23px;color:#141B2D;}
.whiteType .contactWrap .scont dl dd ul li .value{font-size:23px;color:#141B2D;}


.whiteType .latestWrap .headerWrap{border-bottom:1px solid #1F2A40;}
.whiteType .latestWrap .headerWrap .lt strong{color:#1F2A40;}
.whiteType .latestWrap .headerWrap .lt img{filter: brightness(0) saturate(100%) invert(14%) sepia(11%) saturate(2335%) hue-rotate(182deg) brightness(94%) contrast(93%);}
.whiteType .latestWrap .headerWrap .lt ul li{color:#1F2A40;}
.whiteType .latestWrap .headerWrap .lt ul li span{color:#1F2A40;}
.whiteType .latestWrap .headerWrap .rt a span{color:#1F2A40;}
.whiteType .latestWrap .headerWrap .rt a img{filter: brightness(0) saturate(100%) invert(14%) sepia(11%) saturate(2335%) hue-rotate(182deg) brightness(94%) contrast(93%);}
.whiteType .latestWrap .contWrap ul li .cate{color:#1F2A40;}
.whiteType .latestWrap .contWrap ul li .tit{color:#1F2A40;}
.whiteType .latestWrap .contWrap ul li .date{color:#1F2A40;}

.whiteType .latestWrap .contWrap ul li .comm01{color:#1F2A40;}
.whiteType .latestWrap .contWrap ul li .comm02{color:#1F2A40;}
.whiteType .latestWrap .contWrap ul li .comm03{color:#1F2A40;}


.whiteType .residenceWrap .residenceCont{position:relative;}
.whiteType .residenceWrap .residenceCont .scont{top:40px;}
.whiteType .residenceWrap .residenceCont .scont .stit{color:#1F2A40;}
.whiteType .residenceWrap .residenceCont .scont .sdesc{color:#1F2A40;}
.whiteType .residenceWrap .residenceCont .slogo{position:absolute;right:40px;bottom:40px;}



.managerInfo .uinfo01{width:388px;height:auto;overflow:hidden;}
.managerInfo .uinfo01 ul{width:100%;height:auto;overflow:hidden;}
.managerInfo .uinfo01 ul li{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.managerInfo .uinfo01 ul li + li{margin-top:10px;}
.managerInfo .uinfo01 ul li .key{display:flex;font-size:13px;color:#fff;font-weight:600;margin-right:40px;}
.managerInfo .uinfo01 ul li .value{width:100%;flex:1;height:auto;overflow:hidden;font-size:13px;color:#fff;font-weight:300;}
.managerInfo .uinfo02{width:100%;flex:1;height:auto;overflow:hidden;}
.managerInfo .uinfo02 ul{width:100%;flex:1;height:auto;overflow:hidden;}
.managerInfo .uinfo02 ul li{width:100%;flex:1;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center}
.managerInfo .uinfo02 ul li + li{margin-top:5px;}
.managerInfo .uinfo02 ul li .key{display:flex;font-size:13px;color:#fff;font-weight:600;margin-right:20px;}
.managerInfo .uinfo02 ul li .value{display:flex;margin-right:40px;font-size:13px;color:#fff;}
.managerInfo .uinfo02 ul li .value strong{font-size:13px;color:#fff;font-weight:700;}
.managerInfo .uinfo02 ul li .btnGroup{display:flex;justify-content:flex-start;align-items:center}
.managerInfo .uinfo02 ul li .btnGroup button{margin-right:20px;width:118px;height:22px;overflow:hidden;border-radius:11px;border:1px solid #40EFC1;background:rgba(64, 239, 193, 0.00);position:relative;display:flex;justify-content:center;align-items:center}
.managerInfo .uinfo02 ul li .btnGroup button span{font-size:10px;color:#40EFC1;font-weight:500;}
.managerInfo .uinfo02 ul li .btnGroup button strong{font-size:10px;color:#40EFC1;font-weight:500;display:flex;justify-content:flex-start;align-items:center;height:22px;position:absolute;right:10px;top:0;}

@media screen and (max-width: 1500px) {

    .cntWrap{width:calc( 100% - 100px );margin:80px auto 0 auto;}
    .staticsWrap{width:calc( 100% - 100px );margin:20px auto 0 auto;}
    .latestWrap{width:calc( 100% - 100px );margin:20px auto 0 auto;}

    .mainList{width:calc( 100% - 100px );margin:130px auto 0 auto;}
    .mainList .mainHeader .userInfo .umsg{margin-left:20px;font-size:24px;}
    .residenceWrap{width:calc( 100% - 100px );margin:130px auto 0 auto;}
    .contactWrap{width:calc( 100% - 100px );margin:12px auto 0 auto;}

    .managerInfo .managerRela + .managerRela{padding-left:0;width:100%;}
    .managerInfo .uinfo01{width:300px;}
}

@media screen and (max-width: 1024px) {
    .wrapper{width:calc( 100% - 0px );padding-left:0px;}
    .cntWrap{width:calc( 100% - 40px );margin:40px auto 0 auto;}
    .cntWrap ul{display:block}
    .cntWrap ul li{float:left;margin-bottom:20px;width:calc( 50% - 10px - 40px  );padding:0 20px;}
    .cntWrap ul li:nth-child(even){float:right;}

    .cntWrap.type01{margin-bottom:40px;}
    .cntWrap.type01 ul{width:100%;margin-left:0;}
    .cntWrap.type01 ul li{margin:0;height:auto;float:left;margin-bottom:20px;width:calc( 50% - 10px - 40px  );padding:0 20px 20px 20px;}
    .cntWrap.type01 ul li:nth-child(even){float:right;}

    .cntWrap ul li .ico img{}
    .cntWrap ul li .key{font-size:16px;}
    .cntWrap ul li .value{font-size:16px;}
    .staticsWrap{display:block;width:calc( 100% - 40px);margin:0px auto 0 auto;}
    .latestWrap{width:calc( 100% - 40px );margin:20px auto 0 auto;}

    .residenceWrap{width:calc( 100% - 40px );margin:130px auto 0 auto;}
    .contactWrap{width:calc( 100% - 40px );margin:12px auto 0 auto;}
    .residenceWrap .residenceHeader .profile{width:66px;height:auto;overflow:hidden;display:flex;justify-content:center;align-items:center;flex-direction: column;}
    .residenceWrap .residenceHeader .profile .upic{width:66px;height:66px;overflow:hidden;border-radius:50%;background-size:cover !important;}
    .residenceWrap .residenceHeader .profile .uname{font-size:14px;color:#fff;margin-top:5px;font-weight:700;width:100%;height:auto;overflow:hidden;display:flex;justify-content:center;align-items:center;}
    .residenceWrap .residenceHeader .rmsg{flex:1;width:100%;margin-left:20px;font-size:16px;}
    .residenceWrap .residenceHeader .rmsg strong{font-size:18px;}

    .residenceWrap .residenceCont{height:60vw;margin-top:20px;}
    .residenceWrap .residenceCont .scont{position:absolute;right:20px;bottom:20px;}
    .residenceWrap .residenceCont .scont .stit{font-size:16px;}
    .residenceWrap .residenceCont .scont .sdesc{font-size:16px;}

    .contactWrap{height:auto;padding:20px 0;}
    .contactWrap .scont{display:block;width:calc( 100% - 40px );padding:0 20px;}
    .contactWrap .scont dl{width:calc( 100% - 0px );height:auto;overflow:hidden;}
    .contactWrap .scont dl dt{font-size:14px;}
    .contactWrap .scont dl dt strong{font-size:14px;}
    .contactWrap .scont dl dd{margin-top:20px;}
    .contactWrap .scont dl dd ul{width:100%;height:auto;overflow:hidden;}
    .contactWrap .scont dl dd ul li{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
    .contactWrap .scont dl dd ul li + li{margin-top:10px;}
    .contactWrap .scont dl dd ul li .key{font-size:14px;}
    .contactWrap .scont dl dd ul li .value{margin-left:20px;font-size:14px;}
    .contactWrap .scont button{width:120px;height:60px;overflow:hidden;border-radius:10px;border:0;font-size:18px;margin:20px auto 0 auto;}
    .chatBtn{right:20px;bottom:20px;}
    .chatBtn button{width:80px;height:80px;padding:0;border:0;background-size:contain !important;}

    .staticsWrap .chartWrap{}
    .staticsWrap .listWrap{width:100%;margin-top:20px;margin-left:0;height:auto;}
    .staticsWrap .listWrap ul{width:calc( 100% - 40px );margin:20px auto;}
    .latestWrap{margin-top:40px;}
    .latestWrap .headerWrap{}
    .latestWrap .headerWrap .lt{padding-bottom:30px;position:relative;}
    .latestWrap .headerWrap.t01 .lt{padding-bottom:0;}
    .latestWrap .headerWrap .lt img{display:flex;width:25px;}
    .latestWrap .headerWrap .lt strong{font-size:16px;}
    .latestWrap .headerWrap .lt ul{position:absolute;left:0;bottom:0;width:240px;}
    .latestWrap .headerWrap .lt ul li{margin-left:0px;margin-right:15px;display:flex;justify-content:flex-start;align-items:center;color:#fff;font-size:14px;font-weight:500;}
    .latestWrap .headerWrap .lt ul li span{display:flex;margin-left:5px;font-size:14px;color:#40EFC1;font-weight:500;}
    .latestWrap .contWrap{width:100%;height:auto;overflow:hidden;margin:20px auto 0 auto;}
    .latestWrap .contWrap ul li .cate{width:100%;font-size:14px;}
    .latestWrap .contWrap ul li .tit{margin-top:10px;width:100%;height:auto;overflow:Hidden;flex:1;font-size:16px;color:#fff;font-weight:500;text-overflow:ellipsis; white-space:nowrap;word-wrap:normal;}
    .latestWrap .contWrap ul li .date{margin-top:10px;width:100%;display:flex;justify-content:flex-end;align-items:center;font-size:13px;color:#fff;}
    .latestWrap .contWrap ul li a{position:relative;width:100%;height:auto;overflow:hidden;display:block;}

    .latestWrap .contWrap ul li .comm01{padding-bottom:10px;float:left;width:auto;font-size:14px;}
    .latestWrap .contWrap ul li .comm02{float:left;width:auto;margin-left:20px;font-size:14px;}
    .latestWrap .contWrap ul li .comm03{width:auto;font-size:14px;position:absolute;right:0;top:0;}

    .mainList{width:calc( 100% - 40px );margin:130px auto 0 auto;}
    .mainList .mainHeader{display:block;}
    .mainList .mainHeader .userInfo .profile{display:flex;justify-content:center;align-items:center;flex-direction: column}
    .mainList .mainHeader .userInfo .profile .upic{width:40px;height:40px;}
    .mainList .mainHeader .userInfo .profile .uname{height:35px;font-size:12px;}
    .mainList .mainHeader .userInfo .umsg{margin-left:20px;font-size:16px;}

    .mainList .mainHeader ul{margin-top:10px;}
    .mainList .mainHeader ul li{padding:0 10px;}
    .mainList .mainHeader ul li a{font-size:16px;}

    .mlist ul li{margin-top:20px;}
    .mlist ul li .mpic{height:60vw;}
    .mlist ul li a{width:100%;height:60vw;display:block;position:relative;overflow:Hidden;background-size:cover !important;border-radius:20px;}
    .mlist ul li a .scont{position:absolute;right:20px;bottom:20px;text-align:right;}
    .mlist ul li a .scont .stit{font-size:18px;}
    .mlist ul li a .scont .sdesc{font-size:18px;}

    .startItem{display:block;}
    .startItem .slogo{margin:0 auto;}
    .startItem .scont{margin-left:0;}
    .startItem .scont .stit{font-size:22px;}
    .startItem .scont .sdesc{font-size:16px;}

    .managerInfo{width:calc( 100% - 40px );}
    .managerInfo .managerRela + .managerRela{display:block;}

    .managerInfo .uinfo01{width:100%;height:auto;overflow:hidden;}
    .managerInfo .uinfo01 ul li{display:block;}
    .managerInfo .uinfo01 ul li .key{float:left;}
    .managerInfo .uinfo01 ul li .value{width:auto;float:right;margin:0;}
    .managerInfo .uinfo02{width:100%;flex:1;height:auto;overflow:hidden;margin-top:20px;}
    .managerInfo .uinfo02 ul li{display:block;}
    .managerInfo .uinfo02 ul li .key{float:left;}
    .managerInfo .uinfo02 ul li .value{float:right;margin:0;}
    .managerInfo .uinfo02 ul li .value strong{font-size:13px;color:#40EFC1;font-weight:700;}
    .managerInfo .uinfo02 ul li .btnGroup{margin-top:20px;width:100%;float:left;height:auto;overflow:hidden;display:flex;justify-content:center;align-items:center}
    .managerInfo .uinfo02 ul li .btnGroup button{margin-right:20px;width:118px;height:22px;overflow:hidden;border-radius:11px;border:1px solid #40EFC1;background:rgba(64, 239, 193, 0.00);position:relative;display:flex;justify-content:center;align-items:center}
    .managerInfo .uinfo02 ul li .btnGroup button span{font-size:10px;color:#40EFC1;font-weight:500;}
    .managerInfo .uinfo02 ul li .btnGroup button strong{font-size:10px;color:#40EFC1;font-weight:500;display:flex;justify-content:flex-start;align-items:center;height:22px;position:absolute;right:10px;top:0;}

    .managerInfo .managerRela{display:block;}
    .managerInfo .uinfo{width:100%;display:flex;justify-content:center;align-items:center;flex-direction: column}
    .managerInfo .upic{width:89px;height:89px;overflow:hidden;border-radius:50%;background-size:cover !important;}
    .managerInfo .ustatus{text-align:center;margin-left:0px;;font-size:14.7px;}
    .managerInfo .ustatus strong{font-size:14.7px;}

    .cntWrap.type01 ul li{height:90px;}
    .cntWrap.type01 ul li .ico{margin-top:10px;}
    .cntWrap.type01 ul li .key{font-size:9px;}
    .cntWrap.type01 ul li .value{font-size:9px;margin-top:0;}



}



.wrapper .titlebar{max-width:1462px;width:100%;height:auto;display:flex;justify-content:flex-start;align-items:center;margin:130px auto 0 auto;}
.wrapper .titlebar strong{font-size:60px;color:#40EFC1;font-weight:800;display:flex;}
.wrapper .titlebar span{font-size:24px;color:#40EFC1;font-weight:700;display:flex;margin-left:8px;margin-top:10px;}

.wrapper .housingList{margin:40px auto 0 auto;width:1320px;height:auto;overflow:Hidden;}
.wrapper .housingList>li{width:calc( 100% / 3 - 20px );height:600px;overflow:Hidden;margin:0 10px 75px 10px;float:left;background:#1F2A40;}
.wrapper .housingList li a{width:calc( 100% - 60px );padding:0 30px;height:600px;overflow:Hidden;display:block;}
.wrapper .housingList li a .stit{margin-top:20px;width:100%;height:auto;overflow:Hidden;font-size:24px;color:#40EFC1;font-weight:700;}
.wrapper .housingList li a .snum{width:100%;height:auto;overflow:Hidden;font-size:12px;color:#868686;font-weight:700;line-height:25px;}
.wrapper .housingList li a .spic{width:160px;height:160px;overflow:Hidden;margin:0 auto;background-size:cover !important;margin-top:20px;}
.wrapper .housingList li a .addr{margin:28px auto 0 auto;width:178px;height:42px;line-height:42px;border:1px solid #40EFC1;border-radius:22px;padding:0 20px;overflow:Hidden;text-align:center;font-size:14px;color:#40EFC1;font-weight:700;text-overflow:ellipsis; white-space:nowrap;word-wrap:normal;}

.wrapper .housingList li a .tel{margin:28px auto 0 auto;width:133px;height:30px;border:1px solid #40EFC1;overflow:Hidden;text-align:center;display:flex;justify-content:center;align-items:center;}
.wrapper .housingList li a .tel img{display:flex;}
.wrapper .housingList li a .tel span{margin-left:5px;display:flex;font-size:14px;color:#40EFC1;}

.wrapper .housingList li a ul{margin-top:50px;border-top:1px solid rgba(255,255,255,.2);width:100%;height:auto;overflow:Hidden;display:flex;justify-content:center;align-items:center;}
.wrapper .housingList li a ul + ul{margin-top:0;}
.wrapper .housingList li a ul li{display:flex;justify-content:center;align-items:center;flex:1;width:100%;height:95px;overflow:hidden;flex-direction: column;}
.wrapper .housingList li a ul li .key{display:flex;font-size:16px;color:#fff;font-weight:700;}
.wrapper .housingList li a ul li .value{margin-top:5px;display:flex;font-size:20px;color:#40EFC1;font-weight:700;}


@media screen and (max-width: 1500px) {

    .wrapper .titlebar{width:calc( 100% - 100px );margin:130px auto 0 auto;}
    .wrapper .descbar{width:calc( 100% - 100px );margin:0 auto;}
    .wrapper .housingList{width:calc( 100% - 100px );margin:40px auto 0 auto;}
    .wrapper .housingList>li{width:calc( 50% - 20px );margin:0 0 40px 0;}
    .wrapper .housingList>li:nth-child(even){float:right;}
}

@media screen and (max-width: 1024px) {
    .wrapper{width:calc( 100% - 0px );padding-left:0px;}
    .wrapper .titlebar{width:calc( 100% - 40px );}
    .wrapper .titlebar strong{font-size:30px;}
    .wrapper .titlebar span{font-size:18px;}
    .wrapper .descbar{width:calc( 100% - 40px )}
    .wrapper .housingList{margin-top:20px;width:calc( 100% - 40px )}
    .wrapper .housingList>li{width:100%;margin:0 0 20px 0;height:500px;}
    .wrapper .housingList li a{width:calc( 100% - 20px );padding:0 10px;height:500px;}
    .wrapper .housingList li a ul{margin-top:20px;}
    .wrapper .housingList li a ul li{height:70px;}
    .wrapper .housingList li a ul li .key{font-size:14px;}
    .wrapper .housingList li a ul li .value{font-size:16px;}

}

.pageTitle{width:1300px;height:auto;overflow:Hidden;margin:122px auto 0 auto;}
.pageTitle .mtit{width:100%;height:auto;overflow:Hidden;font-size:60px;color:#40EFC1;font-weight:800;line-height:67px;}
.pageTitle .mdesc{width:100%;height:auto;overflow:Hidden;font-size:24px;color:#40EFC1;font-weight:700;line-height:35px;margin-top:20px;}

.wrapper .scrolls{width:1300px;height:auto;overflow:Hidden;margin:80px auto 0 auto;}
.wrapper .scrolls table{width:100%;table-layout:fixed;border:0;border-collapse:collapse;}
.wrapper .scrolls table th{text-align:left;padding-left:20px;font-size:14px;color:#fff;font-weight:500;height:37px;border-left:1px solid #2D459D;border-top:1px solid #2D459D;border-bottom:1px solid #2D459D;background:#2D459D}
.wrapper .scrolls table td{text-align:left;padding-left:20px;font-size:18px;color:#fff;font-weight:500;height:60px;border-left:1px solid #1F2A40;border-bottom:1px solid #1F2A40;background:#1F2A40;}


.wrapper .scrolls table input[type=checkbox]{outline:none;float:left;background:url(../../../assets/image/bg/bg-chk-off01.svg) no-repeat;background-size:24px 24px;border:0;width:24px;height:24px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;}
.wrapper .scrolls table input[type=checkbox]:checked{width:24px;height:24px;background:url(../../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}
.wrapper .scrolls table input[type=checkbox]::-ms-check{border:0;outline:none;appearance: none;-moz-appearance: none;-webkit-appearance: none;width:24px;height:24px;content:"";background:url(../../../assets/image/bg/bg-chk-off.svg) no-repeat;background-size:24px 24px;}
.wrapper .scrolls table input[type=checkbox]:checked::-ms-check{color:transparent;background:url(../../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}

.wrapper .scrolls table td a{display:flex;justify-content:flex-start;align-items:center;}
.wrapper .scrolls table td a img{display:flex;}
.wrapper .scrolls table td a span{display:flex;margin-left:10px;font-size:14px;color:#40EFC1;font-weight:700;}
.wrapper .scrolls table tr>th:first-child{border-left:0;}
.wrapper .scrolls table tr>td:first-child{border-left:0;}

.paging{max-width:1300px;width:100%;height:auto;overflow:Hidden;display:flex;justify-content:center;align-items:center;margin:50px auto 50px auto;}
.paging button{border:0;background:transparent;width:24px;height:24px;overflow:Hidden;padding:0;display:flex;justify-content:center;align-items:center;}
.paging ul{display:flex;justify-content:center;align-items:center;margin:0 20px;}
.paging ul li{display:flex;justify-content:center;align-items:center;}
.paging ul li a{font-size:15px;color:rgba(255,255,255,.7);display:flex;justify-content:center;align-items:center;width:24px;height:24px;}
.paging ul li a.on{color:rgba(255,255,255,1);font-weight:500;}

@media screen and (max-width: 1500px) {
    .pageTitle{width:calc( 100% - 100px );}
    .wrapper .scrolls{width:calc( 100% - 100px );margin:20px auto 0 auto;overflow-x:auto;}
    .wrapper .scrolls table{min-width:1200px;}
}

@media screen and (max-width: 1024px) {
    .pageTitle .mtit{font-size:40px;line-height:40px;}
    .pageTitle .mdesc{font-size:18px;}
    .wrapper .subTitle span{font-size:20px;}
    .pageTitle{width:calc( 100% - 40px );}
    .wrapper .scrolls{width:calc( 100% - 40px );overflow-x:auto !important;}
}

/**/

.wrapper .titlebar{max-width:1300px;width:100%;height:auto;display:flex;justify-content:flex-start;align-items:center;margin:130px auto 0 auto;position:relative;}
.wrapper .titlebar strong{font-size:60px;color:#40EFC1;font-weight:800;display:flex;}
.wrapper .titlebar span{font-size:24px;color:#40EFC1;font-weight:700;display:flex;margin-left:8px;margin-top:10px;}
.wrapper .titlebar button{width:100px;height:40px;border-radius:6px;border:0;background:#40EFC1;color:#333;font-weight:700;font-size:14px;display:flex;justify-content:center;align-items:center;padding:0;position:absolute;right:0;bottom:0;}

.wrapper .descbar{max-width:1300px;margin:0 auto;width:100%;height:auto;display:flex;justify-content:flex-start;align-items:center;font-size:34px;color:#fff;font-weight:800;}

.calendarRela{margin:50px auto 0 auto;width:1300px;height:auto;position:relative;overflow:hidden;display:flex;justify-content:flex-start;align-items:flex-start;}
.calendarRela .side{width:230px;height:100%;overflow:hidden;background:#1F2A40;position:absolute;left:0;top:0;}
.calendarRela .side ul{width:calc( 100% - 20px );height:auto;overflow:hidden;margin:40px auto 0 auto;}
.calendarRela .side ul li{border-bottom:1px solid #fff;width:calc( 100% - 40px );padding:20px;height:auto;overflow:hidden;}
.calendarRela .side ul li .mdesc{width:100%;height:auto;overflow:hidden;font-size:15px;color:#fff;font-weight:600;line-height:1.3em;}
.calendarRela .side ul li .mdate{position:relative;width:calc( 100% - 20px );padding-left:20px;height:auto;overflow:hidden;margin-top:20px;font-size:15px;color:#fff;font-weight:600;}
.calendarRela .side ul li .mdate:before{content:"";display:block;width:9px;height:9px;overflow:hidden;border-radius:50%;position:absolute;left:0;top:50%;margin-top:-4px;}
.calendarRela .side ul li.status01 .mdate:before{background:#FFA9A9;}
.calendarRela .side ul li.status02 .mdate:before{background:#FFFA98;}

.calendarRela .acticle{width:100%;flex:1;height:auto;overflow:hidden;margin-left:280px;}
.fc-button{display:none !important;}

.popup{width:calc( 100% - 40px - 50px );max-width:687px;height:auto;padding:48px 25px;overflow:hidden;background:#ededed;position:fixed;left:50%;top:100px;transform: translateX(-50%);z-index:99999;display:none;}
.popup.on{display:block;}
.popup .ptit{width:100%;height:auto;overflow:hidden;text-align:center;font-size:22px;color:#141B2D;font-weight:600;}
.popup .pdesc{width:100%;height:auto;overflow:hidden;text-align:center;font-size:18px;color:#141B2D;font-weight:300;margin-top:10px;}
.popup .formItem{margin-top:20px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.popup .formItem .inp{border:1px solid #1F2A40;height:45px;width:100%;flex:1;background:#fff;padding:0 10px;font-size:18px;color:#141B2D;}
.popup .btnGroup{margin-top:20px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-end;align-items:center;}
.popup .btnGroup .btnConfirm{display:flex;justify-content:center;align-items:center;width:113px;height:40px;border-radius:3px;background:#3C7EFE;border:0;font-size:18px;color:#fff;font-weight:600;padding:0;}
.popup .btnGroup .btnCancel{margin-left:20px;display:flex;justify-content:center;align-items:center;width:113px;height:40px;border-radius:3px;background:#DEDEDE;border:0;font-size:18px;color:#141B2D;font-weight:600;padding:0;}


@media screen and (max-width: 1500px) {

    .wrapper .titlebar{width:calc( 100% - 100px );margin:130px auto 0 auto;}
    .wrapper .descbar{width:calc( 100% - 100px );margin:0 auto;}
    .calendarRela{width:calc( 100% - 100px );margin:50px auto 0 auto;}
}

@media screen and (max-width: 1024px) {
    .wrapper{width:calc( 100% - 0px );padding-left:0px;}
    .wrapper .titlebar{width:calc( 100% - 40px );margin:40px auto 0 auto;}
    .wrapper .descbar{width:calc( 100% - 40px );margin:0 auto;}
    .calendarRela{display:block;width:calc( 100% - 40px );margin:20px auto 0 auto;}
    .calendarRela .side{position:static;width:calc( 100% - 0px );margin:0px auto 0 auto;}
    .calendarRela .side ul{width:100%;margin-top:0;}
    .calendarRela .side ul li .mdate{margin-top:10px;}
    .calendarRela .acticle{margin:0;}

    .wrapper .titlebar strong{font-size:30px;}
    .wrapper .titlebar span{font-size:18px;}
    .wrapper .descbar{font-size:18px;}

    .popup .btnGroup{justify-content:center;}
    .managerInfo .uname{font-size:17px;}
    .startItem .slogo{max-width:151px;}

    .whiteType .residenceWrap .residenceHeader .profile .uname{font-size:16px;}
    .whiteType .contactWrap{margin-top:20px;}
    .whiteType .contactWrap .scont dl dt .upic{width:40px;height:40px;}
    .whiteType .contactWrap .scont dl dt .ucont{font-size:16px;margin-left:8px;}
    .whiteType .contactWrap .scont dl dt strong{color:#141B2D;font-size:16px;}
    .whiteType .contactWrap .scont dl dd ul li .key{font-size:14px;}
    .whiteType .contactWrap .scont dl dd ul li .value{font-size:14px;}


    .whiteType .residenceWrap .residenceCont .scont{top:20px;}
    .whiteType .residenceWrap .residenceCont .slogo{right:20px;bottom:20px;}
    .whiteType .residenceWrap .residenceCont .slogo img{max-width:120px;}

}
