.wrapper{width:calc( 100% - 110px );padding-left:110px;padding-bottom:100px;height:auto;overflow:hidden;}

@media screen and (max-width: 1500px) {
    .wrapper .subTitle{width:calc( 100% - 100px );margin:130px auto 0 auto;}
    .wrapper .scrolls{width:calc( 100% - 100px );margin:20px auto 0 auto;overflow-x:auto;}
    .wrapper .scrolls table{min-width:1200px;}
}

@media screen and (max-width: 1024px) {
    .wrapper{width:calc( 100% - 0px );padding-left:0px;padding-bottom:0;}
    .wrapper .subTitle{width:calc( 100% - 40px );}
    .wrapper .subTitle span{font-size:20px;}
    .wrapper .scrolls{width:calc( 100% - 40px );overflow-x:auto !important;}
}

/* 선택삭제 버튼 추가 2024-01-08 */
.btn{margin-top:20px; width:130px;height:35px;overflow:hidden;border-radius:8px;border:0;background:#40EFC1;padding:0;display:flex;justify-content:center;align-items:center;font-size:14px;color:#141B2D;font-weight:700;}


.wrapper .scrolls{width:1300px;height:auto;overflow:Hidden;margin:0px auto 0 auto;}
.wrapper .scrolls table{width:100%;table-layout:fixed;border:0;border-collapse:collapse;}
.wrapper .scrolls table th{text-align:center;font-size:14px;color:#fff;font-weight:500;height:37px;border-left:1px solid #fff;border-top:1px solid #fff;background:#2D459D}
.wrapper .scrolls table thead tr:nth-child(1) th{border-top:0;}
.wrapper .scrolls table td{border-left:1px solid #fff;border-bottom:1px solid #fff;border-top:1px solid #fff;text-align:center;font-size:16px;color:#fff;font-weight:500;height:60px;background:#1F2A40;}
.wrapper .scrolls table .bol{border-left:1px solid #fff !important;}
.wrapper .scrolls table .al{text-align:left;}

.wrapper .scrolls.type01{margin-top:40px;}
.wrapper .scrolls.type01 table th{border-top:0;text-align:center;padding-left:0px;height:53px;}
.wrapper .scrolls.type01 table th input{margin:0 auto;float:none;}
.wrapper .scrolls.type01 table td{text-align:center;padding-left:0px;}
.wrapper .scrolls.type01 table td input{margin:0 auto;float:none;}

.wrapper .scrolls table input[type=checkbox]{outline:none;float:left;background:url(../../../assets/image/bg/bg-chk-off01.svg) no-repeat;background-size:24px 24px;border:0;width:24px;height:24px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;}
.wrapper .scrolls table input[type=checkbox]:checked{width:24px;height:24px;background:url(../../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}
.wrapper .scrolls table input[type=checkbox]::-ms-check{border:0;outline:none;appearance: none;-moz-appearance: none;-webkit-appearance: none;width:24px;height:24px;content:"";background:url(../../../assets/image/bg/bg-chk-off.svg) no-repeat;background-size:24px 24px;}
.wrapper .scrolls table input[type=checkbox]:checked::-ms-check{color:transparent;background:url(../../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}

.wrapper .scrolls table td a{display:flex;justify-content:flex-start;align-items:center;}
.wrapper .scrolls table td a img{display:flex;}
.wrapper .scrolls table td a span{display:flex;margin-left:10px;font-size:14px;color:#40EFC1;font-weight:700;}
.wrapper .scrolls table tr>th:first-child{border-left:0;}
.wrapper .scrolls table tr:nth-child(1)>th{border-top:0;}
.wrapper .scrolls table tr>td:first-child{border-left:0;}

.dsTitleWrap{margin:130px auto 0 auto;width:1300px;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.dsTitleWrap strong{display:flex;font-size:45px;color:#40EFC1;font-weight:800;}
.dsTitleWrap span{display:flex;margin-left:40px;font-size:28px;color:#fff;font-weight:800;}
.cntWrap{margin:40px auto 0 auto;width:1300px;height:auto;overflow:hidden;}
.cntWrap ul{width:100%;height:auto;overflow:hidden;display:flex;justify-content:space-between;align-items:flex-start;gap:16px;}
.cntWrap ul li{width:100%;flex:1;padding:0 30px;height:188px;overflow:Hidden;background:#1F2A40;position:relative;}
.cntWrap ul li .ico{margin-top:20px;width:100%;overflow:hidden;height:50px;display:flex;justify-content:flex-start;align-items:center;}
.cntWrap ul li .key{margin-top:20px;width:100%;height:auto;overflow:hidden;font-size:16px;color:#fff;font-weight:700;}
.cntWrap ul li .value{margin-top:10px;width:100%;height:auto;overflow:hidden;font-size:18px;color:#40EFC1;font-weight:700;cursor: pointer;}
.cntWrap ul li .chart{width:60px;height:60px;background:#ddd;position:absolute;right:30px;top:30px;}

.cntWrap.type01{margin-top:50px;margin-bottom:100px;}
.cntWrap.type01 ul{display:block;width:calc( 100% + 22px );margin-left:-11px;}
.cntWrap.type01 ul li{width:calc( 100% / 3 - 22px - 60px );height:226px;float:left;margin:52px 11px 0 11px;}
.cntWrap.type01 ul li .key{margin-top:5px;}
.cntWrap.type01 ul li .value{margin-top:20px;}

.staticsWrap{margin:30px auto 0 auto;width:1300px;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.staticsWrap .chartWrap{font-size:18px;color: #FFFFFF;font-weight:500;width:100%;flex:1;height:414px;overflow:Hidden;background:#1F2A40;display:flex;justify-content:center;align-items:center;text-align:center;}
.staticsWrap .listWrap{width:313px;height:414px;overflow:hidden;margin-left:16px;background:#1F2A40;}
.staticsWrap .listWrap .key{margin-top:20px;margin-left:20px;height:auto;overflow:hidden;font-size:16px;color:#fff;font-weight:700;}
.staticsWrap .listWrap ul{width:calc( 100% - 60px );height:auto;overflow:Hidden;margin:20px auto 0 auto;}
.staticsWrap .listWrap ul li{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.staticsWrap .listWrap ul li + li{margin-top:8px;}
.staticsWrap .listWrap ul li a{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.staticsWrap .listWrap ul li .cate{width:80px;height:auto;overflow:Hidden;font-size:18px;color:#40EFC1;font-weight:500;}
.staticsWrap .listWrap ul li .name{width:100%;height:auto;overflow:hidden;text-overflow:ellipsis; white-space:nowrap;word-wrap:normal;flex:1;margin-left:16px;font-size:18px;color:#40EFC1;font-weight:500;}
.staticsWrap .listWrap ul li .age{margin-left:16px;font-size:18px;color:#40EFC1;font-weight:500;}

.latestWrap{margin:100px auto 0 auto;width:1300px;height:auto;overflow:hidden;}
.latestWrap .headerWrap{padding-bottom:15px;width:100%;height:auto;overflow:hidden;display:flex;justify-content:space-between;align-items:center;}
.latestWrap .headerWrap .lt{display:flex;justify-content:flex-start;align-items:center;}
.latestWrap .headerWrap .lt img{display:flex;width:30px;}
.latestWrap .headerWrap .lt strong{display:flex;margin-left:8px;font-size:18px;color:#40EFC1;font-weight:700;}
.latestWrap .headerWrap .lt ul{display:flex;justify-content:flex-start;align-items:center;}
.latestWrap .headerWrap .lt ul li{margin-left:20px;display:flex;justify-content:flex-start;align-items:center;color:#fff;font-size:19px;font-weight:500;}
.latestWrap .headerWrap .lt ul li span{display:flex;margin-left:5px;font-size:19px;color:#40EFC1;font-weight:500;}
.latestWrap .headerWrap .rt{display:flex;justify-content:flex-start;align-items:center;}
.latestWrap .headerWrap .rt a{display:flex;justify-content:flex-start;align-items:center;}
.latestWrap .headerWrap .rt a span{display:flex;font-size:9px;color:#fff;font-weight:300;}
.latestWrap .headerWrap .rt a img{display:flex;margin-left:6px;}
.latestWrap .contWrap{width:750px;height:auto;overflow:hidden;margin:20px auto 0 auto;}

@media screen and (max-width: 1500px) {
    .wrapper .scrolls{width:calc( 100% - 100px );margin:20px auto 0 auto;overflow-x:auto;}
    .wrapper .scrolls table{min-width:1200px;}

    .dsTitleWrap{width:calc( 100% - 100px );margin:130px auto 0 auto;}
    .cntWrap{width:calc( 100% - 100px );margin:20px auto 0 auto;}
    .staticsWrap{display:block;width:calc( 100% - 100px );margin:20px auto 0 auto;}
    .latestWrap{width:calc( 100% - 100px );margin:20px auto 0 auto;}
    .staticsWrap .listWrap{width:100%;height:auto;overflow:hidden;padding-bottom:20px;margin-left:0;margin-top:20px;}
}

@media screen and (max-width: 1024px) {
    .wrapper .subTitle span{font-size:20px;}
    .wrapper .scrolls{width:calc( 100% - 0px );margin-bottom:40px;margin-top:0;overflow-x:auto !important;}
    .dsTitleWrap{width:calc( 100% - 40px );margin:130px auto 0 auto;display:block;}
    .dsTitleWrap strong{font-size:26px;}
    .dsTitleWrap span{margin-left:0;font-size:14.3px;margin-top:10px;}
    .cntWrap{width:calc( 100% - 40px );margin:20px auto 0 auto;}
    .cntWrap ul{display:block}
    .cntWrap ul li{float:left;margin-bottom:20px;width:calc( 50% - 10px - 40px  );padding:0 20px;height:126px;}
    .cntWrap ul li:nth-child(even){float:right;}

    .cntWrap.type01{margin-bottom:40px;}
    .cntWrap.type01 ul{width:100%;margin-left:0;}
    .cntWrap.type01 ul li{margin:0;height:auto;float:left;margin-bottom:20px;width:calc( 50% - 10px - 40px  );padding:0 20px 20px 20px;}
    .cntWrap.type01 ul li:nth-child(even){float:right;}

    .cntWrap ul li .ico img{width:30px;}
    .cntWrap ul li .key{font-size:9px;}
    .cntWrap ul li .value{font-size:9px;}
    .staticsWrap{display:block;width:calc( 100% - 40px);margin:0px auto 0 auto;}
    .staticsWrap .listWrap .key{font-size:9px;}
    .latestWrap{width:calc( 100% - 40px );margin:20px auto 0 auto;}

    /* .cntWrap ul li .chart{width:50px;height:50px;background:#ddd;position:absolute;right:20px;top:20px;} */

    .latestWrap .headerWrap .lt img{display:flex;width:30px;}
    .latestWrap .headerWrap .lt strong{display:flex;margin-left:8px;font-size:10px;color:#40EFC1;font-weight:700;}
    .latestWrap .headerWrap{padding-bottom:15px;margin-top:40px;}
}

.wrapper{width:calc( 100% - 110px );padding-left:110px;padding-bottom:100px;height:auto;overflow:hidden;}
.wrapper .subTitle{width:1300px;height:auto;overflow:Hidden;margin:130px auto 0 auto;border-bottom:2px solid #40EFC1;padding-bottom:40px;display:flex;justify-content:center;align-items:center;position:relative;}
.wrapper .subTitle img{display:flex;}
.wrapper .subTitle span{display:flex;margin-left:16px;font-size:28px;color:#40EFC1;font-weight:700;}
.wrapper .subTitle .etc{position:absolute;right:0;bottom:40px;font-size:21px;color:#fff;font-weight:600;}
.wrapper .subTitle button{width:134px;height:36px;overflow:hidden;border-radius:8px;border:0;background:#40EFC1;display:flex;justify-content:center;align-items:center;font-size:16px;color:#141B2D;font-weight:700;position:absolute;right:0;top:0;}

@media screen and (max-width: 1500px) {
    .wrapper .subTitle{width:calc( 100% - 100px );margin:130px auto 0 auto;}
    .staticsWrap .chartWrap{height:414px;color:#FFFFFF;}
}

@media screen and (max-width: 1024px) {
    .wrapper{width:calc( 100% - 0px );padding-left:0px;}
    .wrapper .subTitle{width:calc( 100% - 40px );justify-content: flex-start;padding-bottom:20px;}
    .wrapper .subTitle span{font-size:20px;}
    .wrapper .subTitle .etc{font-size:16px;bottom:20px;}
}

.mobList{display:none;width:calc( 100% - 40px );margin:0 auto;height:auto;overflow:hidden;}
.mobList.type01{width:100%;}
.mobList li{width:100%;height:auto;overflow:hidden;}
.mobList li .chk{width:100%;height:auto;overflow:hidden;padding:0 0 10px 0;}
.mobList li + li{margin-top:20px;}

.mobList li .chk input[type=checkbox]{outline:none;float:none;margin:0 auto;background:url(../../../assets/image/bg/bg-chk-off01.svg) no-repeat;background-size:24px 24px;border:0;width:24px;height:24px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;}
.mobList li .chk input[type=checkbox]:checked{width:24px;height:24px;background:url(../../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}
.mobList li .chk input[type=checkbox]::-ms-check{border:0;outline:none;appearance: none;-moz-appearance: none;-webkit-appearance: none;width:24px;height:24px;content:"";background:url(../../../assets/image/bg/bg-chk-off.svg) no-repeat;background-size:24px 24px;}
.mobList li .chk input[type=checkbox]:checked::-ms-check{color:transparent;background:url(../../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}

@media screen and (max-width: 1024px) {
    .mobList{display:block;}
    .wrapper .scrolls .mobList li table{min-width:0;}
    .wrapper .scrolls .mobList li table th{border-top:1px solid #fff;border-left:0 !important;border-right:0;border-bottom:0;font-size:9px;line-height:1.3em;padding:10px;height:23px;}
    .wrapper .scrolls .mobList li table td{border-top:1px solid #fff;border-left:0 !important;border-right:0;border-bottom:0;font-size:9px;line-height:1.3em;padding:10px;height:23px;}

    .staticsWrap .chartWrap{height:414px;color:#FFFFFF;}
    .staticsWrap .listWrap ul li .cate{font-size:12.6px;}
    .staticsWrap .listWrap ul li .name{font-size:12.6px;}
    .staticsWrap .listWrap ul li .age{font-size:12.6px;}
    .staticsWrap .listWrap ul{margin-top:10px;}
    .cntWrap ul li .ico{margin-top:10px;}
    .cntWrap ul li .key{margin-top:0px;}
}