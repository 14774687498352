.wrapper{width:calc( 100% - 110px );padding-left:110px;padding-bottom:100px;height:auto;overflow:hidden;}
.wrapper .subTitle{width:1300px;height:auto;margin:130px auto 0 auto;border-bottom:2px solid #40EFC1;padding-bottom:40px;display:flex;justify-content:center;align-items:center;position:relative;}
.wrapper .subTitle img{display:flex;}
.wrapper .subTitle span{display:flex;margin-left:16px;font-size:28px;color:#40EFC1;font-weight:700;}
.wrapper .subTitle .etc{position:absolute;right:0;bottom:40px;font-size:21px;color:#fff;font-weight:600;}
.wrapper .subTitle .etc button{width:130px;height:35px;overflow:hidden;border-radius:8px;border:0;background:#40EFC1;padding:0;display:flex;justify-content:center;align-items:center;font-size:14px;color:#141B2D;font-weight:700;}

.paging{max-width:1300px;width:100%;height:auto;overflow:Hidden;display:flex;justify-content:center;align-items:center;margin:50px auto 50px auto;}
.paging button{border:0;background:transparent;width:24px;height:24px;overflow:Hidden;padding:0;display:flex;justify-content:center;align-items:center;}
.paging ul{display:flex;justify-content:center;align-items:center;margin:0 20px;}
.paging ul li{display:flex;justify-content:center;align-items:center;}
.paging ul li a{font-size:15px;color:rgba(255,255,255,.7);display:flex;justify-content:center;align-items:center;width:24px;height:24px;}
.paging ul li a.on{color:rgba(255,255,255,1);font-weight:500;}


/* 선택삭제 버튼 추가 2024-01-08 */
.btn{margin-top:20px; width:130px;height:35px;overflow:hidden;border-radius:8px;border:0;background:#40EFC1;padding:0;display:flex;justify-content:center;align-items:center;font-size:14px;color:#141B2D;font-weight:700;}

.wrapper .scrolls{width:1300px;height:auto;overflow:Hidden;margin:60px auto 0 auto;}


.wrapper .scrolls table{width:100%;table-layout:fixed;border:0;border-collapse:collapse;}
.wrapper .scrolls table th{text-align:center;font-size:14px;color:#fff;font-weight:500;height:55px;border-left:1px solid #fff;border-top:1px solid #fff;background:#1F2A40}
.wrapper .scrolls table thead tr:nth-child(1) th{border-top:0;}
.wrapper .scrolls table td{border-left:1px solid #fff;border-bottom:1px solid #fff;border-top:1px solid #fff;text-align:center;font-size:14px;color:#fff;font-weight:500;height:60px;background:transparent;}
.wrapper .scrolls table input{float:none !important;margin:0 auto !important;}

.wrapper .scrolls.type01{margin-top:80px;}
.wrapper .scrolls.type01 table th{border:0;text-align:left;padding-left:20px;height:53px;}
.wrapper .scrolls.type01 table td{text-align:left;padding-left:20px;}

.wrapper .scrolls table input[type=checkbox]{outline:none;float:left;background:url(../../assets/image/bg/bg-chk-off01.svg) no-repeat;background-size:24px 24px;border:0;width:24px;height:24px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;}
.wrapper .scrolls table input[type=checkbox]:checked{width:24px;height:24px;background:url(../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}
.wrapper .scrolls table input[type=checkbox]::-ms-check{border:0;outline:none;appearance: none;-moz-appearance: none;-webkit-appearance: none;width:24px;height:24px;content:"";background:url(../../assets/image/bg/bg-chk-off.svg) no-repeat;background-size:24px 24px;}
.wrapper .scrolls table input[type=checkbox]:checked::-ms-check{color:transparent;background:url(../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}

.wrapper .scrolls table td a{display:flex;justify-content:center;align-items:center;margin:0 auto;font-size:18px;color:#878787;}
.wrapper .scrolls table td a img{display:flex;}
.wrapper .scrolls table td a span{display:flex;margin-left:10px;font-size:14px;color:#40EFC1;font-weight:700;}
.wrapper .scrolls table tr>th:first-child{border-left:0;}
.wrapper .scrolls table tr:nth-child(1)>th{border-top:0;}
.wrapper .scrolls table tr>td:first-child{border-left:0;}
.wrapper .scrolls table .al{text-align:left;}

.boardSearch{max-width:1300px;margin:0 auto;width:100%;height:auto;overflow:Hidden;display:flex;justify-content:center;align-items:center;}
.boardSearch .searchForm{width:520px;border:1px solid #141B2D;height:40px;overflow:hidden;background:#fff;margin:0 auto;display:flex;justify-content:flex-start;position:relative;align-items:center;}
.boardSearch .select{width:130px;height:40px;border:0;padding-left:12px;font-size:13px;color:#000;}
.boardSearch .inp{padding-left:12px;width:100%;flex:1;height:40px;border-left:1px solid #141B2D;}
.boardSearch button{position:absolute;border:0;right:0;top:0;width:60px;height:40px;overflow:hidden;padding:0;background:transparent;display:flex;justify-content:center;align-items:center;}

@media screen and (max-width: 1500px) {

    .wrapper .subTitle{width:calc( 100% - 100px );margin:130px auto 0 auto;}
    .wrapper .scrolls{width:calc( 100% - 100px );margin:20px auto 0 auto;overflow-x:auto;}
    .wrapper .scrolls table{min-width:1200px;}
}

@media screen and (max-width: 1024px) {
    .wrapper{width:calc( 100% - 0px );padding-left:0px;}
    .wrapper .subTitle{width:calc( 100% - 40px );justify-content: flex-start;padding-bottom:20px;}
    .wrapper .subTitle span{font-size:22px;}
    .wrapper .scrolls{width:calc( 100% - 40px );overflow-x:auto !important;}
    .wrapper .subTitle .etc{font-size:16px;bottom:20px;}

}

.detailSection{width:800px;height:auto;overflow:hidden;margin:70px auto 100px auto;}
.detailSection dl{width:100%;height:auto;overflow:hidden;}
.detailSection dl + dl{margin-top:80px;}
.detailSection dl dt{width:100%;height:auto;overflow:hidden;display:flex;justify-content:space-between;align-items:center;border-bottom:1px solid rgba(219, 219, 219, .2);padding-bottom:20px;}
.detailSection dl dt .stit{font-size:22px;color:#40EFC1;font-weight:700;}
.detailSection dl dt button{width:134px;height:36px;overflow:hidden;border-radius:8px;border:0;background:#40EFC1;display:flex;justify-content:center;align-items:center;font-size:16px;color:#141B2D;font-weight:700;}
.detailSection dl dd{margin-top:30px;width:100%;height:auto;overflow:hidden;}
.detailSection dl dd ul{width:calc( 100% - 60px );padding-left:60px;height:auto;overflow:hidden;}
.detailSection dl dd ul li{width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:flex-start;}
.detailSection dl dd ul li + li{margin-top:20px;}
.detailSection dl dd ul li .key{display:flex;width:120px;font-size:18px;color:#40EFC1;font-weight:600;}
.detailSection dl dd ul li .value{display:flex;font-size:15px;color:#fff;font-weight:600;justify-content:flex-start;align-items:center;}
.detailSection dl dd ul li .value span{font-size:15px;color:#fff;font-weight:600;width:100%;flex:1;}
.detailSection dl dd ul li .value button{font-size:13px;color:#fff;font-weight:600;margin-left:40px;display:flex;justify-content:center;align-items:center;width:80px;height:30px;border:1px solid #fff;background:rgba(204, 211, 228, 0.00);border-radius:5px;box-shadow:0px 3.42932px 2.57199px 1.71466px rgba(0, 0, 0, 0.04);}
.detailSection .sitem{margin-top:20px;margin-bottom:20px;width:calc( 100% - 60px );padding-left:60px;height:auto;overflow:hidden;}
.detailSection .sitem span{border: 1.286px solid #fff;
    background: transparent;
    height:28px;padding:0 15px;display:inline-flex;justify-content:center;align-items:center;float:left;margin-right:8px;font-size:13px;color:#fff;font-weight:600;}
.detailSection .btnGroup{width:100%;height:auto;overflow:hidden;display:flex;justify-content:center;align-items:center;margin-top:100px;}
.detailSection .btnGroup button{width:330px;height:54px;border-radius:5px;border:0;padding:0;background:#40EFC1;overflow:hidden;display:flex;justify-content:center;align-items:center;font-size:18px;color:#141B2D;font-weight:700;}

@media screen and (max-width: 1024px) {
    .detailSection{width:calc( 100% - 40px );margin-top:20px;margin-bottom:60px;}
    .detailSection .sitem{width:calc( 100% - 0px );padding-left:0px;}
    .detailSection dl dt .stit{font-size:20px;}
    .detailSection dl dt button{margin-left:20px;width:100px;}
    .detailSection dl dd ul{width:calc( 100% - 0px );padding-left:0px;}
    .detailSection dl dd ul li .key{font-size:15px;}
    .detailSection dl dd ul li .value{font-size:14px;}
    .detailSection dl dd ul li .span{font-size:14px;}
    .detailSection .btnGroup{margin-top:40px}
}


.mobList{display:none;width:calc( 100% - 0px );margin:20px auto 0 auto;height:auto;overflow:hidden;}
.mobList.type01{width:100%;}
.mobList li{width:100%;height:auto;overflow:hidden;}
.mobList li .chk{width:100%;height:auto;overflow:hidden;padding:0 0 10px 0;}
.mobList li + li{margin-top:20px;}

.mobList li .chk input[type=checkbox]{outline:none;float:none;margin:0 auto;background:url(../../assets/image/bg/bg-chk-off01.svg) no-repeat;background-size:24px 24px;border:0;width:24px;height:24px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;}
.mobList li .chk input[type=checkbox]:checked{width:24px;height:24px;background:url(../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}
.mobList li .chk input[type=checkbox]::-ms-check{border:0;outline:none;appearance: none;-moz-appearance: none;-webkit-appearance: none;width:24px;height:24px;content:"";background:url(../../assets/image/bg/bg-chk-off.svg) no-repeat;background-size:24px 24px;}
.mobList li .chk input[type=checkbox]:checked::-ms-check{color:transparent;background:url(../../assets/image/bg/bg-chk-on01.svg) no-repeat;background-size:24px 24px;}

@media screen and (max-width: 1024px) {
    .mobList{display:block;}
    .wrapper .scrolls .mobList li table{min-width:0;}
    .wrapper .scrolls .mobList li table th{border-bottom:0;border-top:1px solid #fff;font-size:10.5px;line-height:1.3em;padding:10px;height:27.6px;}
    .wrapper .scrolls .mobList li table td{border-top:1px solid rgba(255,255,255,.9);border-bottom:0;font-size:10.5px;line-height:1.3em;padding:10px;height:27.6px;}
    .wrapper .scrolls .mobList li table tr:nth-child(2)>th,
    .wrapper .scrolls .mobList li table tr:nth-child(2)>td{border-top:0;}
    .webList{display:none;}
    .wrapper .scrolls table td a{font-size:14px;margin:0;width:auto;justify-content:flex-start;}
}